import React, { useEffect, useState } from "react";
import "../navDropdown.css";
import Navbar from "../../../section/navbar/Navbar";
import LeaveFrom from "./LeaveFrom";
import EmpLeaveData from "./EmpLeaveData";
import EventsLeave from "./EventsLeave";

const LeaveHome = () => {
  // ------------------------------------------------------ adim  config ------------------------------------------------ //

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));

  const storedColor = localStorage.getItem("selectedColor");

  const [leaveRequest, setLeaveRequest] = useState([]);

  useEffect(() => {
    fetch("https://hrmsadmin.hola9.com/account/leaves/")
      .then((response) => response.json())
      .then((data) => setLeaveRequest(data))
      .catch((err) => console.log(err));
  }, []);

  //toggle for admin table row toshow more details

  const [showDetails, setShowDetails] = useState(null);

  const handleRowClick = (index) => {
    setShowDetails((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleAdminAction = (leaveId, action) => {
    const updatedLeaveStatus = {
      admin_approve: action === "approve",
      admin_cancel: action === "cancel",
    };

    fetch(`https://hrmsadmin.hola9.com/account/leaves/${leaveId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedLeaveStatus),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Leave status updated:", data);
        alert("Leave status updated");
        setLeaveRequest((prevLeaveRequests) =>
          prevLeaveRequests.map((leave) =>
            leave.id === leaveId ? { ...leave, ...updatedLeaveStatus } : leave
          )
        );
      })
      .catch((error) => {
        console.error("Error updating leave status:", error);
      });
  };

  // ------------------------------------------------------ user config ------------------------------------------------ //

  const [leaveReq, setLeaveReq] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`https://hrmsadmin.hola9.com/account/leaves/`)
      .then((response) => response.json())
      .then((data) => {
        setLeaveReq(data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar />
      {isLoading ? (
        <center className="loading-indicator">
          <img
            src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
            alt="---loading"
            className="emp-loading"
            style={{ width: "350px", height: "350px", margin: "0" }}
          />
        </center>
      ) : (
        <div className="width nav-m-top" style={{ minHeight: "10rem" }}>
          <div style={{ paddingTop: "20px" }}>
            <LeaveFrom />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "30px 0",
            }}
          >
            {loggedInUser ||
              logintesting ||
              loginbackend ||
              logintl ||
              loginbackendtl ||
              logintltest ? (
              <div
                style={{
                  background: "white",
                  minHeight: "10rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: "40px",
                }}
              >
                <span
                  style={{
                    width: "170px",
                    background: "green",
                    height: "110px",
                    color: "white",
                    padding: "5px 20px",
                    borderRadius: "7px",
                  }}
                >
                  <b>Annaul leave</b>
                  <h1 style={{ color: "white" }}>15</h1>
                  <p style={{ color: "rgba(250, 250, 250, 0.9)" }}>Available</p>
                </span>

                <span
                  style={{
                    width: "170px",
                    background: "red",
                    height: "110px",
                    color: "white",
                    padding: "5px 20px",
                    borderRadius: "7px",
                  }}
                >
                  <b>Sick leave</b>
                  <h1 style={{ color: "white" }}>15</h1>
                  <p style={{ color: "rgba(250, 250, 250, 0.9)" }}>Available</p>
                </span>

                <span
                  style={{
                    width: "170px",
                    background: "blue",
                    height: "110px",
                    color: "white",
                    padding: "5px 20px",
                    borderRadius: "7px",
                  }}
                >
                  <b>Casual leave</b>
                  <h1 style={{ color: "white" }}>15</h1>
                  <p style={{ color: "rgba(250, 250, 250, 0.9)" }}>Available</p>
                </span>
              </div>
            ) : loggedInadmin ? (
              <div
                style={{
                  width: loggedInadmin ? "600px" : "550px",
                  background: "white",
                  minHeight: "10rem",
                  padding: "30px",
                }}
              >
                <EmpLeaveData />
              </div>
            ) : null}

            <div
              style={{
                width: loggedInadmin ? "600px" : "550px",
                background: "white",
                minHeight: "10rem",
                padding: "30px",
              }}
            >
              <EventsLeave />
            </div>
          </div>

          <div>
            <span
              className="width"
              style={{
                background: storedColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "13px 30px",
                boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h5 style={{ color: "white" }}>
                {loggedInUser || logintesting || loginbackend
                  ? "Applied Leaves"
                  : logintl || loginbackendtl || logintltest
                    ? "Applied Leaves"
                    : loggedInadmin
                      ? "Leave Requests"
                      : null}
              </h5>
            </span>
            <br />
            {loggedInUser ||
              logintesting ||
              loginbackend ||
              logintl ||
              loginbackendtl ||
              logintltest ? (
              <div className="width">
                <table style={{ width: "100%" }}>
                  <tr
                    style={{
                      textAlign: "center",
                      boxShadow: "1px 1px 1px gray",
                      height: "50px",
                    }}
                  >
                    <th>Leave Type</th>
                    <th>From Date - To Date</th>
                    <th>Applied Date</th>
                    <th>Status</th>
                  </tr>

                  {leaveReq.map((data) => (
                    <tr
                      style={{
                        textAlign: "center",
                        boxShadow: "1px 1px 1px gray",
                        height: "50px",
                      }}
                    >
                      <td>{data.leave_type}</td>
                      <td>{`${data.from_date} - ${data.to_date}`}</td>
                      <td>{data.posted_date}</td>
                      <td style={{ color: "darkorange" }}>
                        <>
                          {data.admin_approve ? (
                            <b
                              style={{
                                background: "green",
                                color: "white",
                                padding: "5px 10px",
                                borderRadius: "5px",
                              }}
                            >
                              Approved
                            </b>
                          ) : data.admin_cancel ? (
                            <b
                              style={{
                                background: "red",
                                color: "white",
                                padding: "5px 10px",
                                borderRadius: "5px",
                              }}
                            >
                              Denied
                            </b>
                          ) : (
                            <b>Waiting For Approval...</b>
                          )}
                        </>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            ) : // ) : logintl || loginbackendtl || logintltest ? (
              // 'Applied Leaves'
              loggedInadmin ? (
                <div className="width">
                  <table style={{ width: "100%" }}>
                    <tr
                      style={{
                        textAlign: "center",
                        boxShadow: "1px 1px 1px gray",
                        height: "50px",
                      }}
                    >
                      <th>Posted Date</th>
                      <th>Employee Name</th>
                      <th>Leave Type</th>
                      <th>From Date - To Date</th>
                      <th>Status</th>
                    </tr>
                    {leaveRequest.map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          onClick={() => handleRowClick(index)}
                          style={{
                            textAlign: "center",
                            boxShadow: "1px 1px 1px gray",
                            height: "50px",
                            cursor: "pointer",
                          }}
                        >
                          <td>{data.posted_date}</td>
                          <td>{data.name}</td>
                          <td>{data.leave_type}</td>
                          <td>
                            {data.from_date} - {data.to_date}
                          </td>
                          <td style={{ color: "white" }}>
                            {!data.admin_approve && !data.admin_cancel && (
                              <div>
                                <button
                                  onClick={() =>
                                    handleAdminAction(data.id, "approve")
                                  }
                                  style={{
                                    background: "green",
                                    color: "white",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    handleAdminAction(data.id, "cancel")
                                  }
                                  style={{
                                    background: "red",
                                    color: "white",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                            {data.admin_approve && (
                              <button
                                onClick={() =>
                                  handleAdminAction(data.id, "cancel")
                                }
                                style={{
                                  background: "red",
                                  color: "white",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                }}
                              >
                                Cancel
                              </button>
                            )}
                            {data.admin_cancel && (
                              <button
                                onClick={() =>
                                  handleAdminAction(data.id, "approve")
                                }
                                style={{
                                  background: "green",
                                  color: "white",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                }}
                              >
                                Approve
                              </button>
                            )}
                          </td>
                        </tr>
                        {showDetails === index && (
                          <div>
                            <p>{`subject : ${data.subject}`}</p>
                            <p>{`tlname : ${data.tlname}`}</p>
                            <p>{`department : ${data.department}`}</p>
                            <p>{`description : ${data.description}`}</p>
                            <p>{`admin approve : ${data.admin_approve}`}</p>
                            <p>{`tl approve : ${data.tl_approve}`}</p>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </table>
                </div>
              ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default LeaveHome;
