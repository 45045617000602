import React from 'react'
const Card = ({children,className,onClick}) => {
    // const {}
  return (
    <article className={`NotificationCard ${className}`} onClick={onClick}>
      {children}
    </article>
  )
}

export default Card