import React, { useState } from 'react';
import './LoginForm.css';
import Navbar from '../../section/navbar/Navbar';
import Adminlogin from '../login/Adminlogin';
import Login from '../login/UserLogin';
import { Link } from 'react-router-dom';
import Color from '../../section/navbar/Color';

const LoginForm = () => {
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const [isEmployeeLogin, setIsEmployeeLogin] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [activeButton, setActiveButton] = useState("employee"); // Default active button




  const handleTogglePanel = () => {
    setIsSignUpActive((prev) => !prev);
    setIsEmployeeLogin(false);
    setName('');
  };

  const handleEmployeeLogin = () => {
    setIsSignUpActive(false);
    setIsEmployeeLogin(true);
    setActiveButton("employee");

  };

  const handleAdminLogin = () => {
    setIsSignUpActive(true);
    setIsEmployeeLogin(false);
    setActiveButton("admin");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('Submitted:', { name, email, password, isEmployeeLogin });
  };

  const pri = "sss^7FvbbPric"
  return (


    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      {/* <Navbar/> */}
      <div>
        <div className="button-container">
          <button
            className={`employee-sign-in-button ${activeButton === "employee" ? "active" : ""}`}
            onClick={handleEmployeeLogin}
          >
            Employee Login
          </button>
          <button
            className={`admin-sign-in-button ${activeButton === "admin" ? "active" : ""}`}
            onClick={handleAdminLogin}
          >
            Admin Login
          </button>
        </div>
        <div className={`main-container ${!isSignUpActive ? 'right-panel-active' : 'right-panel-notactive'}`} id="container">

          <div className="form-container sign-in-container">
            {!isEmployeeLogin ? (
              <Adminlogin />
            ) : (
              <Login />
            )}
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className={`overlay-panel overlay-right ${isSignUpActive ? 'right-panel-active' : ''}`}>
                <img src='https://static.vecteezy.com/system/resources/previews/005/861/670/non_2x/user-key-concepts-vector.jpg' style={{ borderRadius: "50%" }} alt='login' height={200} width={200} />
                <h4 className='heading-awi'>Welcome, Admin!</h4>
                <p className='message'>Enter your personal details and start the journey with us</p>
                {/* Removed the "Sign Up" button */}
                {/* {!isEmployeeLogin && (
                    <Link to={`/Pricing/${pri}`}>
                      <button className="ghost" style={{ borderRadius: "10px" }}> 
                        Sign In
                      </button></Link>
                  )} */}
              </div>
              <div className={`overlay-panel overlay-left ${isSignUpActive ? 'right-panel-active' : ''}`}>
                <img src='https://cdn2.iconfinder.com/data/icons/flat-style-svg-icons-part-1/512/user_login_man-1024.png' alt='login' height={200} width={200} />
                <h4 className='heading-awi'>Welcome Employee</h4>
                <p className='message'>To keep connected with us, please login with your personal info</p>
                {!isEmployeeLogin && (
                  <Link to={`/Pricing/${pri}`}>

                    <button className="ghost" style={{ borderRadius: "10px" }} >
                      Sign In
                    </button>
                  </Link>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>




  );
};

export default LoginForm;


