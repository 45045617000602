import React from "react";
import "./MainHome.css";
import EmpofWeek from "./EmpofWeek";
import NotificationHome from "./NotificationHome";
import EventsHome from "./EventsHome";
import NameHome from "./NameHome";
import HomeHeader from "./HomeHeader";
import InstructionHome from "./InstructionHome";
import NewsfeedHome from "./NewsfeedHome";
import Checkcred from "../../section/navbar/Checkcred";

const MainHome = () => {
  const storedColor = localStorage.getItem('selectedColor');

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));

  return (
    <div>
      <div className="background-container" style={{background:storedColor}}></div>
      <NameHome />
      <div className="mainHome-div">
        <HomeHeader />
        <NotificationHome />
        <EventsHome />
      </div>
      <div className="mainHOME-INST">
        {/* <InstructionHome /> */}
        <EmpofWeek />
  
        <NewsfeedHome  name2001={loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : loggedInadmin?"Hola9": null}
        />
        </div>
    </div>
  );
};

export default MainHome;
