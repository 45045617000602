// import React from 'react'
// import "./footer.css"
// import { Link } from 'react-router-dom'

// const Footer = () => {
//   return (
//     <div className="footer">
//     <div className="footer-card1">
//       <span className="card1-ourlinks">Our Quick Links<div id="footer-displayline"></div></span>
//           <dl className="card1-lists">
//           <dt><Link to="/UserLogin" id="card1-lists">Employee login</Link></dt>
//           <dt> <Link to="/AdminLogin" id="card1-lists">Admin login</Link></dt>
//           <dt><a href="#about" id="card1-lists">About us</a></dt>
//           <dt><a href="#contact" id="card1-lists">Contact us</a> </dt>
//           {/* <dt><a href="https://hola9.com/career/" id="card1-lists">Careers</a> </dt> */}
//           </dl>
//   </div>
//   <div className="footer-card2">
//     <span className="card2-address">Address information<p id="footer-displayline"></p></span>
//     <address
//               style={{ color: "#fce0b1", lineHeight: "25px", fontSize: "15px" }}
//             >
//               #12 Second Floor 3rd Cross <br />
//               Patel Narayana Reddy Layout
//               <br />
//               6th Block Koramangala
//               <br />
//               Bengaluru - 560095
//               <br></br>
//               <b style={{ color: "#fce0b1", fontWeight: "bold" }}>Email </b>:
//               hello@hola9.com
//               <br></br>
//               <b style={{ color: "#fce0b1", fontWeight: "bold" }}>
//                 Contact No{" "}
//               </b>
//               : +919606451628
//             </address>
//   </div>

//   <div className="footer-card4">
//     <span className="card4-links">Technologies<p id="footer-displayline"></p></span>
//         <ul className="card4-allbuttons">
//           <a href="" className="card4-button">
//             <button id="card4-button">javascript</button>
//           </a>
//           <a href="" className="card4-bikes">
//             <button id="card4-button">Reactjs</button>
//           </a>
//           <a href="" className="card4-cars">
//             <button id="card4-button">Python</button>
//           </a>
//         </ul>
//         <ul className="card4-allbuttons">
//           <a href="" className="card4-button">
//             <button id="card4-button">Django</button>
//           </a>
//           <a href="" className="card4-bikes">
//             <button id="card4-button">Php</button>
//           </a>
//         </ul>
//         <ul className="card4-allbuttons">
//           <a href="" className="card4-button">
//             <button id="card4-button">C#</button>
//           </a>
//           <a href="" className="card4-bikes">
//             <button id="card4-button">Angular</button>
//           </a>
//           <a href="" className="card4-cars">
//             <button id="card4-button">Vue</button>
//           </a>
//         </ul>

//   </div>
//   </div>
//   )
// }

// export default Footer

import React, { useEffect, useState } from "react";
import "./footer.css";
import data from "../header/data";
import { Link, useNavigate } from "react-router-dom";
import MobileDetect from 'mobile-detect';
const Footer = () => {
  const [activeButton, setActiveButton] = useState(false); // Default active button
  const navigate = useNavigate();
  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
    const logintl = JSON.parse(localStorage.getItem("logintl"));
    const logintesting = JSON.parse(localStorage.getItem("logintesting"));
    const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
    const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
    const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));

    console.log("loggedInUser", loggedInUser);
    console.log("loggedInadmin", loggedInadmin);
    console.log("logintl", logintl);
    console.log("logintesting", logintesting);
    console.log("logintltest", logintltest);
    console.log("loginbackend", loginbackend);
    console.log("loginbackendtl", loginbackendtl);
    // Check if any of the items is not present
    if (!loggedInUser && !loggedInadmin && !logintl && !logintesting && !logintltest && !loginbackend && !loginbackendtl) {
      navigate("/login");
      setActiveButton(true);

    }
  }, []);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobileDevice = md.mobile() !== null || md.tablet() !== null;

    setIsMobile(isMobileDevice);
  }, []);

  useEffect(() => {
    if (isMobile) {
      // Redirect to the mobile error page
      navigate('/mobile-error');
      setActiveButton(true);
    }
  }, [isMobile, navigate]);
  const storedColor = localStorage.getItem('selectedColor');

  return (
    <footer >
      {/* <div className="footer-main" style={{background:storedColor}}>
        <div className="footer-containers">
          <div className="footer-container12">
            <div className="footer-container1">
              <a
                href="/AboutUs"
                className="footer-container1-para"
                title="About us"
              >
                About us
              </a>
              <a
                href="/ContactUs"
                className="footer-container1-para"
                title="Contact us"
              >
                Contact us
              </a>
              <a href="" className="footer-container1-para" title="Careers">
                Careers
              </a>
              <a
                href=""
                className="footer-container1-para"
                title="Terms of Services"
              >
                Terms of Services
              </a>
            </div>
            <div className="footer-container2">
              <i style={{ margin: "0px" }}>#12 Second Floor 3rd Cross</i>
              <i style={{ margin: "0px" }}>Patel Narayana Reddy Layout</i>
              <i style={{ margin: "0px" }}>6th Block Koramangala</i>
              <i style={{ margin: "0px" }}>Bengaluru - 560095</i>
            </div>
          </div>
          <div className="footer-container3">
            <div className="footer-container3-all">
              <div className="footer-container3-one">
                <a
                  href=""
                  className="footer-container3-first f-c-f"
                  title="email"
                >
                  <i class="footer-container3-first-envelope">
                    <i className="fas fa-envelope"></i>
                  </i>
                  <p className="f-c-f-p">hello@hola9.com</p>
                </a>
                <div
                  className="footer-container3-first"
                  style={{ marginTop: "10px" }}
                >
                  <i class="fas footer-container3-first-envelope">
                    <i className="fas fa-phone-alt"></i>
                  </i>
                  <a
                    class="footer-container3-first-link-number f-c-f-p"
                    href="tel:9606451628"
                    title="Toll Free"
                  >
                    +919606451628
                  </a>
                </div>
              </div>

              <hr className="footer-container3-line" />
              <div className="footer-container3-third">
                {data.map((item) => (
                  <a
                    key={item.id}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="footer-bottom" style={{ background: storedColor }}>
        2022 © Hola9 Classifieds India Private Limited
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', fontSize: '17px' }}>
          <Link to='https://hola9.com/' target="_blank">
            Hola9
          </Link>
          <span style={{ margin: '-5px 10px 0px 10px', fontSize: '20px' }}> | </span>
          {data.map((item, index) => (
            <a
              key={`${item.id}-${index}`}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer" style={{ margin: '0 7px -4px 7px', fontSize: '20px', background: storedColor }}> {item.icon}</a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
