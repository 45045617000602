import React, { useEffect, useState } from "react";
// import data from "../../section/header/data";
import "../../section/header/header.css";
import { AiOutlineInstagram, AiOutlineTwitter, AiOutlineFacebook, AiOutlineLinkedin } from "react-icons/ai"

const HomeHeader = () => {
  const [company, setCompany] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [storedColor, setStoredColor] = useState(localStorage.getItem('selectedColor'));

  const FetchCompanyData = async () => {
    try {
      const response = await fetch(
        "https://hrmsadmin.hola9.com/account/adminapiget/"
      );
      if (!response.ok) {
        throw new Error("Network response not ok");
      }
      const data = await response.json();
      setCompany(data);
      console.log("companydata", data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    FetchCompanyData();
  }, []);

  useEffect(() => {
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const intervalId = setInterval(nextSlide, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  const images = [
    "https://content3.jdmagicbox.com/comp/goa/f2/0832px832.x832.220527185136.f5f2/catalogue/ak-rental-car-and-bike-aquem-goa-car-rental-lxcqq8w6qy.jpg",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8HWaO3xEjMoH1cxMDlMg8_bExFIZyd1V-QA&usqp=CAU",
    "https://www.sahivalue.com/product-images/14+pro+max.jpg/293890000021697778/400x400",
    "https://images.saymedia-content.com/.image/ar_1:1%2Cc_fill%2Cs‌​_rgb%2Cq_auto:eco%2C‌​w_1200/MTk3MjI5OTczM‌​DA0NjI0OTc5/cute-pet‌​-names.png",
    "https://shop.gkwretail.com/cdn/shop/products/Designer-Sofa-Set-in-Fabric-L-Shape_2.png?v=1651899140",
    "https://www.gooezy.com/uiassets/images/serviceslist/officecleaning.jpg",
  ];

  return (
    <div className="homeHeaderContainer">
      <div className="headerLeftSection">
        {company.map((item, index) => (
          <div key={`${item.id}-${index}`}>
            {item.organization === "npm" && (
              <div >
                <h2 className="headerTitle" style={{ color: storedColor }}>
                  {item.organization}
                </h2>
                <p className="headerParagraph">
                  {item.about}
                </p>
              </div>
            )}
          </div>
        ))}
        <div className="header__cta">
          <a href="#contact" className="btn" style={{ background: storedColor }}>
            Let's Talk
            <i className="fas fa-arrow-right"></i>
          </a>
          <a href="#about" className="btn light" style={{ background: storedColor }}>
            Detail's
            <i className="fas fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div className="headerRightSection">
        <img
          src={images[currentIndex]}
          alt="headerimage"
          className="headerImage"
        />
        <div className="headerSocials header__socials">
          {company.map((item) => (
            <div key={item.id} >
              {item.organization === "npm" && (
                <>
                  <div style={{ marginBottom: "24px" }}>
                    {item.social_media_links.instagram && (
                      <a
                        href={item.social_media_links.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="headerSocialLink"
                        style={{ background: storedColor }}
                      >
                        <AiOutlineInstagram />
                      </a>
                    )}
                  </div>
                  <div style={{ marginBottom: "24px" }}>
                    {item.social_media_links.facebook && (
                      <a
                        href={item.social_media_links.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="headerSocialLink"
                        style={{ background: storedColor }}
                      >
                        <AiOutlineFacebook />
                      </a>
                    )}
                  </div>
                  <div style={{ marginBottom: "24px" }}>
                    {item.social_media_links.linkedin && (
                      <a
                        href={item.social_media_links.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="headerSocialLink"
                        style={{ background: storedColor }}
                      >
                        <AiOutlineLinkedin />
                      </a>
                    )}
                  </div>
                  <div style={{ marginBottom: "24px" }}>
                    {item.social_media_links.twitter && (
                      <a
                        href={item.social_media_links.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="headerSocialLink"
                        style={{ background: storedColor }}
                      >
                        <AiOutlineTwitter />
                      </a>
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
