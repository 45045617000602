import React from "react";
import "./OurTrustedCompanies.css";
import Hola9 from "../../Home/featuresImages/report.webp";
import Hola91 from "../../Home/featuresImages/Chat.webp";
import Hola92 from "../../Home/featuresImages/Instruction.jpg";
import Hola93 from "../../Home/featuresImages/NewsFeed.webp";
import Hola94 from "../../Home/featuresImages/Mails.webp";

const OurTrustedCompanies = () => {
  const companyLogo = [
    { img: Hola9 },
    { img: Hola91 },
    { img: Hola92 },
    { img: Hola93 },
    { img: Hola94 },
  ];
  return (
    <>
      <div className="main001 ourTrusted-div">
        <div
          style={{
            width: "40%",
            display: "flex",
            justifyContent: "right",
            flexDirection: "column",
            alignItems: "end",
            paddingBottom: "80px",
            paddingTop: "100px",
          }}
        >
          <h2>We Work With the</h2>
          <h2>Best Projects</h2>
          <br />
          <p style={{ textAlign: "right" }}>
            Our Vision is to make Hola9 one stop solution for local
            businesses, classifieds and largest business-to-business
            marketplace for India Where you can get business to business,
            Business to Customer and Customer to customer services at one
            place{" "}
            <ins>
              <b>E-Commerce Platform</b>
            </ins>
            , focuses on expert services around Home, Life and Self and where
            the user need is customized
          </p>
        </div>
        <div
          style={{
            width: "55%",
            display: "flex",
            flexWrap: "wrap",
            padding: "50px",
            gap: "50px",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="top-cat-icons"
        >
          {companyLogo.map((logo, index) => (
            <img key={`${logo.id}-${index}`} src={logo.img} alt="Hola9" width={130} height={60} />
          ))}
        </div>
      </div>
    </>
  );
};

export default OurTrustedCompanies;
