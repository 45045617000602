import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../section/navbar/Navbar";

import "./allemp.css";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { GiConversation } from "react-icons/gi";

const Allempdash = () => {
  const storedColor = localStorage.getItem("selectedColor");

  const { name } = useParams();

  const [isLoading1, setIsLoading1] = useState(true);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    fetch(`https://hrmsadmin.hola9.com/account/loginprofile/?name=${name}`)
      .then((response) => response.json())
      .then((data) => {
        setDetails(data);
        setIsLoading1(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [name]);

  const navigate = useNavigate();

  const handleChat = (e) => {
    navigate(`/profileDashboard/${name}`);
  };

  return (
    <>
      <Navbar />

      <div
        style={{ height: "150px", background: storedColor }}
        className="background-container"
      ></div>
      {isLoading1 ? (
        <img
          src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
          alt="---loading"
          height={150}
          width={150}
          style={{ marginTop: "70px" }}
        />
      ) : (
        <>
          {details.map((user) => (
            <div className="Allempdash-div">
              <div className="Allempdash-img-div">
                <img className="Allempdash-img" src={user.image} />
                <p>{user.role}</p>
              </div>
              <div className="Allempdash-cont-div">
                <sapn style={{ display: "flex", alignItems: "center" }}>
                  <h3 className="Allempdash-c-h3">{user.name}&nbsp; &nbsp;</h3>
                  <GiConversation
                    className="icon-style-prof"
                    title={`Chat with ${user.name}`}
                    onClick={handleChat}
                  />
                  {user.facebook_link ? (
                    <FaFacebookSquare
                      className="icon-style-prof"
                      onClick={() => window.open(user.facebook_link, "_blank")}
                    />
                  ) : null}
                  {user.insta_link ? (
                    <FaInstagram
                      className="icon-style-prof"
                      onClick={() => window.open(user.insta_link, "_blank")}
                    />
                  ) : null}
                  {user.linkedin_link ? (
                    <FaLinkedinIn
                      className="icon-style-prof"
                      onClick={() => window.open(user.linkedin_link, "_blank")}
                    />
                  ) : null}
                  {user.twitter_link ? (
                    <FaTwitter
                      className="icon-style-prof"
                      onClick={() => window.open(user.twitter_link, "_blank")}
                    />
                  ) : null}
                </sapn>

                <div
                  className="Allempdash-cont-sub-div"
                  style={{ marginTop: "25px" }}
                >
                  <h5>PERSONAL</h5>

                  <div className="Allempdash-cont-details-div Allempdash-cont-details-div-flex">
                    <span>
                      <h6>Name</h6>
                      <p style={{ textTransform: "uppercase" }}>{user.name}</p>
                    </span>
                    <span>
                      <h6>Date of Birth</h6>
                      <p>{user.dob}</p>
                    </span>
                    <span>
                      <h6>Blood Group</h6>
                      <p>{user.blood_group}</p>
                    </span>
                    <span>
                      <h6>Phone Number</h6>
                      <p>+91 {user.phone_number} </p>
                    </span>
                    <span>
                      <h6>Marital Status</h6>
                      <p>{user.marital_status}</p>
                    </span>
                    <span>
                      <h6>Gender</h6>
                      <p>{user.gender}</p>
                    </span>
                  </div>
                </div>

                <div className="Allempdash-cont-sub-div">
                  <h5>PROFESSIONAL</h5>

                  <div className="Allempdash-cont-details-div Allempdash-cont-details-div-flex">
                    <span>
                      <h6>Employee ID</h6>
                      <p>{user.userid}</p>
                    </span>
                    <span>
                      <h6>Reporting</h6>
                      <p>{user.reporting_to}</p>
                    </span>
                    <span>
                      <h6>Designation</h6>
                      <p>{user.role}</p>
                    </span>
                    <span>
                      <h6>mail ID</h6>
                      <p>{user.email}</p>
                    </span>
                    <span>
                      <h6>Joining Date</h6>
                      <p>{user.working_since}</p>
                    </span>
                  </div>
                </div>

                <div className="Allempdash-cont-sub-div">
                  <h5>PROJECTS</h5>

                  <div className="Allempdash-cont-details-div">
                    <span>
                      {user.project_name && (
                        <>
                          <h6>{user.project_name} &nbsp; </h6> | &nbsp;{" "}
                          <p>on going</p>
                        </>
                      )}
                    </span>
                    {/*<span>
                <h6>Services website &nbsp; </h6> | &nbsp;{" "}
                <p>09-10-23 to 05-01-24</p>
              </span> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Allempdash;
