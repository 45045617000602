import React, { useEffect, useState } from "react";
import "./navbar.css";
import data from "./data";
import logo from "../../images/Hola9logo.jpg";
import logo2 from "../../images/Hrms.png";
import { IoIosColorPalette } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { MdDashboard, MdHolidayVillage } from "react-icons/md";
import {
  BiBell,
  BiDownArrow,
  BiLogOut,
  BiNotification,
  BiPlus,
} from "react-icons/bi";
import { SiManageiq } from "react-icons/si";
import { FaHome, FaChartBar, FaFileAlt } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import Swal from 'sweetalert2';
import { FcGallery, FcLeave } from 'react-icons/fc';
import LoginDetails from '../../components/login/LoginDetails';
import Color from "./Color";
import AllempSearch from "../../components/AllEmployeesDashboard/AllempSearch";
const storedColor = localStorage.getItem('selectedColor');

const Navbar = () => {
  const navigate = useNavigate();
  const [showLogoutDropdown, setShowLogoutDropdown] = useState(false);
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("loggedInadmin");
      localStorage.removeItem("logintl");
      localStorage.removeItem("logintesting");
      localStorage.removeItem("logintestingtl");
      localStorage.removeItem("loginbackend");
      localStorage.removeItem("loginbackendtl");
      localStorage.removeItem("lastlogintime");
      navigate("/login");
      setShowLogoutDropdown(false);
    }
  };

  const handleclick = () => {
    navigate("/profileDashboard");
  };
  const name = loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null;
  const items = [];

  if (loggedInadmin) {
    items.push(
      {
        key: "1",
        label: <p onClick={() => navigate("/notification")}>Notification</p>,
        icon: <BiBell />,
      },
      {
        key: "2",
        label: <p onClick={() => navigate("/holidays")}>Holidays</p>,
        icon: <MdHolidayVillage />,
      },
      {
        key: "3",
        label: <p onClick={() => navigate("/leave")}>Leaves</p>,
        icon: <FcLeave />,
      },
      {
        key: '4',
        label: (
          <button onClick={handleLogout}>
            Logout
          </button>
        ),
        icon: <BiLogOut />,
      }
    );
  } else if (!loggedInadmin) {
    items.push(
      {
        key: '5',
        label: (
          <Link to={`/profileDashboard/${loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null}`}>
            DashBoard
          </Link>
        ),
        icon: <MdDashboard />,
      },
      {
        key: '6',
        label: (
          <button onClick={handleLogout}>
            Logout
          </button>
        ),
        icon: <BiLogOut />,
      }
    );
  }

  return (
    <>
      <nav>
        <div className='container nav__container nav-bar-cont'>
          <Link
            to={loggedInUser || logintesting || loginbackend || logintl || loginbackendtl || logintltest || loggedInadmin ? "/" : "#"}
            onClick={(e) => {
              const isLoginPage = window.location.pathname.includes("login");
              if (isLoginPage && !(loggedInUser || logintesting || loginbackend || logintl || loginbackendtl || logintltest || loggedInadmin)) {
                e.preventDefault(); // Prevent navigation
              }
            }}
          >
            <img src={logo2} alt='logo' className='nav__logo' />
          </Link>
          {/* <ul className='nav__menu'>
            {loggedInadmin ? (
              <>
                <li>
                  <Link to='/' title="Home">
                    <FaHome style={{ fontSize: "30px" }} />
                  </Link>
                </li>
                <li>
                  <Link to={`/AdminDashboard/${loggedInadmin.email}`} title="DashBoard">
                    <FaChartBar style={{ fontSize: "30px" }} />
                  </Link>
                </li>
                <li>
                  <Link to="/addadmin" title="Add User">
                    <SiManageiq style={{ fontSize: "30px" }} />
                  </Link>
                </li>
              </>
            ) : null}
          </ul> */}
          {loggedInUser || logintesting || loginbackend || logintl || loginbackendtl || logintltest || loggedInadmin ? (
            <AllempSearch />
          ) : null}
          <div>
            {loggedInUser || logintesting || loginbackend || logintl || loginbackendtl || logintltest ? (
              <div style={{ display: "flex", gap: '10px' }}><LoginDetails name={name} />
                <Color /></div>
            ) : loggedInadmin ? (
              <>
                <div style={{ display: "flex" }}>
                  {/* <div className="user-welcome">
                    <Dropdown menu={{ items }}>
                      <Space>
                        <span style={{ color: "navy", fontWeight: "bold" }}><BiDownArrow/> Welcome</span>
                        <span style={{ color: "red", fontWeight: "bold" }}>Admin</span>
                      </Space>
                    </Dropdown>
                  </div> */}
                  <Link to="/Assigntask">
                    <button className='taskbtn2001' style={{ background: storedColor }}>
                      Assign-Task
                    </button>
                  </Link>
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  <Color />

                </div>
              </>
            ) : null}
            {/* (
              <>
                <Link to="/UserLogin">
                  <button id='theme__icon'>Employee Login</button>
                </Link>
                &nbsp;
                &nbsp;
                &nbsp;
                <Link to="/AdminLogin">
                  <button id='theme__icon1'>Admin Login</button>
                </Link>
              </>
            ) */}
          </div>

        </div>
      </nav>
    </>
  )
}

export default Navbar;

