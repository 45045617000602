import React, { useState } from "react";
import "./ProfileDashboard.css";
import logo from "../../../images/Hrms.png";
import Navbar from "../../../section/navbar/Navbar";
import ProfileDashCard from "./ProfileCards/ProfileDashCard";
import { useNavigate } from "react-router-dom";


import EmployeeTabledetail from "../Employeetabledetails";
import EmployeeTable2 from "../EmployeeTable";
import Employeetask1 from "../Employeetask1";
import Personalslip from "../Personalslip";

import Notification from "../../navDropdown/Notification/Notification";

import LeaveHome from "../../navDropdown/Leave/LeaveHome";
import Holidays from "../../navDropdown/Holidays/Holidays";
import ProjectDetails from "../../Dashboard/ProjectDetails";
import Admintask from "../Employeetaskad";
import Testingtl from "../Employeetestinglead";
import Assigntask from "../../Dashboard/Assigntask";
import Assigntasktl from "../../Dashboard/Assigntesting";
import Assigntaskbackend from "../../Dashboard/Assigntaskbackend";
import MailForm from "../../Dashboard/Sendmail";
import Gallery from "../Gallery/Gallery";
import Chatboard from "../Chats.jsx/Chatboard";
import EditProfile from "../../Dashboard/EditProfile";
// import Productdetailadmin from '../../Dashboard/Productdetailadmin'

const ProfileDashboard = () => {
  const [active, setActive] = useState("");
  // const [active, setActive] = useState("profile");
  const [activeCss, setActiveCss] = useState("");

  const navigate = useNavigate();

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));


  const background = {
    color: "gray",
  };
  const faarrow = {
    borderRadius: "50%",
    backgroundColor: "white",
    padding: "3px",
    cursor: "pointer",
  };


  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");

    if (confirmLogout) {
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("loggedInadmin");
      localStorage.removeItem("logintl");
      localStorage.removeItem("logintesting");
      localStorage.removeItem("logintestingtl");
      localStorage.removeItem("loginbackend");
      localStorage.removeItem("loginbackendtl");
      localStorage.removeItem("lastlogintime");
      window.location.reload();

      navigate("/login");
    }
  };

  const AddProfile = () => {
    setActive("EditProfile")
  }
  const storedColor = localStorage.getItem('selectedColor');

  return (
    <>
      <Navbar />
      <div className="top-margin" style={{ background: storedColor }}  ></div>
      <div style={{ display: "flex" }}>
        <div
          className={
            activeCss === "close-side-bar" ? "open-side-bar" : "close-side-bar"
          }
          style={{ background: storedColor }}
        >
          <span className="arrow-span">
            {activeCss === "close-side-bar" ? (
              <i
                className="fas fa-arrow-left"
                style={faarrow}
                onClick={() => setActiveCss("open-side-bar")}
              ></i>
            ) : (
              <i
                className="fas fa-arrow-right"
                style={faarrow}
                onClick={() => setActiveCss("close-side-bar")}
              ></i>
            )}
          </span>
          <span className="img-span">
            <img src={logo} width={110} alt="logo" />
          </span>
          <span className="prof-heading  slide-prof-heading">
            <span onClick={() => navigate("/")}>
              <i className="fas fa-home"></i>
              <p>Home</p>
            </span>
            <span
              onClick={AddProfile}
            >
              <i className="fas fa-user-edit" style={active === "EditProfile" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Edit Profile</> : <>Edit</>}
              </p>
            </span>
            <span
              onClick={() => setActive("Chats")}
            >
              <i className="fas fa-comments" style={active === "Chats" ? { ...background } : null}></i>
              <p>Chats</p>
            </span>
            <span
              onClick={() => setActive("Reports")}
            >
              <i className="fas fa-user" style={active === "Reports" ? { ...background } : null}></i>
              <p>Reports</p>
            </span>

            <span
              onClick={() => setActive("YourDetails")}
            >
              <i className="fas fa-address-card" style={active === "YourDetails" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Your Details</> : <>logs</>}
              </p>
            </span>
            <span
              onClick={() => setActive("Notifications")}
            >
              <i className="fas fa-bell" style={active === "Notifications" ? { ...background } : null}></i>
              <p>Notifications</p>
            </span>
            {loggedInadmin ? (
              <span
                onClick={() => setActive("Mail")}
              >
                <i className="fas fa-envelope" style={active === "Mail" ? { ...background } : null}></i>
                <p>Mail</p>
              </span>
            ) : null}
            <span
              onClick={() => setActive("CheckIn")}
            >
              <i className="fas fa-sign-in-alt" style={active === "CheckIn" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? (
                  <>Check-In Details</>
                ) : (
                  <>Check-in</>
                )}
              </p>
            </span>
            <span
              onClick={() => setActive("Assigned")}
            >
              <i className="fas fa-list" style={active === "Assigned" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Tasks List</> : <>Tasks</>}
              </p>
            </span>
            {loginbackendtl || logintl || logintltest ? (
              <span
                onClick={() => setActive("task")}
              >
                <i className="fas fa-list" style={active === "task" ? { ...background } : null}></i>
                <p>
                  {activeCss === "close-side-bar" ? <>Assign Task</> : <>Assign</>}
                </p>
              </span>) : null}
            <span
              onClick={() => setActive("Leave")}
            >
              <i className="fas fa-calendar-day" style={active === "Leave" ? { ...background } : null}></i>
              <p>Leave</p>
            </span>
            <span
              onClick={() => setActive("Holidays")}
            >
              <i className="fas fa-candy-cane" style={active === "Holidays" ? { ...background } : null}></i>
              <p>Holidays</p>
            </span>
            <span
              onClick={() => setActive("payslip")}
            >
              <i className="fas fa-money-check-alt" style={active === "payslip" ? { ...background } : null}></i>
              <p>Payslip</p>
            </span>
            <span
              onClick={() => setActive("gallery")}
            >
              <i className="fas fa-image" style={active === "gallery" ? { ...background } : null}></i>
              <p>Gallery</p>
            </span>
            <span
              onClick={handleLogout}
            >
              <i className="fas fa-sign-out-alt"></i>
              <p>Logout</p>
            </span>
          </span>
        </div>

        <div style={{ padding: "30px", width: "100%" }}>
          {active === "Mail" ? null : active === "payslip" ? null : active === "Chats" ? null : (
            <div
              style={{
                borderBottom: "1px solid black",
                minHeight: "5rem",
                display: "flex",
              }}
            >
              <ProfileDashCard name={loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null}
                email={loggedInUser ? loggedInUser.email : logintesting ? logintesting.email : loginbackend ? loginbackend.email : logintl ? logintl.email : logintltest ? logintltest.email : loginbackendtl ? loginbackendtl.email : null}
                domain={loggedInUser ? "SoftWare Developer" : logintesting ? "Software Tester" : loginbackend ? "SoftWare Developer" : logintl ? "SoftWare Developer" : logintltest ? "Software Tester" : loginbackendtl ? "SoftWare Developer" : null}
                AddProfile={AddProfile} />
            </div>)}
          <div>
            {/* {active === "Home" ?  <Home/> :null} */}
            {active === "Reports" ? (
              <ProjectDetails name={loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null} domain={""} />
            ) : null}
            {active === "Mail" ? (
              <MailForm />
            ) : null}
            {active === "EditProfile" ? <>
              <EditProfile name={loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null} /> </> : null}
            {active === "YourDetails" ? (
              <EmployeeTabledetail name={loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null} />
            ) : null}
            {active === "Notifications" ? (
              <Notification name={loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null} />
            ) : null}
            {active === "CheckIn" ? (
              <EmployeeTable2 name={loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null} />
            ) : null}
            {active === "Assigned" ? (
              <>
                {loggedInUser ? (
                  <>
                    <Employeetask1 name={loggedInUser.name} />
                  </>
                ) : logintl ? (
                  <>
                    <Admintask name={logintl.name} /> </>
                ) : logintesting ? (
                  <>
                    <Testingtl name={logintesting.name} />
                  </>
                ) : logintltest ? (
                  <>   <Testingtl name={logintltest.name} />
                  </>
                ) : loginbackend ? (
                  <>   <Admintask name={loginbackend.name} /> </>
                ) : loginbackendtl ? (
                  <>   <Admintask name={loginbackendtl.name} /> </>
                ) : loggedInadmin ? (
                  <></>
                ) : null}
              </>) : null}
            {active === "task" ? (
              <>
                {logintl ? (
                  <>
                    <Assigntask name={logintl.name} /> </>
                ) : logintltest ? (
                  <>   <Assigntasktl name={logintltest.name} />
                  </>
                ) : loginbackendtl ? (
                  <>   <Assigntaskbackend name={loginbackendtl.name} /> </>
                ) : null}
              </>) : null}
            {active === "Leave" && <LeaveHome />}
            {active === "Chats" && <Chatboard />}
            {active === "Holidays" && <Holidays />}
            {active === "payslip" ? (
              <Personalslip name={loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null} />
            ) : null}
            {active === "gallery" && <Gallery />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
