import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';

const EmployeeApprove = ({ close }) => {

  const [formData, setFormData] = useState({
    id: 1,
    name: '',
    emp_id: '',
    description: '',
    emp_approve: true
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://hrmsadmin.hola9.com/account/empapprovdetail/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        // Handle success response if needed
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error response if needed
      });
  };
  const storedColor = localStorage.getItem("selectedColor");
  return (
    <>
      <div style={{ backgroundColor: 'white', width: '800px', minHeight: '100px', borderRadius: '10px', padding: '30px' }}>
        <form>
          <span style={{ float: 'right', height: '0', width: '100%', display: 'flex', justifyContent: 'right' }}><span onClick={close} style={{ cursor: 'pointer', position: 'absolute' }}>❌</span></span>
          <h3 style={{ textAlign: 'center', background: storedColor, backgroundClip: 'text', color: 'transparent', marginTop: '-20px' }}>Approval Request</h3>

          {/* <div style={{display:'flex',width:'100%',gap:'2%'}}>
                        
                    <div style={{width:'50%'}}>
                    <label>Name</label>
                    <input style={{width:'100%'}} type='text'></input>
                    </div>
                    <div style={{width:'50%'}}>
                    <label>Employee ID</label>
                    <input style={{width:'100%'}} type='text'></input>
                    </div>
                    </div> */}

          {/* <div  style={{display:'flex',width:'100%',gap:'2%'}}>
                   
                    <div style={{width:'50%'}}>
                    <label>Login Tme</label> <input style={{width:'100%'}} type='time'></input>
                    </div>
                    <div style={{width:'50%'}}>
                    <label>Logout Time</label> <input style={{width:'100%'}} type='time'></input>
                    </div>
                    </div> */}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Description</label><TextArea type="text" name="description" value={formData.description} onChange={handleChange} />
          </div>

          <button style={{ background: storedColor, width: '100%', color: 'white' }}>Send Approve Request To HR</button>
        </form>
        <p id="description" className="main-description" style={{ height: '0', }}>
          Hola9 Classifieds India Private Ltd.
        </p>
      </div>


    </>
  );
};

export default EmployeeApprove;