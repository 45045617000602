import React from 'react'
import aboutimage from "../../images/chad1.jpg"
import aboutimage1 from "../../images/chad2.jpg"
import aboutimage2 from "../../images/chad3.jpg"
import aboutimage3 from "../../images/chad4.jpg"
import aboutimage4 from "../../images/chad5.jpg"
import aboutimage5 from "../../images/chad6.jpg"
import bio from "../../images/atrangimain.jpg"
import{AiOutlineDownload} from "react-icons/ai"
import "./about.css"
import Card from '../../components/Card'
import data from './data'
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import Navbar from '../navbar/Navbar'
import Header from '../header/Header'
import Services from '../services/Services'
import Contact from '../contact/Contact'


const mob={
  height:"6cm",
  width:"4cm"

}

const desk={

}
const storedColor = localStorage.getItem('selectedColor');

const About = () => {
  return (
    <>
    <Navbar />


    <div style={{marginTop:'70px'}}></div>

    {/* <Header/> */}

    <section id="about">
      
      <div className='container about__container'>
      
        
          <div className='about__portrait' style={isMobile ? mob : desk}>
            <img src="https://media.wired.com/photos/64daad6b4a854832b16fd3bc/master/w_2560%2Cc_limit/How-to-Choose-a-Laptop-August-2023-Gear.jpg" alt='aboutiamge' height={320} width={150}/>
            <img src="https://img.freepik.com/free-photo/front-view-stacked-books-graduation-cap-ladders-education-day_23-2149241014.jpg?w=2000" alt='aboutiamge' height={320} width={150}/>
          </div>

  <div>
          <h2 style={{color: "#092144"}}>Hola9</h2>
          <p style={{fontFamily:"sans-serif",color: "#092144", fontWeight: "700"}}>
              Our Vision is to make Hola9 one stop solution for local
              businesses, classifieds and largest business-to-business
              marketplace for India Where you can get business to business,
              Business to Customer and Customer to customer services at one
              place <ins><b style={{color:"red"}}>ECommerce Platform</b></ins>, focuses on expert services around
              Home, Life and Self and where the user need is customized
            </p>
          <div className='about__cards'>
          {data.map(item=>(
              <Card key={item.id} className="about__card" >
                <span className='about__card-icon' style={{background:storedColor}}>{item.icon}</span>
                <h5>{item.title}</h5>
                <small>{item.desc}</small>
              </Card>
            ))}
          
          </div>
          {/* <a href={bio} download className='btn primary'>Download<AiOutlineDownload/></a> */}
          </div>  
        
      </div>
    
          
    </section>

    {/* <Services/> */}
    {/* <Contact/> */}

    </>
  )
}

export default About
