import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import './CompanyDetails.css'

const CompanyDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const storedColor = localStorage.getItem('selectedColor')
  const [company, setCompany] = useState([]);
  const [edit, setEdit] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [isLoading1, setIsLoading1] = useState(true);
  const [companyData, setCompanyData] = useState({
    name: '',
    organization: '',
    logo: null,
    company_type: '',
    company_since: '',
    color: '',
    company_address: '',
    social_links
      : {
      facebook: '',
      instagram: '',
      linkedin: '',
      twitter: '',
    },
    about: '',
    images: [],
    tagline: '',
    no_of_employees: '',
    domain: '',
    user_id: '',
    email: '',
    password: '',
  });


  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === 'social_media_links') {
  //     try {
  //       const parsedValue = JSON.parse(value);
  //       setCompanyData({ ...companyData, [name]: parsedValue });
  //     } catch (error) {
  //       console.error("Invalid JSON format");
  //     }
  //   } else if (name === 'logo' || name === 'images') {
  //     setCompanyData({ ...companyData, [name]: e.target.files[0] });
  //   } else {
  //     setCompanyData({ ...companyData, [name]: value });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    // Check if the change is for a social media link directly
    const socialMediaFields = ['facebook', 'instagram', 'linkedin', 'twitter'];
    if (socialMediaFields.includes(name)) {
      // Handle change for social media links
      setCompanyData(prevState => ({
        ...prevState,
        social_links: {
          ...prevState.social_links,
          [name]: value
        }
      }));
    } else if (type === 'file') {
      // Handle file input types
      setCompanyData(prevState => ({ ...prevState, [name]: files[0] }));
    } else {
      // Handle all other inputs
      setCompanyData(prevState => ({ ...prevState, [name]: value }));
    }
  };


  const FetchCompanyData = async () => {
    try {
      const response = await fetch(
        "https://hrmsadmin.hola9.com/account/adminapiget/"
      );
      if (!response.ok) {
        throw new Error("Network response not ok");
      }
      const data = await response.json();
      setCompany(data);
      setIsLoading(false);
      console.log("companydata", data);
      setIsLoading1(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    FetchCompanyData();
  }, []);


  const toggleForm = () => {
    console.log(

      'toggleform calling'
    )
    setShowForm(!showForm);
    setEdit(false);
    setCompanyData(
      {
        name: '',
        organization: '',
        logo: null,
        company_type: '',
        company_since: '',
        color: '',
        company_address: '',
        social_links: {
          facebook: '',
          instagram: '',
          linkedin: '',
          twitter: '',
        },
        about: '',
        images: [],
        tagline: '',
        no_of_employees: '',
        domain: '',
        user_id: '',
        email: '',
        password: '',
      });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', companyData.name);
      formData.append('organization', companyData.organization);

      formData.append('company_type', companyData.company_type);
      formData.append('company_since', companyData.company_since);
      formData.append('color', companyData.color);
      formData.append('company_address', companyData.company_address);

      // Append social media links if it's an object
      if (typeof companyData.social_links === 'object') {
        for (const [key, value] of Object.entries(companyData.social_links)) {
          formData.append(`social_links[${key}]`, value);
        }
      }

      formData.append('about', companyData.about);
      formData.append('tagline', companyData.tagline);
      formData.append('no_of_employees', parseInt(companyData.no_of_employees));
      formData.append('domain', companyData.domain);
      formData.append('user_id', companyData.user_id);
      formData.append('email', companyData.email);
      formData.append('password', companyData.password);

      // Now you can send fo
      if (companyData.images) {
        formData.append("images", companyData.images);
      }
      // Append logo file if available
      if (companyData.logo) {
        formData.append('logo', companyData.logo);
      }

      let url = "https://hrmsadmin.hola9.com/account/adminapi/";
      let method = "POST";

      if (edit) {
        url = `https://hrmsadmin.hola9.com/account/adminapiupdate/${companyData.id}/`;
        method = "PUT";
      }

      const response = await fetch(url, {
        method: method,
        body: formData
      });


      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      FetchCompanyData();
      setShowForm(false);

    } catch (error) {
      console.error("Error submitting holiday data:", error);
    }
  };

  const handleEditClick = (item) => {
    setEdit(true);
    // Set companyData with existing data, except for logo and images
    const { logo, images, ...rest } = item;
    setCompanyData({ ...rest });
    setShowForm(true);
  };


  return (
    <>
      <div>
        <div>
          <button type='submit' onClick={toggleForm} style={{ padding: "none", border: "node", borderRadius: "5px", background: "none", color: "#fff" }}>AddData</button>
          {isLoading1 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                // src="https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif"
                src="https://www.icegif.com/wp-content/uploads/2023/07/icegif-1262.gif"
                alt="---loading"
                height={250}
                width={850}

                style={{ marginTop: "70px" }}
              /></div>

          ) : (
            <>

              <div className='profile-card-main-div'>
                {company.map((item) => (
                  <div key={item.id} style={{ minWidth: '330px', maxHeight: '300px', boxShadow: '0px 2px 5px', flexGrow: '0.1', borderRadius: '5px', cursor: 'pointer', margin: '20px' }} onClick={() => handleEditClick(item)}>

                    <div style={{ width: '100%', height: '30%', background: storedColor, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', borderRadius: '5px 5px 0 0', color: 'whitesmoke' }}>
                      <div>
                        <h5 style={{ color: 'wheat' }}>{item.organization}</h5>
                        <p>{item.email}</p>
                        <p>Domain : {item.domain}</p>
                      </div>
                      <div>
                        <img src={item.logo} alt='logo' style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                      </div>
                    </div>
                    <div style={{ height: '5%', background: 'rgba(0, 0, 0, 0.5)', color: 'whitesmoke', padding: '0 5px' }}>
                      {item.tagline}
                    </div>

                    <div style={{ width: '100%', height: '55%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
                      <div style={{ fontSize: '90%' }}>
                        <h6>Company</h6>
                        <p>Type : {item.company_type}</p>
                        <p>Since : {item.company_since}</p>
                        <p>Employees : {item.no_of_employees}</p>
                        <i><span>Address : </span>{item.company_address}</i>
                      </div>
                      <div style={{ fontSize: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={item.images && item.images.length > 0 && (item.images[0].image)} alt='image' style={{ width: '100px', height: '60px', borderRadius: '5px' }} />
                        <div>
                          <p>Name : {item.name}</p>
                          <p>User ID : {item.user_id}</p>
                          <p>Password : {item.password}</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: '10%', background: 'whitesmoke', boxShadow: '0px -1px 2px lightgray', padding: '5px', borderRadius: '10px' }}>
                      {item.about}
                    </div>
                  </div>
                ))}
              </div>






              {/* ------------------------------------------------------------------------------------------------------------------------------------- */}


              <Modal
                title={edit ? "EditData" : "AddData"}
                open={showForm}
                onCancel={toggleForm}
                footer={null}
              >
                <form onSubmit={handleSubmit}>
                  <label>Name:</label>
                  <input type="text" name="name" value={companyData.name} onChange={handleChange} />

                  <label>Organization:</label>
                  <input type="text" name="organization" value={companyData.organization} onChange={handleChange} />

                  <label>Logo URL:</label>
                  {companyData.logo && <img src={companyData.logo} alt="Logo" style={{ maxWidth: "100px", maxHeight: "100px" }} />}
                  <input type="file" name="logo" accept="image/*" onChange={handleChange} />

                  <label>Image:</label>
                  <input type="file" name="images" accept="image/*" onChange={handleChange} />


                  <label>Company Type:</label>
                  <input type="text" name="company_type" value={companyData.company_type} onChange={handleChange} />

                  <label>Company Since:</label>
                  <input type="text" name="company_since" value={companyData.company_since} onChange={handleChange} />

                  <label>Color:</label>
                  <input type="text" name="color" value={companyData.color} onChange={handleChange} />

                  <label>Company Address:</label>
                  <input type="text" name="company_address" value={companyData.company_address} onChange={handleChange} />

                  <label>About:</label>
                  <textarea name="about" value={companyData.about} onChange={handleChange}></textarea>

                  <label>Facebook:</label>
                  <input type="text" name="facebook" value={companyData.social_links.facebook} onChange={handleChange} />

                  <label>Twitter:</label>
                  <input type="text" name="twitter" value={companyData.social_links.twitter} onChange={handleChange} />

                  <label>Instagram:</label>
                  <input type="text" name="instagram" value={companyData.social_links.instagram} onChange={handleChange} />

                  <label>LinkedIn:</label>
                  <input type="text" name="linkedin" value={companyData.social_links.linkedin} onChange={handleChange} />


                  <label>Tagline:</label>
                  <input type="text" name="tagline" value={companyData.tagline} onChange={handleChange} />

                  <label>No. of Employees:</label>
                  <input type="number" name="no_of_employees" value={companyData.no_of_employees} onChange={handleChange} />

                  <label>Domain:</label>
                  <input type="text" name="domain" value={companyData.domain} onChange={handleChange} />

                  <label>User ID:</label>
                  <input type="text" name="user_id" value={companyData.user_id} onChange={handleChange} />

                  <label>Email:</label>
                  <input type="text" name="email" value={companyData.email} onChange={handleChange} />

                  <label>Password:</label>
                  <input type="password" name="password" value={companyData.password} onChange={handleChange} />

                  <button type="submit"
                    style={{
                      background: "rgb(8,33,68)", // Add your desired background color here
                      color: "#fff",
                      borderRadius: "8px", // Add your desired border radius here
                      padding: "8px 16px", // Add padding for better visual appearance
                      border: "none", // Remove default button border
                      cursor: "pointer" // Change cursor to pointer on hover
                    }}
                  >
                    {edit ? "UpdateData" : "AddData"}
                  </button>
                </form>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;