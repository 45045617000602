import React, { useEffect, useState } from 'react';
import { Button, Input, Checkbox } from 'antd';
import axios from 'axios';
import AddUserDataTable from './AddUserDataTable';
import './AddUserDataTable.css';

const AddUser = () => {
  const [refreshData, setRefreshData] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    username: '',
    password: '',
    tl: false,
    testing: false,
    testing_tl: false,
    backend: false,
    backend_tl: false,
    frontend: false,
    frontend_tl: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, type, checked } = e.target;

    if (type === 'checkbox' && checked) {
      const updatedFormData = { ...formData };
      Object.keys(updatedFormData).forEach((key) => {
        if (key !== name && typeof updatedFormData[key] === 'boolean') {
          updatedFormData[key] = false;
        }
      });
      setFormData({ ...updatedFormData, [name]: checked });
    } else {
      const value = type === 'checkbox' ? checked : e.target.value;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const checkIfUserExists = async (name, username) => {
    try {
      const response = await fetch(
        `https://hrmsadmin.hola9.com/account/adduser/?name=${name}&username=${username}`
      );
      const data = await response.json();
      console.log("API Data:", data); 

      const userExists = data.some(
        (user) => user.name === name || user.username === username
      );
      return userExists;
    } catch (error) {
      console.error('Error checking user:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!formData.name.trim()) errors.name = '*Name is required';
    if (!formData.organization.trim()) errors.organization = '*Organization is required';
    if (!formData.username.trim()) errors.username = '*Username is required';
    if (!formData.password.trim()) errors.password = '*Password is required';

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const userExists = await checkIfUserExists(formData.name, formData.username);
    if (userExists) {
      alert('User with the same name or username already exists!');
      return;
    }

    try {
      const response = await fetch('https://hrmsadmin.hola9.com/account/adduser/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('User added successfully!');
        setFormData({
          name: '',
          organization: '',
          username: '',
          password: '',
          tl: false,
          testing: false,
          testing_tl: false,
          backend: false,
          backend_tl: false,
          frontend: false,
          frontend_tl: false,
        });
        setErrors({});
        setRefreshData((prev) => !prev);
      } else {
        console.error('Error submitting data:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const storedColor = localStorage.getItem('selectedColor');

  return (
    <>
      <div
        style={{
          width: '30%',
          margin: 'auto',
          background: '#fff',
          border: '1px solid #ccc',
          padding: '30px 20px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
        }}
      >
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" />
            {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}
          </label>
          <label>
            Organisation:
            <input type="text" name="organization" value={formData.organization} onChange={handleChange} placeholder="Organization" />
            {errors.organization && <div style={{ color: 'red' }}>{errors.organization}</div>}
          </label>
          <label>
            Username:
            <input type="text" name="username" value={formData.username} onChange={handleChange} placeholder="Username" />
            {errors.username && <div style={{ color: 'red' }}>{errors.username}</div>}
          </label>
          <label>
            Password:
            <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" />
            {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
          </label>

          {/* Role Selection */}
          <label className="adduser-TL-LA">
            TL:
            <div className="adduserCheckbox-TL">
              <input type="checkbox" name="tl" checked={formData.tl} onChange={handleChange} />
            </div>
          </label>
          <label className="adduser-TS-LA">
            Testing:
            <div className="adduserCheckbox-TS">
              <input type="checkbox" name="testing" checked={formData.testing} onChange={handleChange} />
            </div>
          </label>
          <label className="adduser-TST-LA">
            Testing TL:
            <div className="adduserCheckbox-TST">
              <input type="checkbox" name="testing_tl" checked={formData.testing_tl} onChange={handleChange} />
            </div>
          </label>
          <label className="adduser-BD-LA">
            Backend:
            <div className="adduserCheckbox-BD">
              <input type="checkbox" name="backend" checked={formData.backend} onChange={handleChange} />
            </div>
          </label>
          <label className="adduser-BDT-LA">
            Backend TL:
            <div className="adduserCheckbox-BDT">
              <input type="checkbox" name="backend_tl" checked={formData.backend_tl} onChange={handleChange} />
            </div>
          </label>

          {/* New Frontend Checkboxes */}
          <label className="adduser-TST-LA">
            Frontend:
            <div className="adduserCheckbox-BDT">
              <input type="checkbox" name="frontend" checked={formData.frontend} onChange={handleChange} />
            </div>
          </label>
          <label className="adduser-TST-LA">
            Frontend TL:
            <div className="adduserCheckbox-BDT">
              <input type="checkbox" name="frontend_tl" checked={formData.frontend_tl} onChange={handleChange} />
            </div>
          </label>

          <button className="adduserSubmit-sty" type="submit" style={{ background: storedColor }}>
            Submit
          </button>
        </form>
      </div>

      <br />
      <br />
      <div>
        <AddUserDataTable refreshData={refreshData} setRefreshData={setRefreshData} />
      </div>
    </>
  );
};

export default AddUser;
