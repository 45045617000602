// Import necessary dependencies
import React, { useState, useEffect } from "react";
import { BiLocationPlus } from "react-icons/bi";
import MapLocationAds from "./MapLocation";
import MapLocationAds1 from "./Maplocation1";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactPaginate from "react-paginate"; // Import pagination component
import EmployeeApprove from "./EmployeeApprove";

const EmployeeTable = (props) => {
  const name = props.name;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state added
  const [itemsToLoad, setItemsToLoad] = useState(20);
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
  const [isLoading1, setIsLoading1] = useState(true);

  const [filters, setFilters] = useState({
    name: "",
    date: "",
  });
  const itemsPerPage = 42; // Number of items to display per page

  const [pageNumber, setPageNumber] = useState(0);

  const storedColor = localStorage.getItem("selectedColor");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://hrmsadmin.hola9.com/account/employeelogin2?limit=${itemsToLoad}&name=${name}`
        );
        const apiData = await response.json();
        setData(apiData);
        setFilteredData(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading to false when data is fetched or an error occurs
      }
    };

    fetchData(); // Call the async function
  }, [itemsToLoad, name]); // Include itemsToLoad and name in the dependency array
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 20);
    setLimitIncreaseCount((prevCount) => prevCount + 1); // Increase the number of items to load
    // Increase the number of items to load
  };
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading && limitIncreaseCount != 3) {
        const newDataLength = data.length;

        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, data, prevDataLength, limitIncreaseCount]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);

    let filtered = data.filter((employee) => {
      const nameMatch = employee.name
        .toLowerCase()
        .includes(updatedFilters.name.toLowerCase());
      const dateMatch =
        updatedFilters.date === "" ||
        new Date(employee.date).toLocaleDateString() ===
        new Date(updatedFilters.date).toLocaleDateString();
      return nameMatch && dateMatch;
    });

    setFilteredData(filtered);
  };
  const dataall = filteredData.filter((user) => user.name === name);
  const pageCount = Math.ceil(dataall.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    setLimitIncreaseCount(0);
  };

  const displayedData = dataall.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const [showPopup, setShowPopup] = useState(false);
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    // Fetch data from your API
    fetch(`https://hrmsadmin.hola9.com/account/loginprofile/?name=${name}`)
      .then((response) => response.json())
      .then((data) => {

        setUserData(data);
        setIsLoading1(false);
      });
  }, []);
  const convertMinutesToHours = (minutes) => {
    const totalMinutes = parseInt(minutes, 10); // Ensure total_minutes is a valid number
    // if (isNaN(totalMinutes)) {
    //   return (
    //     <span
    //       style={{
    //         cursor: "pointer",
    //         padding: "5px 10px",
    //         background: storedColor,
    //         color: "white",
    //         borderRadius: "5px",
    //       }}
    //       onClick={() => setShowPopup(true)}
    //     >
    //       Approve from HR
    //     </span>
    //   );
    // }

    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  // const tlNames = data.filter((item) => item.tl).map((item) => item.name);

  if (isLoading) {
    return (
      <center className="loading-indicator" style={{ padding: "50px" }}>
        <img
          src="https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif"
          alt="loading..."
        />
      </center>
    ); // Display loading state while data is being fetched
  }
  return (
    <div>
      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={filters.name} onChange={handleFilterChange} />
        </div>
        <div>
          <label>Date:</label>
          <input type="date" name="date" value={filters.date} onChange={handleFilterChange} />
        </div>

      </div> */}
      <table className="employee-table" id="employeeTable">
        <thead>
          <tr>
            <th style={{ background: storedColor }}>ID</th>
            {/* <th>Name</th> */}
            <th style={{ background: storedColor, margin: "0" }}>
              {/* Date */}
              <input
                type="date"
                name="date"
                value={filters.date}
                onChange={handleFilterChange}
                style={{ width: "100%" }}
              />
            </th>
            <th style={{ background: storedColor }}>Check-in</th>
            <th style={{ background: storedColor }}>Lunch Break</th>
            {/* <th>Check-in Location</th> */}
            <th style={{ background: storedColor }}>Check-Out</th>
            {/* <th>Check-Out Location</th> */}
            <th style={{ background: storedColor }}>Total Time</th>
          </tr>
        </thead>
        <tbody>
          {displayedData
            .sort((a, b) => b.id - a.id)
            .map((employee) => (
              <tr key={employee.id}>
                <td>{employee.id}</td>
                {/* <td>{employee.name}</td> */}
                <td>{employee.date}</td>
                <td>
                  {employee.login_time}
                  {/* </td>
              <td> */}
                  {employee.lattiude1 && employee.longitude1 ? (
                    <>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${employee.lattiude1},${employee.longitude1}`}
                        target="_blank"
                        style={{
                          fontSize: "80%",
                          padding: "3px 10px",
                          marginLeft: "3%",
                          boxShadow: "0px 0.5px 3px gray inset",
                          background: storedColor,
                          backgroundClip: "text",
                          color: "transparent",
                        }}
                      >
                        See On Map
                      </a>
                      <MapLocationAds
                        id={employee.id}
                        name={name}
                        props={{
                          lati: employee.lattiude1,
                          long: employee.longitude1,
                        }}
                      />
                    </>
                  ) : (
                    <>No location found</>
                  )}
                </td>
                <td>
                  {employee.lunch_break_checkin && (
                    <pre>{`lunch from : ${employee.lunch_break_checkin}`}</pre>
                  )}
                  {employee.lunch_break_checkout && (
                    <pre>{`lunch to   : ${employee.lunch_break_checkout}`}</pre>
                  )}
                </td>
                <td>
                  {employee.logout_time}
                  {/* </td>
              <td> */}
                  {employee.lattiude2 && employee.longitude2 ? (
                    <>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${employee.lattiude2},${employee.longitude2}`}
                        target="_blank"
                        style={{
                          fontSize: "80%",
                          padding: "3px 10px",
                          marginLeft: "3%",
                          boxShadow: "0px 0.5px 3px gray inset",
                          background: storedColor,
                          backgroundClip: "text",
                          color: "transparent",
                        }}
                      >
                        See On Map
                      </a>
                      <MapLocationAds1
                        id1={employee.id}
                        props1={{
                          lati1: employee.lattiude2,
                          long1: employee.longitude2,
                        }}
                      />
                    </>
                  ) : (
                    <>No location found</>
                  )}
                </td>
                {employee.approved === true ? (
                  <td>{convertMinutesToHours(employee.total_time)}</td>
                ) : (
                  <>
                    <span
                      style={{
                        cursor: "pointer",
                        padding: "5px 10px",
                        background: storedColor,
                        color: "white",
                        borderRadius: "5px",
                      }}
                      onClick={() => setShowPopup(true)}
                    >
                      Approve from HR
                    </span>
                    {/* Debugging */}

                  </>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {showPopup && (
        <>
          {data.map((is) => (
            <div className="popup">
              <EmployeeApprove close={() => setShowPopup(false)} id={is.userid} />
            </div>
          ))}
        </>
      )}
      {filteredData.length > itemsPerPage && (
        <ReactPaginate
          previousLabel={"Previous"}
          className="paginat"
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination-container"}
          pageClassName={"pagination-page"}
          pageLinkClassName={"pagination-link"}
          previousClassName={"pagination-previous"}
          nextClassName={"pagination-next"}
          disabledClassName={"pagination-disabled"}
          activeClassName={"pagination-active"}
        />
      )}
    </div>
  );
};

export default EmployeeTable;
