import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { TbDropletFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import LoginDetails from "../../../login/LoginDetails";

const ProfileDashCard = (props) => {
  const name = props.name;
  const email = props.email;
  const domain = props.domain;
  const AddProfile = props.AddProfile;

  const [permissionAsked, setPermissionAsked] = useState(false);

  const askForPermission = () => {
    const storedPermission = localStorage.getItem("geolocationPermission");
    if (storedPermission === "granted") {
      handleLocationClick();
    } else {
      const userPermission = window.confirm(
        "Allow this website to access your location?"
      );
      if (userPermission) {
        localStorage.setItem("geolocationPermission", "granted");
        handleLocationClick();
      } else {
        console.log("User denied geolocation permission.");
        setPermissionAsked(true);
      }
    }
  };

  const handleLocationClick = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDEnLDetZt4PvT03AWs7ZTyzAdA3K-Sz1g`
      );

      const data = await response.json();

      // Extract city name from the response
      const city = data.results[0].address_components.find(
        (component) => component.types.includes("locality")
      );

      // Store city name in local storage
      localStorage.setItem("selectedCity", city ? city.long_name : "Unknown");

    } catch (error) {
      console.log("Error: Unable to fetch location", error);
    }
  };

  useEffect(() => {
    const storedPermission = localStorage.getItem("geolocationPermission");
    if (storedPermission === "granted") {
      handleLocationClick();
    } else {
      askForPermission();
    }
  }, []);
  const [isLoading1, setIsLoading1] = useState(true);
  const storedCity = localStorage.getItem("selectedCity");
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    // Fetch data from your API
    fetch(`https://hrmsadmin.hola9.com/account/loginprofile/?name=${name}`)
      .then((response) => response.json())
      .then((data) => {

        setUserData(data);
        setIsLoading1(false);
      });
  }, []);
  const getImageMIMEType = (imageData) => {
    // Return a default MIME type if imageData is undefined or null
    if (!imageData) {
      return "image/jpeg"; // Default to JPG
    }

    // Detect the MIME type based on the image data
    if (imageData.startsWith("data:image/png")) {
      return "image/png";
    } else if (
      imageData.startsWith("data:image/jpeg") ||
      imageData.startsWith("data:image/jpg")
    ) {
      return "image/jpeg";
    } else if (imageData.startsWith("data:image/gif")) {
      return "image/gif";
    }

    // Default to JPG if the format is unknown
    return "image/jpeg";
  };
  // const [userData, setUserData] = useState([]);

  // const data = JSON.parse(localStorage.getItem('userData'))

  // console.log(data);
  const loginttime = localStorage.getItem("lastlogintime");


  const isLoggedIn = localStorage.getItem("isLoggedIn");

  return (
    <>
      {isLoading1 ? (
        <>
          <img
            src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
            alt="---loading"
            height={150}
            width={150}
          />{" "}
        </>
      ) : (
        <>
          {userData.find((user) => user.name === name) ? (
            userData.map(
              (user, index) =>
                user.name === name && (
                  <div key={`${index}`} style={{ display: "flex", padding: "10px" }}>
                    <div style={{ width: "200px", padding: "10px" }}>
                      <img
                        src={`${user.image}`}
                        style={{
                          borderRadius: "50%",
                          height: "170px",
                          width: "170px",
                          boxShadow: '0px 1px 5px gray'

                        }}
                      />
                    </div>

                    <div
                      style={{
                        padding: "20px 20px 0",
                        width: "80%",
                        background: "white",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          borderBottom: "0.5px solid gray",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {/* User Name */}
                          <span>
                            <h3>{user.name}</h3>
                          </span>
                          <i
                            style={{
                              marginLeft: "0.5rem",
                              color: "white",
                              backgroundColor: isLoggedIn ? "Green" : "Darkred",
                              borderRadius: "15px",
                              padding: "1px 7px",
                              fontSize: '75%',
                              marginBottom: '9px'
                            }}
                          >
                            {isLoggedIn ? "Check-In" : "Check-Out"}
                          </i>
                        </span>
                        {/* <hr style={{ height: "5px" }}></hr> */}

                        <p style={{ color: "navy", fontSize: '75%' }}>
                          Last Login:
                          <span style={{ color: "red" }}>{loginttime}</span>
                        </p>
                        <hr style={{ height: "10px" }}></hr>
                        <span
                          className="prof-icon-top"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "80%",
                            paddingLeft: "15px",
                          }}
                        >
                          <p style={{ color: "navy" }}>
                            <i
                              className="fas fa-map-marker-alt"
                              style={{ color: "red" }}
                            ></i>
                            {storedCity}
                          </p>
                          <p>
                            <i
                              className="fas fa-phone-alt"
                              style={{ color: "green" }}
                            ></i>
                            +91 {user.phone_number}
                          </p>
                          <p>
                            <i
                              className="fas fa-calendar-alt"
                              style={{ color: "navy" }}
                            ></i>
                            {user.dob}
                          </p>
                          <p>
                            <TbDropletFilled style={{ color: "red" }} />{" "}
                            {user.blood_group}
                          </p>
                        </span>
                      </div>
                      <hr style={{ height: "20px" }}></hr>
                      <div>
                        <table
                          style={{
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          <thead style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                            <tr>
                              <th>DESIGNATION</th>
                              <th>OUT LOOK</th>
                              <th>REPORTING TO</th>
                              <th>EMP ID</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/* <td>{data.domain}</td> */}
                              <td>{user.role}</td>
                              <td>{user.email}</td>
                              <td>{user.reporting_to}</td>
                              <td>{user.userid}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                        marginRight: "15px",
                        marginTop: "-20px",
                      }}
                    ></div>
                  </div>
                )
            )
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="card-body" style={{ textAlign: "center" }}>
                <Button onClick={AddProfile}>Add Profile</Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProfileDashCard;
