import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from 'react-sidebar';
import Navbar from '../../section/navbar/Navbar';


// const { TextArea } = Input;
import "./Sendmail.css"
const MailForm = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMails, setSelectedMails] = useState([]);
  const [ccMails, setCcMails] = useState([]);
  const [bccMails, setBccMails] = useState([]);
  const [subject, setSubject] = useState('');
  const [mailDescription, setMailDescription] = useState('');
  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData(apiData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const ccMailsArray = [
    'gopikrishna@hola9.com',
    'ramya.hola9@outlook.com',
    'akshathaml.nayak@outlook.com',
    'sarang.giri@outlook.com',
  ];

  const bccMailsArray = [
    'gopikrishna@hola9.com',
    'sarang.giri@outlook.com',
  ];

  const handleToggle = (mail) => {
    const index = selectedMails.indexOf(mail);
    if (index === -1) {
      setSelectedMails([...selectedMails, mail]);
    } else {
      setSelectedMails([...selectedMails.slice(0, index), ...selectedMails.slice(index + 1)]);
    }
  };
  const handleSelectMailTo = () => {
    setBccMails((prevBccMails) => [...prevBccMails, ...selectedMails]);
    setSelectedMails([]);
  };
  const handleSelectAll = () => {
    setSelectedMails(data.map((item) => item.username));
  };

  const handleSelectCc = () => {
    setCcMails([...ccMails, ...ccMailsArray]);
    setSelectedMails([]);
  };

  const handleSelectBcc = () => {
    setBccMails([...bccMails, ...bccMailsArray]);
    setSelectedMails([]);
  };

  const handleRemoveCc = (mail) => {
    setCcMails(ccMails.filter((ccMail) => ccMail !== mail));
  };

  const handleRemoveBcc = (mail) => {
    setBccMails(bccMails.filter((bccMail) => bccMail !== mail));
  };
  const handleRemoveAllBcc = () => {
    setBccMails([]);
    setSelectedMails([]);
  };

  const handleSubmit = () => {
    openMailPage({
      to: bccMails,
      cc: ccMails,
      bcc: selectedMails,
      subject: subject,
      mailDescription: mailDescription,
    });
  };

  const openMailPage = ({ to, cc, bcc, subject, mailDescription }) => {
    const mailtoUrl = `mailto:${to.join(',')}?cc=${cc.join(',')}&bcc=${bcc.join(',')}&subject=${subject}&body=${mailDescription}`;
    window.location.href = mailtoUrl;
  };

  return (
    <>
      <Navbar />
      <div className="container mt-4">
        <div className="row21 row-cols-1 row-cols-md-4" style={{ marginTop: "90px", border: "1px solid navy", borderRadius: "20px", height: '300px', overflowY: 'scroll' }}>
          {loading ? (
            <img src="https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif" alt="loading..." />
          ) : (
            data.map((item) => (
              <div key={item.username} className="col mb-4">
                <div className={`card ${selectedMails.includes(item.username) ? 'selected' : ''}`} onClick={() => handleToggle(item.username)}>
                  <div className="card-body">
                    <span className="mail-icon" style={{ textAlign: "center", fontSize: "160%" }}>✉️</span>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectedMails.includes(item.username)}
                        onChange={() => handleToggle(item.username)}
                      />
                      <label className="form-check-label" style={{ fontSize: "80%" }}>
                        {item.username}
                      </label>
                      <br />

                    </div>
                    <label className="form-check-label ms-2">{item.name}</label>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="mt-3">
          <div className="mb-3">
            <label htmlFor="subject" className="form-label">
              Subject:
            </label>
            <input
              type="text"
              className="form-control"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="mailDescription" className="form-label">
              Mail Description:
            </label>
            <textarea style={{ width: "100%", height: "auto" }}
              className="form-control"
              id="mailDescription"
              rows="3"
              value={mailDescription}
              onChange={(e) => setMailDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-2">
            {ccMails.length > 0 && (
              <>
                <span className="badge bg-info me-2">CC:</span>
                {ccMails.map((ccMail) => (
                  <span key={ccMail} className="badge bg-dark me-2" style={{ backgroundColor: "purple", color: "white", padding: "5px" }}>
                    {ccMail}
                    <button
                      type="button" style={{ color: "red" }}
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => handleRemoveCc(ccMail)}
                    >X</button>
                  </span>
                ))}
              </>
            )}
            <br />
            <br />
            {bccMails.length > 0 && (
              <>
                <span className="badge bg-warning me-2">Mail to:</span>
                {bccMails.map((bccMail) => (
                  <span key={bccMail} className="badge bg-dark me-2" style={{ backgroundColor: "purple", color: "white", padding: "5px" }}>
                    {bccMail}
                    <button
                      type="button"
                      className="btn-close" style={{ color: "red" }}
                      aria-label="Close"
                      onClick={() => handleRemoveBcc(bccMail)}
                    >X</button>
                  </span>
                ))}
                <button type="button" className="btn btn-danger" onClick={handleRemoveAllBcc}>
                  Remove All
                </button>
              </>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <button type="button" className="btn btn-primary me-2" onClick={handleSelectAll}>
              Select All as Bcc
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleSelectMailTo}>
              Select All as Mail to
            </button>
            <button type="button" className="btn btn-info me-2" onClick={handleSelectCc}>
              Select as CC
            </button>
            <button type="button" className="btn btn-warning" onClick={handleSelectBcc}>
              Mail to
            </button>
          </div>
        </div>
        <br></br>
        <div className="mt-3">
          <button type="button" className="btn btn-success" onClick={handleSubmit}>
            Send mail <span className="mail-icon">✉️</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default MailForm;
