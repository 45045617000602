// EmployeeEditForm.js
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useNavigate } from 'react-router-dom';
import Navbar from '../../section/navbar/Navbar';
import axios from 'axios';

const EmployeeEditForm = () => {
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState({
    employeeName: '',
    employeeId: '',
    totalwork: '',
    leaves: '',
    designation: '',
    dateOfJoining: '',
    month: '',
    year: '',
    basicDA: '',
    providentFund: '',
    hra: '',
    esi: '',
    conveyance: '',
    loan: '',
    professionTax: '',
    lop: '',
    totalAddition: '',
    totalDeduction: '',
    netSalary: '',
    bank_name: '',  // Ensure bank_name is included in the initial state
    ifsc: '',
    acc_num: '',
    branch_name: ''
  });
  const history = useNavigate();
  const [originalEmployee, setOriginalEmployee] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setLoading1(true);
    // Fetch data for the specific employee from the API
    fetch(`https://hrmsadmin.hola9.com/account/employees_joining/${employeeId}/`)
      .then(response => response.json())
      .then(data => {
        setEmployee(data);
        setOriginalEmployee(data);
        setLoading1(false);// Update originalEmployee with the latest data
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [employeeId]);

  const [editSuccess, setEditSuccess] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  const handleSaveClick = () => {
    setLoading(true);
    // Update the employee data on the server
    fetch(`https://hrmsadmin.hola9.com/account/employees_joining/${employeeId}/`, {
      method: 'PUT', // or 'PATCH' depending on your API
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(employee),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to save data');
        }
        return response.json();
      })
      .then(data => {
        console.log('Employee data saved successfully:', data);
        setEditSuccess(true);
        setLoading(false);
        window.alert(`${employee.employeeName}'s Salaryslip Edited successfully.`);
        // Redirect to the employee list page or any other desired page
        history('/allslips');
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error('Error sending data:', error.message);
        setCreateSuccess(false);
        window.alert('Employee slip Edit failed. ');
        setLoading(false);

      })


  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'dateOfJoining') {
      // Handle date input separately
      setEmployee({ ...employee, [name]: value });
    } else {
      // Allow both alphabetic and numeric characters for basicDA, hra, and conveyance
      const isBasicDAHraConveyance = /^[A-Za-z0-9 ]+$/.test(value);

      if (isBasicDAHraConveyance || value === '') {
        // Update state only if the input is alphabetic, numeric, or empty
        setEmployee({ ...employee, [name]: value });
      }
    }
  };


  const handlePrint = async () => {
    try {
      // Set loading state to true
      setLoading(true);

      // Make an HTTP POST request to your API endpoint
      const response = await axios.post('https://hrmsadmin.hola9.com/account/employees_joining/', employee);

      // Set success state to true
      setCreateSuccess(true);
      window.alert(`${employee.employeeName}'s New Slip generated successfully.`);
      // Redirect to the employee list page or any other desired page
      history('/allslips');
      // Log the response from the server (for debugging purposes)
      console.log('Server Response:', response.data);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error sending data:', error.message);
      setCreateSuccess(false);
      window.alert(`${employee.employeeName}'s New Slip generated failed.`);

    } finally {
      // Set loading state back to false, whether the request was successful or not
      setLoading(false);
    }
  };

  useEffect(() => {
    const basicDA = parseFloat(employee.basicDA) || 0;
    const hra = parseFloat(employee.hra) || 0;
    const conveyance = parseFloat(employee.conveyance) || 0;

    const totalAddition = basicDA + hra + conveyance;
    setEmployee({ ...employee, totalAddition: totalAddition.toFixed(2) });
  }, [employee.basicDA, employee.hra, employee.conveyance]);
  useEffect(() => {
    const professionTax = parseFloat(employee.professionTax) || 0;
    const lop = parseFloat(employee.lop) || 0;
    const providentFund = parseFloat(employee.providentFund) || 0;
    const esi = parseFloat(employee.esi) || 0;
    const loan = parseFloat(employee.loan) || 0;

    const totalDeduction = professionTax + lop + providentFund + esi + loan;
    setEmployee({ ...employee, totalDeduction: totalDeduction.toFixed(2) });
  }, [employee.professionTax, employee.lop, employee.providentFund, employee.esi, employee.loan]);
  useEffect(() => {
    const totalAddition = parseFloat(employee.totalAddition) || 0;
    const totalDeduction = parseFloat(employee.totalDeduction) || 0;

    const netSalary = totalAddition - totalDeduction;
    setEmployee({ ...employee, netSalary: netSalary.toFixed(2) });
  }, [employee.totalAddition, employee.totalDeduction]);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const names = data.map((item) => item.name);
  const bankNames = [
    'State Bank of India', 'HDFC Bank', 'ICICI Bank', 'Axis Bank',
    'Kotak Mahindra Bank', 'Bank of Baroda', 'Punjab National Bank', 'Canara Bank',
    'Union Bank of India', 'Bank of India', 'IDBI Bank', 'IndusInd Bank',
    'Yes Bank', 'Federal Bank', 'Karur Vysya Bank', 'South Indian Bank',
    'RBL Bank', 'Standard Chartered Bank', 'Citibank', 'HSBC'
  ];
  //navigation code start for adimin login
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInadmin = localStorage.getItem("loggedInadmin");

    if (!loggedInadmin) {
      navigate("/AdminLogin");
    }
  }, []);
  //navigation code end for adimin login
  if (!employee) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      {loading1 ? (
        <div className="loading-overlay">
          <div className="loading-spinner" />
        </div>
      ) : (
        <div className="salary-slip" style={{ marginTop: "50px" }}>

          <h3>Edit Employee</h3>
          {editSuccess && <p>Edit successfully!</p>}
          {createSuccess && <p>Create successfully!</p>}
          <div>
            <div className="form-row">

              <label>
                Employee Name:
                <select
                  name="employeeName"
                  value={employee.employeeName}
                  onChange={(e) => setEmployee({ ...employee, employeeName: e.target.value, handleInputChange })} style={{ borderRadius: '10px' }}
                >
                  <option value="">Select Name</option>
                  {names.map((employeeName, index) => (
                    <option key={index} value={employeeName}>
                      {employeeName}
                    </option>
                  ))}
                </select>
              </label>
              <label>Employee ID:
                <input type="text" value={employee.employeeId} onChange={(e) => setEmployee({ ...employee, employeeId: e.target.value, handleInputChange })} />
              </label>
              {/* Add input fields for other properties */}
              <label>Designation
                <input type="text" value={employee.designation} onChange={(e) => setEmployee({ ...employee, designation: e.target.value, handleInputChange })} />
              </label>
            </div>
            <div className="form-row">
              <label> Total Working Days:
                <input type="text" value={employee.totalwork} onChange={(e) => setEmployee({ ...employee, totalwork: e.target.value, handleInputChange })} />
              </label>
              <label>Leaves
                <input type="text" value={employee.leaves} onChange={(e) => setEmployee({ ...employee, leaves: e.target.value, handleInputChange })} />
              </label>
            </div>
            <div className="form-row">
              <label>Date of Joining:
                <input type="text" value={employee.dateOfJoining} onChange={(e) => setEmployee({ ...employee, dateOfJoining: e.target.value, handleInputChange })} />
              </label>
              <label>Month
                <input type="text" value={employee.month} onChange={(e) => setEmployee({ ...employee, month: e.target.value, handleInputChange })} />
              </label>
              <label>Year

                <input type="text" value={employee.year} onChange={(e) => setEmployee({ ...employee, year: e.target.value, handleInputChange })} />
              </label>
            </div>
            <div className="form-row">
              <label>BankName
                <select
                  name="bank_name"
                  value={employee.bank_name}
                  onChange={(e) => setEmployee({ ...employee, bank_name: e.target.value, handleInputChange })} style={{ borderRadius: '10px' }}
                >
                  <option value="">Select Month</option>
                  {bankNames.map((employeeName, index) => (
                    <option key={index} value={employeeName}>
                      {employeeName}
                    </option>
                  ))}
                </select>    </label>

              <label>Branch name
                <input type="text" value={employee.branch_name} onChange={(e) => setEmployee({ ...employee, branch_name: e.target.value, handleInputChange })} />
              </label>

            </div>
            <div className="form-row">
              <label>Bank_acc
                <input type="text" value={employee.acc_num} onChange={(e) => setEmployee({ ...employee, acc_num: e.target.value, handleInputChange })} />
              </label>
              <label>IFSC
                <input type="text" value={employee.ifsc} onChange={(e) => setEmployee({ ...employee, ifsc: e.target.value, handleInputChange })} />
              </label>

            </div>

            <h3>Earnings</h3>
            <div className="form-row">
              <label>Basic & DA:
                <input type="text" value={employee.basicDA} onChange={(e) => setEmployee({ ...employee, basicDA: e.target.value, handleInputChange })} />
              </label>
              <label>Conveyance
                <input type="text" value={employee.conveyance} onChange={(e) => setEmployee({ ...employee, conveyance: e.target.value, handleInputChange })} />
              </label>
            </div>
            <div className="form-row">
              <label>Hra
                <input type="text" value={employee.hra} onChange={(e) => setEmployee({ ...employee, hra: e.target.value, handleInputChange })} />
              </label>
              <label>TotalAddition
                <input type="text" value={employee.totalAddition} onChange={(e) => setEmployee({ ...employee, totalAddition: e.target.value, handleInputChange })} />
              </label>
            </div>
            <h3>Deductions</h3>
            <div className="form-row">
              <label>ProfessionTax
                <input type="text" value={employee.professionTax} onChange={(e) => setEmployee({ ...employee, professionTax: e.target.value, handleInputChange })} />
              </label>
              <label>Lop
                <input type="text" value={employee.lop} onChange={(e) => setEmployee({ ...employee, lop: e.target.value, handleInputChange })} />
              </label>
              <label>ProvidentFund
                <input type="text" value={employee.providentFund} onChange={(e) => setEmployee({ ...employee, providentFund: e.target.value, handleInputChange })} />
              </label>
            </div>
            <div className="form-row">
              <label>Esi
                <input type="text" value={employee.esi} onChange={(e) => setEmployee({ ...employee, esi: e.target.value, handleInputChange })} />
              </label>
              <label>Loan
                <input type="text" value={employee.loan} onChange={(e) => setEmployee({ ...employee, loan: e.target.value, handleInputChange })} />
              </label>
              <label>TotalDeduction
                <input type="text" value={employee.totalDeduction} onChange={(e) => setEmployee({ ...employee, totalDeduction: e.target.value, handleInputChange })} />
              </label>
            </div>
            <label>NetSalary</label>
            <input type="text" value={employee.netSalary} onChange={(e) => setEmployee({ ...employee, netSalary: e.target.value, handleInputChange })} />


            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button onClick={handleSaveClick} className='print-button'>Save</button>

              <button onClick={handlePrint} className='print-button12'>Create New</button>
              {loading && <div className="loading-overlay">
                <img src="https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif" alt='processing' />
              </div>}
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeEditForm;
