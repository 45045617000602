import React, { useEffect, useState } from "react";
import "../Dashboard/ApplayLeavesForm.css";
import axios from "axios";

const ApplayLeavesForm = ({ close }) => {

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const [userData, setUserData] = useState([]);
  const name = loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null;



  const storedColor = localStorage.getItem('selectedColor');



  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData(apiData);
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const tlNames = data.filter((item) => item.tl || item.testing_tl || item.backend_tl).map((item) => item.name);




  // Set the posted_date to the current date
  const currentDate = new Date();
  const TodayDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;


  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState({

    subject: '',
    tlname: '',
    department: '',
    reason: '',
    leave_type: '',
    // leave_type : "sick_leave",

    from_date: '',
    to_date: '',
    description: '--',

    name: 56,
    posted_date: TodayDate
  })

  const handleInput = (e) => {
    const { name, value } = e.target;
    setPost({
      ...post,
      [name]: value,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {

      const response = await fetch('https://hrmsadmin.hola9.com/account/leaves/', {
        method: 'POST',
        body: JSON.stringify(post),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // const response = await axios.post('https://hrmsadmin.hola9.com/account/leaves/', post);

      if (response.ok) {
        alert('Apply Leave request sent to HR Department');
        setIsLoading(false);
        console.log("Leave From", post)
        close()
      } else {
        alert('Failed to Apply Leave');
        setIsLoading(false);
      }

    } catch (error) {
      console.error(error);
      alert('Failed to Apply Leave');
    }

    console.log("leave From", post)
  }

  return (
    <>
      {isLoading &&
        <div className='popup'>
          <center className="loading-indicator">
            <img
              src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
              alt="---loading"
              className="emp-loading"
            />
          </center>
        </div>}
      <div style={{ background: 'white', padding: '20px', borderRadius: '10px', width: '70%', height: 'auto', }}>
        <form className="leave-form" onSubmit={handleSubmit}>

          <h3 style={{ textAlign: 'center', background: storedColor, backgroundClip: 'text', color: 'transparent' }}>Apply Leave</h3>

          <div style={{ display: "flex", gap: '10px' }}>
            <div style={{ width: "50%" }}>
              <label>
                Subject:
                <input
                  style={{ width: "100%" }}
                  name="subject"
                  value={post.subject}
                  onChange={handleInput}
                />
              </label>
            </div>
            <div style={{ width: "50%" }}>
              <label>
                Department:
                <input
                  style={{ width: "100%" }}
                  type="text"
                  name="department"
                  value={post.department}
                  onChange={handleInput}
                />
              </label>
            </div>
          </div>

          <div style={{ display: "flex", gap: '10px' }}>
            <div style={{ width: "50%" }}>
              <label>
                Start Date:
                <input
                  style={{ width: "100%" }}
                  type="date"
                  name="from_date"
                  value={post.from_date}
                  onChange={handleInput}
                />
              </label>
            </div>
            <div style={{ width: "50%" }}>
              <label>
                End Date:
                <input
                  style={{ width: "100%" }}
                  type="date"
                  name="to_date"
                  value={post.to_date}
                  onChange={handleInput}
                />
              </label>
            </div>
          </div>


          <div style={{ display: "flex", gap: '10px' }}>
            <div style={{ width: "50%" }}>
              <label>
                Leave Type:
                <select
                  name="leave_type"
                  value={post.leave_type}
                  onChange={handleInput}
                >
                  <option value="">Select Option</option>
                  <option value="sick_leave">Sick Leave</option>
                  <option value="casual_leave">Casual Leave</option>
                  <option value="Annual_leave">Annual Leave</option>
                </select>
              </label>
            </div>
            <div style={{ width: "50%" }}>
              <label>
                Team Leader Name:
                {/* <input
                    style={{ width: "100%" }}
                    type="text"
                    name="tlname"
                    value={post.tlname}
                    onChange={handleInput}
                  /> */}

                <select name="tlname" onChange={handleInput} required>
                  <option>Select Team Leader</option>
                  {tlNames.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>

          <label>
            Reason for Leave:
            <textarea
              name="reason"
              value={post.reason}
              onChange={handleInput}
            />
          </label>

          <div style={{ display: "flex" }}>
          </div>

          <div className="leaveform-buttons" style={{ display: "flex" }}>
            <button onClick={close} style={{ backgroundColor: "red" }}>
              cancel
            </button>

            <button type="submit" style={{ background: storedColor }}>
              Submit
            </button>
          </div>
        </form>
        <p id="description" className="main-description" style={{ height: '0', }}>
          Hola9 Classifieds India Private Ltd.
        </p>
      </div>
    </>
  );
};

export default ApplayLeavesForm;
