import React, { useEffect, useState } from 'react';

import { FaMobileAlt } from 'react-icons/fa';
import './MobileErrorPage.css';
const DeviceCheckPage = () => {


 


return(
    <div className="mobile-error-container">
      <FaMobileAlt className="mobile-icon" />
      <h1 className='soryyclass'>Sorry!</h1>
      <p className='sorrypara'>We are not available on mobile devices.</p>
      <p  className='sorrypara'>Please switch to a desktop or tablet for the best experience.</p>
    </div>
)
};
export default DeviceCheckPage;
