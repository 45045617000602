// VoiceReader.js
import React, { useState } from 'react';
import { useSpeechSynthesis, useSpeechRecognition } from 'react-speech-kit';

const VoiceReader = () => {
  const successMessage = "Success! The operation was successful.";
  const failureMessage = "Failed! The operation encountered an error.";

  const { speak, cancel, speaking } = useSpeechSynthesis();
  const { listen, stop, listening, transcript } = useSpeechRecognition();
  const [isReading, setIsReading] = useState(false);

  const getIndianAccentFemaleVoice = () => {
    const voices = window.speechSynthesis.getVoices();

    // Specify the voice URI for an Indian English female voice
    const indianAccentFemaleVoiceURI = 'Google हिन्दी India Female';

    // Find the voice with the specified URI
    const indianAccentFemaleVoice = voices.find(voice => voice.voiceURI === indianAccentFemaleVoiceURI);

    // If the specified voice is not available, fall back to any female voice
    const femaleVoice = voices.find(voice => voice.name.includes('female'));

    return indianAccentFemaleVoice || femaleVoice || voices[0]; // Use default voice if no suitable voice is found
  };

  const handleReadClick = (text) => {
    if (speaking) {
      cancel();
    } else {
      speak({
        text,
        voice: getIndianAccentFemaleVoice(),
        onEnd: () => setIsReading(false),
      });
    }
    setIsReading(!isReading);
  };

  const handleListenClick = () => {
    if (listening) {
      stop();
    } else {
      listen();
    }
  };

  return (
    <div>
      <button onClick={() => handleReadClick(successMessage)}>
        Read Success
      </button>
      <button onClick={() => handleReadClick(failureMessage)}>
        Read Failure
      </button>
      <button onClick={handleListenClick}>
        {listening ? 'Stop Listening' : 'Start Listening'}
      </button>
      {isReading && <p>Reading...</p>}
      <input type="text" value={transcript}  />
    </div>
  );
};

export default VoiceReader;
