import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import './allemp.css'

const AllempSearch = () => {
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://hrmsadmin.hola9.com/account/loginprofile/");
        const apiData = await response.json();
        setData(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searchName.trim() !== "") {
      const filteredSuggestions = data
        .filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase()))
        .map((item) => item.name);
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [searchName, data]);

  const handleSelect = (name) => {
    navigate(`/empdash/${name}`);
    setSearchName("");
    setShowSuggestions(false);
  };



  const handleKeyDown = useCallback((e) => {
    if (e.key === "Enter" && suggestions.length > 0) {
      handleSelect(suggestions[0]);
    }
  },
    [suggestions, handleSelect]
  );

  return (
    <div>
      <input
        className="aes-input"
        type="text"
        placeholder="Search For Employess..."
        value={searchName}
        onChange={(e) => setSearchName(e.target.value)}
        onKeyDown={handleKeyDown}
        onFocus={() => setShowSuggestions(true)}
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className="aes-ul">
          {suggestions.map((item, index) => (
            <li key={index} onClick={() => handleSelect(item)}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AllempSearch;
