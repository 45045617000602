import React from 'react';
import { useParams } from 'react-router-dom';
import { TeamData } from './ProjectTeamData/TeamData';
import Navbar from '../../section/navbar/Navbar';

const ProjectTeamDetails = () => {
    const { id } = useParams();
    const product = TeamData.filter((item) => item.emptype === "Tl" && item.Project === id);
    const developers = TeamData.filter((item) => item.emptype === "dev" && item.Project === id);

    return (
        <>
            <Navbar />
            <br /><br /><br />
            <div className='mainwrap' >
                {/* project name code start */}
                {
                    developers.slice(0, 1).map((item, index) => (
                        <>
                            <h2 style={{ display: "flex", justifyContent: "start", marginLeft: "4%" }}><span style={{ color: "#e68a00", marginRight: "8px" }}>OurProject</span>  {item.Project}</h2>
                        </>
                    ))
                }
                <div style={{ width: "95%", margin: "auto", background: "black", fontSize: "2px" }}>
                    hjabnkal<hr></hr>
                </div>
                {/* project name code end */}

                {/* Teamlead card start */}
                <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
                    {                       
                        product.map((product, index) => (
                            <div className='main2001' style={{ border: "2px solid #efefef", width: "270px", borderRadius: "8px" }}>
                                <div className='top2001' style={{ display: "flex", justifyContent: "center", background: " #009933", borderRadius: "8px 8px 0px 0px" }}>
                                    <p style={{ color: "#fff", fontSize: "18px" }}> TeamLead</p>
                                </div>
                                <div className='bottom2001' style={{ display: "flex", alignItems: "center", background: "#d6d6c2", borderRadius: "0px 0px 8px 8px" }}>
                                    <div className='letf2001'>
                                        <img src={product.TLimage} alt="user" style={{ width: "90px", height: "80px", marginLeft: "5px", marginTop: "5px", borderRight: "8px" }} />
                                    </div>
                                    <div className='right2001'>
                                        <span style={{ color: "black", fontWeight: "500", marginLeft: "15px" }}>{product.TLname}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }                  
                </div>
               {/* Teamlead card end */}

                {/* employee card start */}
                <div className='empcard' style={{ display: "flex", gap: "50px", justifyContent: "center", marginTop: "60px" }}>
                    {
                        developers.map((item, index) => (
                            <>
                                <div className='main2001' style={{ border: "2px solid #efefef", width: "270px", borderRadius: "8px", marginBottom: "25px" }}>
                                    <div className='top2001' style={{ display: "flex", justifyContent: "center", background: "#476b6b", borderRadius: "8px 8px 0px 0px" }}>
                                        <p style={{ color: "#fff", fontSize: "18px" }}> Employee</p>
                                    </div>
                                    <div className='bottom2001' style={{ display: "flex", alignItems: "center", background: "#d6d6c2", borderRadius: "0px 0px 8px 8px" }}>
                                        <div className='letf2001'>
                                            <img src={item.TLimage} alt="user" style={{ width: "90px", height: "80px", marginLeft: "5px", marginTop: "5px", borderRight: "8px" }} />
                                        </div>
                                        <div className='right2001'>
                                            <span style={{ color: "black", fontWeight: "500", marginLeft: "15px" }}>{item.TLname}</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                    
                </div>
                {/* employee card end */}
            </div>
        </>
    );
};

export default ProjectTeamDetails;
