import React from "react";
import NotificationData from "../navDropdown/Notification/NotificationData";

const NotificationHome = ({ Holidays }) => {
  const getFirstThreeWords = (text) => {
    return text.split(" ").slice(0, 3).join(" ");
  };

  return (
    <div className="mainHome-sub-div">
      <div className="notification-container">
        <h5 className="notification-title">Notifications</h5>
        <i className="fas fa-bell notification-icon"></i>
      </div>
      {NotificationData.slice(-4).map((data, index) => (
        <div key={`${data.id}-${index}`} className="notification-card Notification-Home-Card">
          <img
            src={data.img}
            className="notification-image"
            width={40}
            height={40}
            alt={data.title}
          />
          <div className="notification-content">
            <b className="notification-title-bold">{data.title}</b>
            <p className="notification-para">
              {getFirstThreeWords(data.para)}.....
            </p>
            <i className="notification-time">{data.time}</i>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationHome;
