import React, { useEffect, useState } from "react";
import "../Dashboard/ApplayLeavesForm.css";
import axios from "axios";
import { FaTrashAlt } from "react-icons/fa";

const Performancedata = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://hrmsadmin.hola9.com/account/wanted/");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      const response = await fetch(`https://hrmsadmin.hola9.com/account/wanted/${id}/`, {
        method: "DELETE"
      });
      if (!response.ok) {
        alert("Network response was not ok");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  //-----------------  Filter data based on selected date --------------------------------- //
  const filteredData = selectedDate
    ? data.filter((item) => item.date === selectedDate)
    : data;


  //-----------------  Clear selected date --------------------------------- //
  const clearSelectedDate = () => {
    setSelectedDate("");
  };

  return (
    <>
      <div style={{ width: '40%', display: 'flex' }}>
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          style={{ margin: "10px" }}
        />
        <button onClick={clearSelectedDate} style={{ whiteSpace: 'nowrap' }}>Clear Date</button>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {filteredData.map((data) => (
          <div key={data.id} style={{ overflow: 'hidden', width: '220px', height: '300px', background: 'whitesmoke', borderRadius: '10px 10px 3px 3px', margin: '10px', flexGrow: '' }}>
            <div style={{ display: 'flex', justifyContent: 'right', height: '0', position: 'relative' }}>
              <button style={{ padding: '0', color: 'red', marginTop: '5px' }} onClick={() => handleDeleteClick(data.id)} ><FaTrashAlt /></button>
            </div>
            <div style={{ width: '100%', height: '40%' }}>
              {data.images && data.images.length > 0 && (
                <img src={`https://hrmsadmin.hola9.com${data.images[0].image}`} alt={data.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              )}
            </div>
            <div style={{ padding: '5px', height: '50%' }}>
              <h6 style={{ textAlign: 'right' }}>{data.date}</h6>
              <h5>{data.title}</h5>
              <i style={{ margin: '5px', }}>{data.description}</i>
            </div>

            <p style={{ background: 'rgba(0, 0, 0, 0.2)', color: 'white', textAlign: 'center', padding: '6px 0', borderRadius: '13px 13px 0 0', height: '10%' }}>{data.event_type}</p>
          </div>
        ))}
      </div>
    </>
  );
};

const PerformanceEvents = () => {
  const storedColor = localStorage.getItem("selectedColor");
  const [isLoading, setIsLoading] = useState(false);

  const [post, setPost] = useState({
    title: "",
    date: "",
    description: "",
    images: null,
    event_type: ''
  });

  const handleInput = (e) => {
    const { name, value, type } = e.target;
    const newValue = type === "file" ? e.target.files : value;

    setPost({
      ...post,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    try {
      const postData = new FormData();

      postData.append("title", post.title);
      postData.append("date", post.date);
      postData.append("description", post.description);
      postData.append("event_type", post.event_type);

      if (post.images) {
        for (const image of post.images) {
          postData.append("images", image);
        }
      }

      const response = await axios.post(
        "https://hrmsadmin.hola9.com/account/wanted/",
        postData
      );

      if (response.status === 201) {
        alert("Posted Successfully");
        console.log("Performance", post);
        setIsLoading(false)
      } else {
        alert("Failed to post form");
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error);
      alert("Failed to post form");
      setIsLoading(false)
    }

    console.log("Performance", post);
  };

  return (
    <>
      {isLoading &&
        <div className="popup">
          <center className="loading-indicator">
            <img
              src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
              alt="---loading"
              className="emp-loading"
            />
          </center>
        </div>}
      <div
        style={{
          background: "rgba(0, 0, 0, 0.4)",
          width: "100%",
          padding: "10% 0",
        }}
      >
        <form
          className="leave-form"
          onSubmit={handleSubmit}
          style={{
            background: "white",
            padding: "40px",
            borderRadius: "10px",
            width: "70%",
            height: "auto",
            margin: "auto",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              background: storedColor,
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            Performance & Event
          </h3>
          <p
            id="description"
            className="main-description"
            style={{
              height: "0",
              textAlign: "right",
              marginBottom: "50px",
            }}
          >
            Hola9 Classifieds India Private Ltd.
          </p>

          <label>
            Title
            <input
              name="title"
              value={post.title}
              onChange={handleInput}
            />
          </label>

          <label>
            Date
            <input
              type="date"
              name="date"
              value={post.date}
              onChange={handleInput}
            />
          </label>

          <label>
            Images
            <input
              type="file"
              accept="image/*"
              multiple
              name="images"
              onChange={handleInput}
            />
          </label>

          <label>
            Description
            <textarea
              name="description"
              value={post.description}
              onChange={handleInput}
            />
          </label>

          <label>
            Event type:
            <select name="event_type" value={post.event_type} onChange={handleInput} required>
              <option value='null'>select</option>
              <option value='employee_of_month'>Employee of Month</option>
              <option value='employee_of_week'>Employee of Week</option>
              <option value='employee_birthday'>Employee Birthday</option>
              <option value='festival'>Festival / Event</option>
            </select>
          </label>

          <button type="submit" style={{ background: 'red', color: "white" }}>
            Submit
          </button>
        </form>
      </div>

      <Performancedata />

    </>
  );
};

export default PerformanceEvents;
