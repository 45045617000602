import React, { useEffect, useState } from "react";

const EventsLeave = () => {
  const [galleryData, setGalleryData] = useState([]);
  const currentDate =
    new Date().toISOString().split("T")[0]
    ; // Get today's date in "YYYY-MM-DD" format

  useEffect(() => {
    fetch("https://hrmsadmin.hola9.com/account/holiday/")
      .then((response) => response.json())
      .then((data) => {
        // Filter out completed holidays
        const upcomingHolidays = data.filter(
          (holiday) => holiday.date >= currentDate
        );

        // Sort upcoming holidays by date in ascending order
        const sortedData = upcomingHolidays.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        // Take the first 3 upcoming holidays
        const upcomingThreeHolidays = sortedData.slice(0, 3);

        // Modify the "week" field to display only three letters
        // Modify the "date" field to display only the date
        const modifiedData = upcomingThreeHolidays.map((holiday) => ({
          ...holiday,
          week: holiday.week.substring(0, 3),
          date: holiday.date.split("-")[2], // Assuming the date format is YYYY-MM-DD
        }));

        setGalleryData(modifiedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [currentDate]);

  return (
    <>
      <div className="event-sub-div" style={{ background: "transparent" }}>
        <h4 style={{ color: "unset", paddingLeft: "10px" }}>
          Upcoming Holidays
        </h4>
        <div className="event-row" style={{ flexDirection: "column" }}>
          {galleryData.map((data, index) => (
            <a className="event-Home-Card" href="/" style={{ width: "100%" }}>
              <span className="event-date-info">
                <p>{data.week}</p>
                <h3>{data.date}</h3>
              </span>
              <span className="event-details">
                <b style={{ color: "unset" }}>{data.holidayname}</b>
                <p style={{ color: "unset" }}>{data.desc}</p>
              </span>
            </a>
          ))}
        </div>
        {/* <p className="event-view-calendar">View Full Calander</p> */}
      </div>
    </>
  );
};

export default EventsLeave;
