import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Checkcred from "../../section/navbar/Checkcred";

const NameHome = () => {
  const navigate = useNavigate();
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const [userData, setUserData] = useState([]);
  const name = loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name : logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name : null;
  const [isLoading1, setIsLoading1] = useState(true);

  useEffect(() => {
    // Fetch data from your API
    fetch(`https://hrmsadmin.hola9.com/account/loginprofile/?name=${name}`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setIsLoading1(false);
      })
      .catch(() => {
      });
  }, []);

  const getImageMIMEType = (imageData) => {
    // Detect the MIME type based on the image data
    if (imageData.startsWith('data:image/png')) {
      return 'image/png';
    } else if (imageData.startsWith('data:image/jpeg') || imageData.startsWith('data:image/jpg')) {
      return 'image/jpeg';
    } else if (imageData.startsWith('data:image/gif')) {
      return 'image/gif';
    }
    // Add more formats as needed

    return 'image/png'; // Default to PNG
  };
  const savedLoginStatus = localStorage.getItem("isLoggedIn");

  return (
    <div className="name-home-container" >
      <h3 className="name-home-welcome">
        Welcome,
        <span className="name-home-span">
          {loggedInUser || logintesting || loginbackend ? (
            <>
              {loggedInUser
                ? loggedInUser.name
                : logintesting
                  ? logintesting.name
                  : loginbackend
                    ? loginbackend.name
                    : null}
            </>
          ) : logintl || loginbackendtl || logintltest ? (
            <> Team Lead </>
          ) : loggedInadmin ? (
            <> Admin </>
          ) : null}
        </span>
      </h3>
      <h4
        onClick={() =>
          navigate(
            !loggedInadmin ?
              `/profileDashboard/${loggedInUser
                ? loggedInUser.name
                : logintesting
                  ? logintesting.name
                  : loginbackend
                    ? loginbackend.name
                    : logintl
                      ? logintl.name
                      : logintltest
                        ? logintltest.name
                        : loginbackendtl
                          ? loginbackendtl.name
                          : null
              }` : `/AdminDashboard/${loggedInadmin.email}`
          )
        }
        className="name-home-profile"
      >
        {isLoading1 ? ( // Render loading indicator if data is still loading
          <center className="loading-indicator"> <img
            src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
            alt="---loading"
            className="name-home-loading"
          /> </center>
        ) : (
          <>
            {userData.find((user) => user.name === name) ? (
              userData.map((user, index) => user.name === name && (
                <div key={`${userData.user}-${index}`}>
                  <img
                    src={`${user.image}`}
                    alt="User"
                    style={{ border: savedLoginStatus ? "2.5px solid greenYellow" : "2.5px solid Red" }}
                    className="name-home-loading"
                  />
                </div>
              ))
            ) : (
              <img
                src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
                alt="---loading"
                className="name-home-loading"
              />
            )}</>
        )}
        &nbsp;Profile&nbsp;
        <i
          className="fas fa-arrow-circle-right fa-right-icon"
          style={{ color: "rgba(250, 250, 250, 0.5)" }}
        ></i>
      </h4>
    </div>
  );
};

export default NameHome;
