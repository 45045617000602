import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './components/Home';
import UserLogin from './components/login/UserLogin';

import AdminDashboard from './components/Dashboard/AdminDashboard';
import ProjectDetails from './components/Dashboard/ProjectDetails';
import ProjectDetails1 from './components/Dashboard/Productdetailadmin';
import Dashboard from './components/Dashboard/Dashboard';
import Adminlogin from './components/login/Adminlogin';
import ProfileForm from './components/Dashboard/Profile';
import Navbar from './section/navbar/Navbar';
import Footer from './section/footer/Footer';
import TaskDetails from './components/Userdashboard/TaskDetailsPopup';
import SalarySlip from './components/Dashboard/SalarySlip';
import AddAdmin from './components/Dashboard/AddAdmin';
import Assigntask from './components/Dashboard/Assigntask';
import PaySlip from './components/Dashboard/PaySlip';
import YourComponent from './components/Dashboard/Attendenceerror';
import Employeeslips from './components/Dashboard/Employeeslips';
import EmployeeEditForm from './components/Dashboard/Employeeslipedit';
import Loginpro from './components/Userdashboard/Loginpro';

import Assigntasktl from './components/Dashboard/Assigntesting';
import Assigntaskbackend from './components/Dashboard/Assigntaskbackend';
import EmailSender from './components/Dashboard/Sendmail';
import VoiceReader from './section/portfolio/Voicereader';
import OfflineListener from './components/Offline';
import { NoInternetWarning, Seruvavailabel } from './components/Internet';
import Serverissue from './components/Serverissue';
import Notification from './components/navDropdown/Notification/Notification';
import Holidays from './components/navDropdown/Holidays/Holidays';
import ProfileDashboard from './components/Userdashboard/ProfileDashboard/ProfileDashboard';
import EmployeeTabledetail from './components/Userdashboard/Employeetabledetails';
import LeaveHome from './components/navDropdown/Leave/LeaveHome';
import Contact from './section/contact/Contact';
import About from './section/about/About';
import LoginForm from './components/SignUp/LoginForm'
import Loggedout from './components/Loggedout';
import DeviceCheckPage from './components/Mobileerror';
import ProfileAdminDashBoard from './components/Userdashboard/ProfileDashboard/ProfileAdminDashBoard';
import { LocationComponent } from './components/Dashboard/Pricing';
import AdminSignup from './components/Adminsignup';
import ProjectTeamDetails from './components/Home/ProjectTeamDetails';
import Allempdash from './components/AllEmployeesDashboard/Allempdash';
import EditProfile from './components/Dashboard/EditProfile';
import Checkcred from './section/navbar/Checkcred';
import Trail from './section/navbar/Trial';

// import UserDashboard from './components/Userdashboard/UserDashboard';

function App() {
  return (


    <div>
      <BrowserRouter>
        <OfflineListener />
        <Serverissue />
        {/* <Checkcred /> */}
        <Loggedout />
        {/* <DeviceCheckPage/> */}
        <Navbar />

        <Routes>
          <Route path='/' exact element={<Home />} />
          {/* <Route path='/Signup' exact element={<Trail/>}/> */}
          <Route path='/UserLogin' exact element={<UserLogin />} />
          <Route path='/mobile-error' exact element={<DeviceCheckPage />} />
          <Route path='/nointernet' element={<NoInternetWarning />} />
          <Route path='/serviceunavailable' element={<Seruvavailabel />} />
          <Route path='/AdminLogin' exact element={<Adminlogin />} />
          <Route path='/profile/:nam' exact element={<ProfileForm />} />
          <Route path='/AdminDashboard/:user' exact element={<ProfileAdminDashBoard />} />
          <Route path='/ProjectDetails/:id' exact element={<ProjectDetails />} />
          <Route path='/Pricing/:id' exact element={<LocationComponent />} />
          <Route path='/ProjectCards/:id' exact element={<ProjectTeamDetails />} />
          <Route path='/task-details/:taskId' exact element={<TaskDetails />} />
          <Route path='/Dashboard' exact element={<Dashboard />} />
          <Route path='/salaryslip' exact element={<SalarySlip />} />
          <Route path='/addadmin' exact element={<AddAdmin />} />
          <Route path='/Assigntask' exact element={<Assigntask />} />
          <Route path='/Assigntasktest' exact element={<Assigntasktl />} />
          <Route path='/Assigntaskbackend' exact element={<Assigntaskbackend />} />
          <Route path='/Printslip/:emp' exact element={<PaySlip />} />
          <Route path='/atteror' exact element={<YourComponent />} />
          <Route path='/allslips' exact element={<Employeeslips />} />
          <Route path='/emp' exact element={<Loginpro />} />
          <Route path='/mailto' exact element={<EmailSender />} />
          <Route path='/voicetper' exact element={<VoiceReader />} />
          <Route path='/edit/:employeeId' exact element={<EmployeeEditForm />} />
          <Route path='/ProjectDetails1/:id' exact element={<ProjectDetails1 />} />
          <Route path='/notification' exact element={<Notification />} />
          <Route path='/holidays' exact element={<Holidays />} />
          <Route path='/profileDashboard/:name' exact element={<ProfileDashboard />} />
          <Route path='/leave' exact element={<LeaveHome />} />
          <Route path='/ContactUs' exact element={<Contact />} />
          <Route path='/AboutUs' exact element={<About />} />
          <Route path='/login' exact element={<LoginForm />} />
          <Route path='/Signup' exact element={<AdminSignup />} />
          <Route path='/empdash/:name' exact element={<Allempdash />} />
          <Route path='/EditProf/:name' exact element={<EditProfile />} />

        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
