import React, { useState } from 'react'
import "./contact.css"
import {MdEmail} from "react-icons/md"
import {SiMessenger} from "react-icons/si"
import {RiWhatsappFill} from "react-icons/ri"
import Navbar from '../../section/navbar/Navbar'
const Contact = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Message: '',
    PhoneNumber: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get the current date
    const currentDate = new Date().toISOString().slice(0, 10);

    // Add the current date to the form data
    const dataToSend = {
      ...formData,
      created_at: currentDate,
    };

    try {
      // Send data to your API
      const response = await fetch('https://databytess.com/api/contactus/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        console.log('Data sent successfully');
      window.alert('Submitted successfully');

        // You can add further actions or redirects here
      } else {
        console.error('Error sending data to the API');
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('Submit Failed');

    }
  };
  const storedColor = localStorage.getItem('selectedColor');

  return (
    <>

    <Navbar />

    <section id='contact'>

    <div style={{height:'70px'}}></div>
   
    
    <h2 style={{color: "#092144"}}>Contact us</h2>
    <br></br>
    <br></br>
    <div className='container contact__container'>
      <div className='contact__options'>
      <article className='contact__option' style={{background:storedColor}}> 
       <a href='mailto:hello@hola9.com' target="_blank"><MdEmail className='contact__option-icon'/></a>
        <h5 className="hello">Email</h5>        
        <h6 className="hello">hello@hola9.com</h6>
       
      </article>
      <article className='contact__option' style={{background:storedColor}}>
        <a href='https://messenger.com' target="_blank"> <SiMessenger className='contact__option-icon'/></a>
        <h5 className="hello">Messenger</h5>
        <h6 className="hello">Hola9</h6>

      </article>
      <article className='contact__option' style={{background:storedColor}}>
        <a href='https://api.whatsapp.com/send?phone=9606451628' target="_blank"><RiWhatsappFill className='contact__option-icon'/></a>
        <h5 className="hello">What's App</h5>
        {/* <h5>+91 </h5> */}
        
      </article>

      </div>
      {/* END OF THE CONTACT */}
      <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="Name"
        placeholder="Your Full name"
        value={formData.Name}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        name="Email"
        placeholder="Your Email"
        value={formData.Email}
        onChange={handleChange}
        required
      />
      <input
        type="number"
        name="PhoneNumber"
        placeholder="Your Phone Number"
        value={formData.PhoneNumber}
        onChange={handleChange}
        pattern="[0-9]{10}"
        title="Please enter a 10-digit phone number"
        required
      />
      <textarea
        name="Message"
        cols="30"
        rows="7"
        placeholder="Your Message"
        value={formData.Message}
        onChange={handleChange}
        required
      />
        <button type='submit' className='btn btn-light' style={{background:storedColor}}>Send Message</button>
      </form>
    </div>

    </section>
    </>
  )
}

export default Contact