import React from 'react';
import { Link } from 'react-router-dom';
import server from "../images/server.gif";
import internet from "../images/offline.gif";
export const NoInternetWarning = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
      <span role="img" aria-label="Warning" style={{ fontSize: '50px' }}>
        ⚠️
      </span>
      <p style={{ fontSize: '24px', marginTop: '20px' }}>
        Oops! It seems like you're offline.
        <br/>
        <Link to="/">
      <button className='taskbtn2001'>Try again</button>
      </Link>
      </p>
      
      <img src={internet} alt="No Internet Gif" style={{ maxWidth: '100%', marginTop: '20px' }} />
      <p style={{ fontSize: '18px', marginTop: '20px' }}>
        Please check your internet connection and try again.
      </p>
    </div>
  );
};

export const Seruvavailabel = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
      <span role="img" aria-label="Warning" style={{ fontSize: '50px' }}>
        ⚠️
      </span>
      <p style={{ fontSize: '24px', marginTop: '20px' }}>
        Server Unvailabel.<span style={{color:"red"}}>Under Maintainance</span>
        <br/>
        <Link to="/">
      <button className='taskbtn2001'>Try again</button>
      </Link>
      </p>
      
      <img src={server} alt="No servaer Gif" style={{ maxWidth: '100%', marginTop: '20px' }} />
      <p style={{ fontSize: '18px', marginTop: '20px' }}>
        Please try again after some time.
      </p>
    </div>
  );
};

