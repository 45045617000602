import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate } from 'react-router-dom';
import DocumentViewer from './Dashboard';
import "./AdminDashboard.css"
import ReactPaginate from 'react-paginate'; // Import pagination component
import Navbar from '../../section/navbar/Navbar';
import Employeelogin from '../Userdashboard/Employeelogin';
import Employeetask from '../Userdashboard/Employeetask';
import Clock from '../Userdashboard/MapLocationRow ';
import YourComponent from './Attendenceerror';

function EmployeeTable() {
  const [data, setData] = useState([]);
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [nameEmailFilter, setNameEmailFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const itemsPerPage = 40; // Number of items per page
  const [isLoading, setIsLoading] = useState(true);

  // popup logic for deatails btn start
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  // popup logic for deatails btn end
  const [selectedTab, setSelectedTab] = useState("allEmployees");
  const [itemsToLoad, setItemsToLoad] = useState(30);
  const [searchName, setSearchName] = useState('');
  // const [currentPage, setCurrentPage] = useState(0);
  // const [buttonClicked, setButtonClicked] = useState(false);
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    setLimitIncreaseCount(0);
  };
  useEffect(() => {
    fetchData();
  }, [itemsToLoad]);// Holds the number of items to load initially and increases when "Load More" is clicked
  const fetchData = () => {
    fetch(`https://hrmsadmin.hola9.com/account/employeeprofile/?limit=${itemsToLoad}&${searchName ? `&name=${searchName}` : ''}`)
      .then((response) => response.json())
      .then((newData) => {
        setData(newData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false); // Set isLoading to false regardless of success or failure
      });
  };



  const handleLoadMore = () => {
    console.log('handleLoadMore called');
    if (!isLoading) {
      setItemsToLoad(itemsToLoad + 30);
    }
    setLimitIncreaseCount((prevCount) => prevCount + 1);

  };
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading && limitIncreaseCount != 3) {
        const newDataLength = data.length;

        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, data, prevDataLength, limitIncreaseCount]);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInadmin = localStorage.getItem("loggedInadmin");

    if (!loggedInadmin) {
      navigate("/AdminLogin");
    }
  }, []);
  // Filter function to apply filters
  const filteredData = data.filter((employee) => {
    // Filter by Name/Email
    if (nameEmailFilter) {
      const name = employee.comment.toLowerCase();
      const email = employee.report_of_work ? employee.report_of_work.toLowerCase() : '';
      const task = employee.task ? employee.task.toLowerCase() : '';
      const nameEmail = name + email + task;
      if (!nameEmail.includes(nameEmailFilter.toLowerCase())) {
        return false;
      }
    }

    // Filter by Date Range
    if (dateFilter) {
      const employeeDate = new Date(employee.task_date);
      const selectedDate = new Date(dateFilter);

      // Check if the selected date matches the employee's task_date
      if (employeeDate.toDateString() !== selectedDate.toDateString()) {
        return false;
      }
    }

    return true;
  });

  const sortedData = filteredData.sort((a, b) => b.id - a.id);
  // Calculate the start and end indexes based on the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the data to display for the current page
  const dataToDisplay = filteredData.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const pageCount = Math.ceil(sortedData.length / itemsPerPage);
  const handleSearchByName = () => {
    fetchData();
    setItemsToLoad(30);
    setIsLoading(true); // You can also perform additional logic here if needed
  };
  const [data1, setData1] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData1(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const names = data1.map((item) => item.name);
  const handleSelectChange = (event) => {
    setSearchName(event.target.value);
  };
  return (
    <>
      {/* <Navbar/>
    
    <Clock/>
    <h5 style={{ marginTop: "10px", fontFamily: "sans-serif", color: "navy", animation: "fadeInUp 1s ease-in-out" ,textAlign:"center"}}>
    ✨ Behind every successful organization, there is a diligent administrator keeping things in order✨
    </h5>
     */}






      <div className="employee-table-container">
        <div className="profileInput" style={{ display: "flex" }}>
          <input
            type="date"
            className="input1"
            placeholder="Select Date"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            style={{
              height: "10px",
            }}
          />
          <input
            className="input2"
            type="search"
            placeholder="Search.."
            value={nameEmailFilter}
            onChange={(e) => setNameEmailFilter(e.target.value)}
            style={{
              height: "10px",
            }}
          />
          &nbsp;
          <select
            value={searchName}
            onChange={handleSelectChange}
            placeholder='select name' style={{ width: "40%", borderRadius: "10px" }}
          >
            <option value="">Select Name</option>
            {names.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          <button className='btn' onClick={handleSearchByName}>Search</button>
          {/* <div>
          <ReactHTMLTableToExcel
        id="downloadButton"
        className="download-button"
        table="employeeTable"
        filename="employee_data"
        sheet="employee_data"
        buttonText={
          <>
            <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '8px' }} />
            Download Details
          </>
        }
        
      />
        </div> */}
        </div>

        {isLoading ? (
          <h3 style={{ fontFamily: "sans-serif" }}>Hello Admin Please Wait We Are Fetching All Employee Data 👨‍💼</h3>) : (
          <>
            <h3 style={{ fontFamily: "sans-serif" }}>Here is your Total employee Details👨‍💼 You Can Serch For Individual employee also </h3> <ReactHTMLTableToExcel
              id="downloadButton"
              className="download-button"
              table="employeeTable"
              filename="employee_data"
              sheet="employee_data"
              buttonText={
                <>
                  <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '8px' }} />
                  Download Details
                </>
              }

            /></>
        )}

        {isLoading ? ( // Render loading indicator if data is still loading
          <center className="loading-indicator" style={{ padding: "50px" }}><img src='https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif' alt='loading...' /></center>
        ) : (

          <>
            {filteredData.length > itemsPerPage && (

              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactPaginate
                  className='paginat'
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>)}
            <div style={{ overflowX: 'auto' }}>
              <table className="employee-table" id="employeeTable">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Task Date</th>
                    <th>Task</th>
                    {/* <th>Report of Work</th>
                    <th>Completion Status</th> */}
                    <th>Work Report</th>
                    <th>Document</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {dataToDisplay.sort((a, b) => b.id - a.id).map((employee) => (
                    <tr key={employee.id}>
                      <td><a href={`https://hrmsadmin.hola9.com/admin/account/employeedetails/${employee.id}/change/`} target='blank' style={{ color: "navy" }}>{employee.id}</a> </td>
                      <td>
                        <Link to={`/userdash/${employee.name}`} style={{ color: 'navy' }}>
                          {employee.name}
                        </Link>
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>{employee.task_date}</td>
                      <td>{employee.task}</td>
                      <td>
                        <pre>
                          {`Report  -  ${employee.report_of_work}`}
                        </pre>
                        <pre>
                          {`Status  -  ${employee.completion_status}`}
                        </pre>
                      </td>
                      {/* <td>{employee.report_of_work}</td>
                      <td>{employee.completion_status}</td> */}
                      {/* <td style={{ fontSize: '50%' }}><td><a href={employee.documnet} target='_blank' style={{color:"navy"}}>View Document</a></td>
                        <td>
                          {employee.document_base64 && (
                            <DocumentViewer documentData={employee} />
                          )}
                        </td>
                        </td> */}
                      <td>
                        <span>
                          <a href={employee.documnet} target='_blank' style={{ color: "navy" }}>View Document</a>
                        </span>
                        <span>
                          {employee.document_base64 && (
                            <DocumentViewer documentData={employee} />
                          )}
                        </span>
                      </td>
                      <td>{employee.comment}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {/* Pagination component */}
        {filteredData.length > itemsPerPage && (

          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactPaginate
              className='paginat'
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>)}
      </div>

    </>
  );
}

export default EmployeeTable;
