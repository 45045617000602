import React, { useState } from 'react';
import './ProjectDetails.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BiSolidDashboard } from "react-icons/bi"
import axios from 'axios';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Navbar1 from '../../section/navbar/Navbar1';
import Navbar from '../../section/navbar/Navbar';

const ProjectDetails = () => {
  const { id } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [id1, setid] = useState(id);
  const [task, settask] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = useState('');
  const [selectedRadio, setSelectedRadio] = useState('');
  const [comment, setcomment] = useState('');
  const [formError, setFormError] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
  const [loading, setLoading] = useState(false); // State to control loading overlay

  const [isLoading1, setIsLoading1] = useState(true);

  const handleDropdownChange = (e) => {
    setSelectedDropdown(e.target.value);
  };

  // Function to handle radio button value change
  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const resetForm = () => {
    setid(id);
    settask('');
    setStartDate(null);
    setSelectedDropdown('');
    setSelectedRadio('');
    setcomment('');
    setSelectedFile(null);
    setFormError('');
    setUploadProgress(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !id1 ||
      !task ||
      !startDate ||
      !selectedDropdown ||
      !selectedRadio ||
      !comment ||
      !selectedFile
    ) {
      setFormError('Please fill out all required fields.');
      return;
    }

    setLoading(true); // Show loading overlay

    const formData = new FormData();
    formData.append('name', id1);
    formData.append('task', task);
    formData.append('task_date', startDate);
    formData.append('report_of_work', selectedDropdown);
    formData.append('completion_status', selectedRadio);
    formData.append('comment', comment);
    formData.append('documnet', selectedFile);

    try {
      await axios.post('https://hrmsadmin.hola9.com/account/detailEmployee/', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      console.log('Data sent successfully');
      setShowSuccessPopup(true);
      resetForm();
    } catch (error) {
      console.error('Error sending data:', error);
    } finally {
      setLoading(false); // Hide loading overlay regardless of success or failure
    }
  };
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    // Fetch data from your API
    fetch('https://hrmsadmin.hola9.com/account/loginprofile/')
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setIsLoading1(false)
      });
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const loggedInUser = localStorage.getItem("logintl");

    if (!loggedInUser) {
      navigate("/UserLogin");
    }
  }, []);
  const getImageMIMEType = (imageData) => {
    // Detect the MIME type based on the image data
    if (imageData.startsWith('data:image/png')) {
      return 'image/png';
    } else if (imageData.startsWith('data:image/jpeg') || imageData.startsWith('data:image/jpg')) {
      return 'image/jpeg';
    } else if (imageData.startsWith('data:image/gif')) {
      return 'image/gif';
    }
    // Add more formats as needed

    return 'image/png'; // Default to PNG
  };

  return (
    <>
      <Navbar />
      <br /><br />
      <div className="project-details-container">
        <main className="main-content">
          <div className="glassmorphism-background">
            <div className="heading">
              <div className="green-bar" />
              <h1 id="title" className="main-heading" style={{ display: 'flex', justifyContent: 'center' }}>
                <span style={{ color: "red" }}>E</span>mployee
              </h1>
              <div className='maindi'>
                <div >
                  <div className='dashboradIcon2001'>
                    <p id="description" className="main-description">
                      Hola9 Classifieds India Private Limited
                    </p>

                  </div>
                  <label htmlFor="name" id="name-label">
                    Name : <span className="required" style={{ color: "rgb(0, 183, 255)" }}>{id}</span>
                  </label>
                </div>
                <div className='imgcont'>

                  {isLoading1 ? ( // Render loading indicator if data is still loading
                    <center className="loading-indicator">Loading...</center>
                  ) : (
                    <>
                      {userData.find((user) => user.name === id) ? (
                        userData.map((user) => user.name === id && (
                          <>
                            <div className="card-body" style={{ textAlign: "center" }}>

                              <img
                                src={`${user.image}`}
                                alt="User"
                                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
                              />
                            </div>

                          </>
                        ))
                      ) : (
                        <div className="card-body" style={{ textAlign: "center" }}>
                          <Link to={`/profile/${id}`}>
                            <Button>Add Profile</Button>
                          </Link>
                        </div>
                      )}</>
                  )}


                </div>
              </div>
              <hr />
            </div>
            <form onSubmit={handleSubmit} className="survey-form">
              <label htmlFor="name" id="name-label">
                Name<span className="required">*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={id1}
                className="name"
                placeholder={id}
                required // Add the required attribute
                onChange={(e) => setid(e.target.value)}
                readOnly
              />
              <label htmlFor="task" id="task-label">
                Task<span className="required">*</span>
              </label>
              <input
                type="text"
                name="task"
                id="task"
                className="task"
                placeholder="Enter your Task"
                required // Add the required attribute
                onChange={(e) => settask(e.target.value)}
              />
              <label>Select a date:<span className="required">*</span></label>
              <div>
                <span>Date</span>
                <input
                  type="date"
                  placeholder="From Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <label htmlFor="dropdown" id="dropdown-label">
                Report of Work?<span className="required">*</span>
              </label>
              <select
                name="dropdown"
                id="dropdown"
                className="dropdown"
                required // Add the required attribute
                value={selectedDropdown}
                onChange={handleDropdownChange}
              >
                <option value="">Select an option</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
              </select>
              <p className="radio-btn-description">
                How much complete work?<span className="required">*</span>
              </p>
              <div style={{ display: 'flex', justifyContent: 'start', paddingRight: '20px' }}>
                <label className="radio-btn-label" htmlFor="25-percent">
                  <input
                    type="radio"
                    id="25-percent"
                    name="completion-status"
                    className="completion-status"
                    value="25%"
                    checked={selectedRadio === '25%'}
                    onChange={handleRadioChange}
                    required // Add the required attribute
                  />{' '}
                  25%
                </label>
                <label className="radio-btn-label" htmlFor="50-percent">
                  <input
                    type="radio"
                    id="50-percent"
                    name="completion-status"
                    className="completion-status"
                    value="50%"
                    checked={selectedRadio === '50%'}
                    onChange={handleRadioChange}
                    required // Add the required attribute
                  />{' '}
                  50%
                </label>
                <label className="radio-btn-label" htmlFor="75-percent">
                  <input
                    type="radio"
                    id="75-percent"
                    name="completion-status"
                    className="completion-status"
                    value="75%"
                    checked={selectedRadio === '75%'}
                    onChange={handleRadioChange}
                    required // Add the required attribute
                  />{' '}
                  75%
                </label>
                <label className="radio-btn-label" htmlFor="100-percent">
                  <input
                    type="radio"
                    id="100-percent"
                    name="completion-status"
                    className="completion-status"
                    value="100%"
                    checked={selectedRadio === '100%'}
                    onChange={handleRadioChange}
                    required // Add the required attribute
                  />{' '}
                  100%
                </label>
              </div>
              <label>File Upload<span className="required">*</span></label>
              <input
                type="file"
                className="col-lg-12"
                accept=".zip, .jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .mp4, .avi, .mkv, .mov, .wmv"
                onChange={handleFileChange}
                required // Add the required attribute
              />
              {loading && (
                <div className="loading-overlay">
                  <div className="loading-spinner" />
                </div>
              )}
              {uploadProgress > 0 && uploadProgress < 100 && (
                <div className="progress-bar-container">
                  <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
                    {uploadProgress}%
                  </div>
                </div>
              )}
              <label htmlFor="comments">Any comments or suggestions?<span className="required">*</span></label>
              <textarea
                name="comments"
                id="comments"
                cols={30}
                required
                rows={5}
                placeholder="Enter your suggestions here"
                defaultValue={''}
                onChange={(e) => setcomment(e.target.value)}
              />
              {formError && <div className="error" style={{ color: 'red' }}>{formError}</div>}
              {showSuccessPopup && (
                <p className="success-message" style={{ color: 'green' }}>
                  Your data was submitted successfully!
                </p>
              )}
              <center>
                <button className='submitbtn2001' type="submit" disabled={showSuccessPopup || loading || uploadProgress > 0}>
                  Submit
                </button>
              </center>
              {/* <button class="button button5">Black</button> */}
            </form>
          </div>
        </main>
      </div></>
  );
};

export default ProjectDetails;
