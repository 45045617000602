import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiEdit, BiPrinter } from 'react-icons/bi';
import Navbar from '../../section/navbar/Navbar';
import companyLogo from './../../images/Hola9logo.jpg';
import stamp from './../../images/stamp.jpg';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FcDownload } from 'react-icons/fc';
const monthsList = [
  'January', 'February', 'March', 'April',
  'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December',
];

const Personalslip = (props) => {
  const name = props.name;

  const [employees, setEmployees] = useState([]);
  const [editEmployeeId, setEditEmployeeId] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Initialize with current month
  const [loading, setLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const [tasksToShow, setTasksToShow] = useState(1);

  useEffect(() => {
    setLoading(true);

    fetch(`https://hrmsadmin.hola9.com/account/employees_joining/?name=${name}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 0 && employees.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
          setEmployees((prevEmployees) => [...prevEmployees, ...data]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [name]); // Empty dependency array to run only once on component mount

  const handleLoadMore = () => {
    setTasksToShow((prevTasksToShow) => prevTasksToShow + 25);
  };

  const handleEditClick = (employeeId) => {
    setEditEmployeeId(employeeId);
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth + 1) % 12); // Increment current month, loop to January if December
  };

  const handlePreviousMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1)); // Decrement current month, loop to December if January
  };

  const filteredEmployees = employees.filter((employee) => {
    // Check if the month is a valid month name
    const isValidMonth = monthsList.includes(employee.month);

    // If the month is valid and matches the current month, include the employee
    return isValidMonth && (monthsList[currentMonth] === employee.month);
  });


  const isDataFound = filteredEmployees.find((employee) => employee.employeeName === name);
  const numberToWords = (num) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convert = (num) => {
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
      if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + convert(num % 100) : '');
      if (num < 1000000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + convert(num % 1000) : '');
      if (num < 1000000000) return convert(Math.floor(num / 1000000)) + ' Million' + (num % 1000000 !== 0 ? ' ' + convert(num % 1000000) : '');
      if (num < 1000000000000) return convert(Math.floor(num / 1000000000)) + ' Billion' + (num % 1000000000 !== 0 ? ' ' + convert(num % 1000000000) : '');


    };

    return convert(num);
  };
  const handleDownloadPDF = () => {
    const input = document.getElementById('payslip-container');
    const buttonsToExclude = document.querySelectorAll('.exclude-from-pdf');
    buttonsToExclude.forEach(button => button.style.display = 'none');

    // PDF generation code

    // Restore visibility of excluded buttons after PDF generation
    buttonsToExclude.forEach(button => button.style.display = 'block');
    // Set styles for PDF generation
    const pdfStyles = {
      background: 'white', // Ensure white background
      scale: 2, // Increase scale to improve resolution
      width: input.offsetWidth, // Set width to match container width
      height: input.offsetHeight, // Set height to match container height
    };

    html2canvas(input, { scale: pdfStyles.scale })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', [pdfStyles.width, pdfStyles.height]);
        pdf.addImage(imgData, 'PNG', 0, 0, pdfStyles.width, pdfStyles.height);
        pdf.save('payslip.pdf');
      });
  };

  return (
    <>
      <Navbar />


      {loading ? (
        <div className="employee-list-container">
          <img src="https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif" alt="loading..." />
        </div>

      ) : null}
      {!loading && !noDataFound && (
        <div className="employee-list">
          <div className="employee-list-container">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-around" }}>
              <i className='fas fa-angle-double-left' onClick={handlePreviousMonth} />
              <h5>&nbsp; &nbsp; {monthsList[currentMonth]} &nbsp; &nbsp;</h5>
              <i className='fas fa-angle-double-right' onClick={handleNextMonth} />
              {!loading && isDataFound ? (

                <button onClick={handleDownloadPDF} className="taskbtn2001">
                  <FcDownload /> Download
                </button>

              ) : <p style={{ marginLeft: "50px" }}>No Slips For {monthsList[currentMonth]}</p>}
              {/* <div style={{display:"flex",justifyContent:"space-around",flexDirection:'row'}}>
      <button onClick={() => window.print()} className="taskbtn2001">
        <BiPrinter/> Print Pay Slip
      </button>
      {/* </div> */}
            </div>

            {filteredEmployees.slice(0, tasksToShow).map((employee) => (
              <div className="pay-slip" style={{ marginTop: "70px" }} id="payslip-container">
                <div className="header">
                  <img src={companyLogo} alt="Company Logo" className="company-logo" />
                  <br></br>        <br></br>

                  <hr style={{ color: "navy", backgroundColor: "black", border: "1px solid black" }} />
                </div>
                <div className="company-info">
                  <h2 style={{ fontSize: "120%" }}>Salary Slip - {employee.month} {employee.year}</h2>
                  <p style={{ fontSize: "70%" }}><a href='https://hola9.com' style={{ color: "blue" }}> Hola9</a> |  <a style={{ color: "blue" }} href='tel:+919606451628'>9606451628</a> |  <a style={{ color: "blue" }} href='mailto:hello@hola9.com'>hello@hola9.com</a></p>
                </div>
                <>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        "\ntable{\nwidth: 100%;\nborder-collapse:collapse;\nborder: 1px solid black;\n}\ntable td, table th {border: 1px solid black;}\ntable td{line-height:25px;padding-left:15px;}\ntable th{background-color:#fbc403; color:#363636;}\n"
                    }}
                  />


                  <table style={{ border: "1px solid black", fontSize: "80%" }}>
                    <tbody>

                      <tr>

                        <th>Payslip_Id</th>
                        <td>{employee.id}</td>
                        <th>Name</th>
                        <td>{employee.employeeName}</td>
                      </tr>
                      {/*---2 row-*/}
                      <tr>
                        <th>Bank</th>
                        <td>{employee.bank_name}</td>
                        <th>Branch Name</th>
                        <td>{employee.branch_name}</td>
                        {/* <th>Bank A/c No.</th>
        <td>0x2x6x25x6</td> */}
                      </tr>
                      <tr>
                        <th>Bank Acc.</th>
                        <td>{employee.acc_num}</td>
                        <th>IFSC</th>
                        <td>{employee.ifsc}</td>
                        {/* <th>PF No.</th>
        <td>-NA-</td> */}
                        {/* <th>Bank A/c No.</th>
        <td>0x2x6x25x6</td> */}
                      </tr>
                      <tr>
                        {/* <th>Bank Acc.</th>
        <td>{employee.acc_num}</td>
        <th>IFSC</th>
        <td>{employee.ifsc}</td> */}
                        <th>PF No.</th>
                        <td>-NA-</td>
                        <th>EIN</th>
                        <td>--</td>
                      </tr>
                      {/*----3 row--*/}
                      <tr>
                        <th>Working Days</th>
                        <td>{employee.totalwork}</td>
                        <th>Leaves</th>
                        <td>{employee.leaves}</td>
                      </tr>
                      {/*----4 row--*/}

                      {/*----5 row--*/}
                      <tr>
                        <th>Location</th>
                        <td>Bengaluru</td>
                        <th>Department</th>
                        <td>Software</td>
                      </tr>
                      {/*----6 row--*/}
                      <tr>

                        <th>Designation</th>
                        <td>{employee.designation}</td>
                        <th>Date of Joining</th>
                        <td>{employee.dateOfJoining}</td>
                      </tr>
                      <tr>
                        <th>Month:</th>
                        <td>{employee.month}</td>
                        <th>Year</th>
                        <td>{employee.year}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ border: "1px solid black", fontSize: "80%" }}>
                    <tbody>
                      <tr>
                        <th>Earnings</th>
                        <th>Amount</th>
                        <th>Deductions</th>
                        <th>Amount</th>
                      </tr>
                      <tr>
                        <td>Basic</td>
                        <td>{employee.basicDA}</td>
                        <td>provident fund</td>
                        <td>{employee.providentFund}</td>
                      </tr>
                      <tr>
                        <td>House Rent Allowance</td>
                        <td>{employee.hra}</td>
                        <td>professional tax</td>
                        <td>{employee.professionTax}</td>
                      </tr>
                      <tr>
                        <td>special Allowance</td>
                        <td>-NA-</td>
                        <td>Income tax</td>
                        <td>-NA-</td>
                      </tr>
                      <tr>
                        <td>conveyance</td>
                        <td>{employee.conveyance}</td>
                        <td>E.S.I</td>
                        <td>{employee.esi}</td>
                      </tr>
                      <tr>
                        <td>ADD Special allowance</td>
                        <td>-NA-</td>
                        <td>Loan</td>
                        <td>--</td>
                      </tr>
                      <tr>
                        <td>Shift Allowance</td>
                        <td>-NA-</td>
                        <td>Profession Tax</td>
                        <td>000.00</td>
                      </tr>
                      <tr>
                        <td>Bonus</td>
                        <td>000.00</td>
                        <td>LOP</td>
                        <td>000.00</td>
                      </tr>
                      <tr>
                        <td>Medical Allowance</td>
                        <td>-NA-</td>
                      </tr>
                      <tr>
                        <th>Gross Earnings</th>
                        <td>{employee.totalAddition}</td>
                        <th>Gross Deductions</th>
                        <td>{employee.totalDeduction}</td>
                      </tr>

                    </tbody>
                  </table>
                  <br />
                  <table style={{ fontSize: "80%" }}>
                    <tr>
                      <td> <strong>NET Salary </strong></td>
                      <td><strong>{employee.netSalary}</strong></td>

                      <td><strong>{numberToWords(parseFloat(employee.netSalary))} Only</strong></td>
                    </tr>
                  </table>
                </>

                <div className="stamp">
                  <img src={stamp} alt="Company Logo" className="-logo" height={150} width={170} />
                </div>
                <hr style={{ color: "navy", backgroundColor: "black", border: "1px solid black" }} />
                <center className="company-info" style={{ fontSize: "80%" }}>
                  <h5>HOLA9 Classifieds India Private Limited</h5>
                  <p>#12, SecondFloor,3rd Cross, Patel Narayana Reddy Layout, 6th Block Koramangala, Bangalore, Karnataka-560095</p>
                </center>
                <small style={{ color: "blue", fontSize: "80%" }}>E-receipt acknowledgment: Signature not necessary for this internet-generated slip
                </small>


              </div>
            ))}
          </div>
        </div>
      )}

      {!loading && !isDataFound && (
        <div className="employee-list-container">
          <img src="https://i.pinimg.com/originals/49/e5/8d/49e58d5922019b8ec4642a2e2b9291c2.png" alt="no data found" />
        </div>

      )}
      {/* {!loading && !noDataFound && employees.length > 25 && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Load More
        </button>
      )} */}
    </>
  );
};

export default Personalslip;
