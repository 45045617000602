export const TeamData = [{
    id: 1,
    Project: "Hola9",
    TLimage: "https://tse1.mm.bing.net/th?id=OIP.b7l-SugeH0Qcf6MmvhS3ZAHaLH&pid=Api&rs=1&c=1&qlt=95&w=73&h=110",
    TLname:"Suman",
    emptype:"Tl",
},{
    id: 2,
    Project: "Hola9",
    TLimage: "https://tse3.mm.bing.net/th?id=OIP.0l7k5zqRUVQ5Yq9eTpW2LgHaLJ&pid=Api&P=0&h=180",
    TLname:"Sk",
    emptype:"dev",
},{
    id: 3,
    Project: "Hola9",
    TLimage: "https://tse2.mm.bing.net/th?id=OIP.Dn-8K_TiXbuob_1fMEFurQHaE7&pid=Api&P=0&h=180",
    TLname:"Skgffh",
    emptype:"dev",
},{
    id: 4,
    Project: "Matrimony",
    TLimage: "https://tse2.mm.bing.net/th?id=OIP.Dn-8K_TiXbuob_1fMEFurQHaE7&pid=Api&P=0&h=180",
    TLname:"Skgffh",
    emptype:"dev",
},{
    id: 5,
    Project: "Matrimony",
    TLimage: "https://tse2.mm.bing.net/th?id=OIP.Dn-8K_TiXbuob_1fMEFurQHaE7&pid=Api&P=0&h=180",
    TLname:"Skgffh",
    emptype:"Tl",
},{
    id: 6,
    Project: "DatingApp",
    TLimage: "https://tse2.mm.bing.net/th?id=OIP.Dn-8K_TiXbuob_1fMEFurQHaE7&pid=Api&P=0&h=180",
    TLname:"Skgffh",
    emptype:"dev",}
]