import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../../section/navbar/Navbar";

const EditProfile = (props) => {
  const { name } = useParams();

  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    blood_group: "",
    userid: "",
    dob: "",
    image: "",
    phone_number: "",
    organization: "",
    role: "",
    email: "",
    reporting_to: "",
    marital_status: "",
    gender: "",
    project_name: "",
    working_since: "",
    sick_leave: "",
    casual_leave: "",
    earned_leave: "",
    facebook_link: null,
    insta_link: null,
    linkedin_link: null,
    twitter_link: null,
  });

  useEffect(() => {
    const fetchUserDataFromAPI = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://hrmsadmin.hola9.com/account/loginprofile/?name=${name}`
        );
        const data = response.data[0] || {};
        console.log("User Data:", data);
        setUserData(data);
        setHasChanges(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDataFromAPI();
  }, [name]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const inputValue = type === "file" ? e.target.files[0] : value;

    if (name === "working_since") {
      return;
    }

    setUserData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));

    setHasChanges(true);
  };

  const handleFormSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();

    Object.keys(userData).forEach((key) => {
      if (key !== "image") {
        formData.append(key, userData[key]);
      }
    });

    if (userData.image instanceof File) {
      formData.append("image", userData.image);
    }

    try {
      const response = await axios.put(
        `https://hrmsadmin.hola9.com/account/loginprofile/${userData.id}/`,
        formData
      );

      if (response.status === 200) {
        console.log("Profile updated successfully!", response.data);
        setUserData(response.data); // Update state with the new data
        window.alert("Profile updated successfully.");
        setHasChanges(false);
      } else {
        console.error(
          "Failed to update profile. Server returned:",
          response.status
        );
        window.alert("Failed to update profile.");
      }
    } catch (error) {
      console.error("Failed to update profile.", error);
      window.alert("Error updating profile.");
    } finally {
      setLoading(false);
    }
  };

  const bloodGroupOptions = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));

  const isEditable = loggedInadmin;

  return (
    <>
      <Navbar />
      <div className="wholeform" style={{ marginTop: "80px" }}>
        <div className="profile-form">
          {loading && (
            <div className="loading-overlay">
              <img
                src="https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif"
                alt="processing"
              />
            </div>
          )}
          <center>
            {userData.image && (
              <img
                src={userData.image}
                alt="User Image"
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            )}
          </center>

          <br />

          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <span style={{ width: "50%" }}>
              <label>
                Name
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
              </label>
              <label>
                email
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  placeholder="email"
                  disabled={!isEditable}
                />
              </label>
            </span>

            <span style={{ width: "50%" }}>
              <label>
                Organization
                <input
                  required
                  type="text"
                  name="organization"
                  value={userData.organization}
                  onChange={handleInputChange}
                  placeholder="Organization"
                  disabled={!isEditable}
                />
              </label>
              <span style={{ display: "flex", gap: "5px", width: "100%" }}>
                <label>
                  Working Since
                  <input
                    style={{ marginBottom: "10px" }}
                    required
                    type="date"
                    name="working_since"
                    value={userData.working_since}
                    onChange={handleInputChange}
                    placeholder="Working on"
                    disabled={!isEditable}
                  />
                </label>
                <label>
                  Employee ID
                  <input
                    required
                    type="text"
                    name="userid"
                    value={userData.userid}
                    onChange={handleInputChange}
                    placeholder="userid"
                    disabled={!isEditable}
                  />
                </label>
              </span>
            </span>
          </div>

          <label>
            Image
            <input
              style={{ marginBottom: "10px" }}
              required
              type="file"
              accept="image/*"
              onChange={handleInputChange}
              placeholder="Image"
              name="image"
            />
          </label>

          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <span style={{ width: "50%" }}>
              <label>
                Phone Number
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  value={userData.phone_number}
                  onChange={handleInputChange}
                  type="number"
                  name="phone_number"
                  placeholder="Phone Number"
                />
              </label>
              <label>
                Date of Birth
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="date"
                  name="dob"
                  value={userData.dob}
                  onChange={handleInputChange}
                  placeholder="Date of Birth"
                />
              </label>
              <label>
                Designation
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="text"
                  name="role"
                  value={userData.role}
                  onChange={handleInputChange}
                  placeholder="role"
                />
              </label>
              <label>
                Gender
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="text"
                  name="gender"
                  value={userData.gender}
                  onChange={handleInputChange}
                  placeholder="Gender"
                />
              </label>
            </span>

            <span style={{ width: "50%" }}>
              <label> Blood Group </label>
              <select
                required
                style={{ marginBottom: "10px" }}
                name="blood_group"
                value={userData.blood_group}
                onChange={handleInputChange}
                placeholder="Blood Group"
              >
                {bloodGroupOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label>
                Reporting to
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="text"
                  name="reporting_to"
                  value={userData.reporting_to}
                  onChange={handleInputChange}
                  placeholder="Reporting Manager"
                />
              </label>
              <label>
                Marital Status
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="text"
                  name="marital_status"
                  value={userData.marital_status}
                  onChange={handleInputChange}
                  placeholder="Marital Status"
                />
              </label>
              <label>
                Project Name
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="text"
                  name="project_name"
                  value={userData.project_name}
                  onChange={handleInputChange}
                  placeholder="Project Name"
                />
              </label>
            </span>
          </div>
          {loggedInadmin ? (
            <div style={{ display: "flex", gap: "10px", width: "100%" }}>
              <span style={{ width: "50%" }}>
                <label>
                  casual leaves
                  <input
                    style={{ marginBottom: "10px" }}
                    required
                    type="text"
                    name="casual_leave"
                    value={userData.casual_leave}
                    onChange={handleInputChange}
                    placeholder="casual leave"
                  />
                </label>
                <label>
                  Earned leaves
                  <input
                    style={{ marginBottom: "10px" }}
                    required
                    type="text"
                    name="earned_leave"
                    value={userData.earned_leave}
                    onChange={handleInputChange}
                    placeholder="earned leave"
                  />
                </label>
              </span>
              <span style={{ width: "50%" }}>
                <label>
                  Sick leaves
                  <input
                    style={{ marginBottom: "10px" }}
                    required
                    type="text"
                    name="sick_leave"
                    value={userData.sick_leave}
                    onChange={handleInputChange}
                    placeholder="sick leave"
                  />
                </label>
                <label>
                  Rating
                  <input
                    style={{ marginBottom: "10px" }}
                    required
                    type="text"
                    name="rating"
                    value={userData.rating}
                    onChange={handleInputChange}
                    placeholder="rating"
                  />
                </label>
              </span>
            </div>
          ) : null}

          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
            <span style={{ width: "50%" }}>
              <label>
                Facebook Link
                <input
                  type="text"
                  name="facebook_link"
                  value={userData.facebook_link || ""}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Instagram Link
                <input
                  type="text"
                  name="insta_link"
                  value={userData.insta_link || ""}
                  onChange={handleInputChange}
                />
              </label>
            </span>
            <span style={{ width: "50%" }}>
              <label>
                LinkedIn Link
                <input
                  type="text"
                  name="linkedin_link"
                  value={userData.linkedin_link || ""}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Twitter Link
                <input
                  type="text"
                  name="twitter_link"
                  value={userData.twitter_link || ""}
                  onChange={handleInputChange}
                />
              </label>
            </span>
          </div>

          <br />

          <button
            onClick={handleFormSubmit}
            style={{
              width: "90%",
              marginLeft: "5%",
              padding: "10px",
              color: "white",
              backgroundColor: "navy",
              borderRadius: "10px",
              cursor: hasChanges ? "pointer" : "not-allowed",
            }}
            title={hasChanges ? "Save changes" : "No changes to save"}
            disabled={!hasChanges}
          >
            Edit Profile
          </button>
        </div>
      </div>
    </>
  );
};

export default EditProfile;