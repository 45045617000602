import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./UserLogin.css";
import Navbar from "../../section/navbar/Navbar";

const Adminlogin = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState(""); // New state for login error
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);

  // useEffect(() => {
  //   const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/; // General email validation
  //   const outlookRegex = /^[A-Za-z0-9._%+-]+@outlook\.com$/i; // Outlook-specific validation

  //   if (!emailRegex.test(email) && email.trim() !== "") {
  //     setEmailError("Invalid email format");
  //   } else {
  //     setEmailError("");
  //   }
  // }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (name !== "Hola9" || email !== "Hola9" || password !== "H9@login169") {
      setLoginError("Wrong credentials. Please try again.");
      setIsProcessing(false);
      return; // Exit the function early if credentials are incorrect
    }

    try {
      const response = await fetch("https://hrmsadmin.hola9.com/account/adminAuth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: email, // Assuming username is the email entered
          password: password,
          organization: name,
        }),
      });

      const result = await response.json();

      if (result === false) {
        // Successful login, redirect to another page
        localStorage.setItem("loggedInadmin", JSON.stringify({ email, password, name }));
        localStorage.setItem('selectedColor', 'linear-gradient(to right, #3498db, #2980b9)');
        alert("Admin Login Successful");
        navigate(`/AdminDashboard/${email}`); // Redirect to Admin Dashboard
      } else {
        // Login failed, display an error message
        setLoginError("Invalid username or password");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Set the processing state to false when the login request is complete (success or failure)
      setIsProcessing(false);
    }
  };

  // Check if the user is already logged in (stored in localStorage)
  useEffect(() => {
    const loggedInUser = localStorage.getItem("loggedInadmin");
    if (loggedInUser) {
      const { email, name } = JSON.parse(loggedInUser);
      navigate(`/AdminDashboard/${email}`);
    }
  }, []);

  // Function to handle logout
  const handleLogout = () => {
    // Remove user details from localStorage
    localStorage.removeItem("loggedInUser");
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">
        {loginError && <div className="error">{loginError}</div>} {/* Display login error */}
        <div className="form-group">
          <label htmlFor="name" className="lab" />
          <input
            type="text"
            placeholder="Organization"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className='input-field'
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="lab" />
          <input
            type="text"
            placeholder="Username"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-field"
          />
        </div>
        {emailError && <div className="error">{emailError}</div>}
        <div className="form-group">
          <label htmlFor="password" className="lab" />
          <input
            type="password"
            placeholder="* * * * * *"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="input-field"
          />
        </div>
        <button className="button1" type="submit">
          {isProcessing ? "Processing..." : "Login"}
        </button>
      </form>
    </>
  );
};

export default Adminlogin;
