import React from "react";

const ProjectsInHome = () => {
  const storedColor = localStorage.getItem("selectedColor");

  const data = [
    {
      id: "1",
      image: "https://tse2.mm.bing.net/th?id=OIP.0IFjen8S_UDqMfnUYG4aBwHaEK&pid=Api&P=0&h=180",
      project: "Hola9"
    },
    {
      id: "2",
      image: "https://tse3.mm.bing.net/th?id=OIP.FVhtAhlILmVdeNgkoy1tpQHaE8&pid=Api&P=0&h=180",
      project: "Matrimony"
    },
    {
      id: "3",
      image: "https://tse4.mm.bing.net/th?id=OIP.zSsmaZAVMgb0f0Q8NflnCQHaEK&pid=Api&P=0&h=180",
      project: "DatingApp"
    },
    {
      id: "4",
      image: "https://tse4.mm.bing.net/th?id=OIP.zSsmaZAVMgb0f0Q8NflnCQHaEK&pid=Api&P=0&h=180",
      project: "Realestate"
    },
    {
      id: "5",
      image: "https://tse4.mm.bing.net/th?id=OIP.zSsmaZAVMgb0f0Q8NflnCQHaEK&pid=Api&P=0&h=180",
      project: "Services"
    },
    {
      id: "6",
      image: "https://tse4.mm.bing.net/th?id=OIP.zSsmaZAVMgb0f0Q8NflnCQHaEK&pid=Api&P=0&h=180",
      project: "Hola9emp"
    },
    {
      id: "7",
      image: "https://tse4.mm.bing.net/th?id=OIP.zSsmaZAVMgb0f0Q8NflnCQHaEK&pid=Api&P=0&h=180",
      project: "Jobportal"
    },
  ];


  return (
    <>
      <div
        style={{
          display: "flex",
          height: "400px",
          gap: "3%",
          marginTop: "100px",
          width: '96.5%'
        }}
      >
        <span
          style={{
            width: "170px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: "2%",
          }}
        >
          <h2 style={{ textAlign: "right" }}>Our</h2>
          <h2 style={{ textAlign: "right" }}>Projects</h2>
          <br />
          <b style={{ textAlign: "right" }}>
            Explore <i className="fa fa-arrow-circle-right"></i>
          </b>
        </span>


        <div style={{ width: '80%', height: '100%', overflow: 'scroll', display: 'grid', gap: '2%', gridTemplateColumns: 'repeat(8, 1fr)' }}>

          {data.map((sm, index) => (
            <div
              key={`${sm.id}-${index}`}
              style={{
                width: "230px",
                height: "100%",
                background: storedColor,
                borderRadius: "10px",
              }}
            >
              <img
                src={sm.image}
                style={{ width: "100%", height: "47.5%", borderRadius: "10px 10px 0 0", }}
                alt="project logo"
              />
              <h5 style={{ width: "100%", height: "5%", color: 'whitesmoke', padding: '10px' }}>{sm.project}</h5>
              <p style={{ width: "100%", height: "35%", color: 'rgba(240, 240, 240, 0.5)', padding: '10px', fontSize: '80%' }}>Project Description dsfdios odjofj dsfjsojs maefrewj4 ;opjfer efwoinedsi iofrfl erer fdo j ewojiojoo oojooigjpog eroip reoio  oi ro</p>
              <p
                style={{
                  width: "100%",
                  height: "10%",
                  background: "rgba(0, 0, 0, 0.2)",
                  width: "97%",
                  margin: "auto auto 1px  auto",
                  borderRadius: "0 0 10px 10px",
                  padding: "10px",
                  color: 'white'
                }}
              >
                <i className="fa fa-arrow-circle-right"></i> &nbsp; Explore Project
              </p>
            </div>
          ))}
        </div>


      </div>
    </>
  );
};

export default ProjectsInHome;
