import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AddUserDataTable.css';

const AddUserDataTable = ({ refreshData, setRefreshData }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [refreshData]); // Trigger fetch on refreshData change

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://hrmsadmin.hola9.com/account/adduser/');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://hrmsadmin.hola9.com/account/adduser/${id}`);
      setRefreshData((prev) => !prev); // Trigger refresh without reloading
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="loading-container">
          <img src="https://cdn.dribbble.com/users/902865/screenshots/4814970/loading-opaque.gif" alt="Loading..." />
        </div>
      ) : (
        <>
          <h2>User Data</h2>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Organization</th>
                <th>Username</th>
                <th>Password</th>
                <th>Role</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.organization}</td>
                  <td>{user.username}</td>
                  <td>{user.password}</td>
                  <td>{user.roles}</td>
                  <td><button onClick={() => handleDelete(user.id)}>Delete</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AddUserDataTable;
