import React, { useState, useEffect, useRef } from "react";
import "./Loginpro.css";
import Navbar from "../../section/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import ProfileForm from "../Dashboard/Profile";
import axios from "axios";
import { ProfileEditByAdmin } from "../Dashboard/EditProfile";

const Loginpro = () => {
  // State variables

  const [userData, setUserData] = useState([]); // Holds user data fetched from API
  const [editUserId, setEditUserId] = useState(null); // Holds the user ID being edited
  const [editUserImage, setEditUserImage] = useState(null); // Holds the image being edited
  const [newImageFile, setNewImageFile] = useState(null); // Holds the new image file being selected
  const [loading, setloading] = useState(true); // Holds the new image file being selected
  const [loading1, setloading1] = useState(true); // Holds the new image file being selected
  const [itemsToLoad, setItemsToLoad] = useState(20); // Holds the number of items to load initially and increases when "Load More" is clicked

  //navigation code start for adimin login
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInadmin = localStorage.getItem("loggedInadmin");

    if (!loggedInadmin) {
      navigate("/AdminLogin");
    }
  }, []);
  //navigation code end for adimin login

  // useEffect hook to fetch data from API when the component mounts or when itemsToLoad changes

  // Function to fetch data from the API
  const fetchData = () => {
    setloading1(true);
    // Fetch data from the API using the specified limit
    fetch(
      `https://hrmsadmin.hola9.com/account/loginprofile/?limit=${itemsToLoad}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setloading(false);
        setloading1(false);
        // Set loading state to false when data is fetched
      })
      // Set the fetched data to the state
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    debouncedFetchData();
  }, [itemsToLoad]);
  const debouncedFetchData = _debounce(fetchData, 2000);

  // Function to handle the edit profile action
  const handleEditProfile = (userId, userImage) => {
    setEditUserId(userId); // Set the user ID being edited
    setEditUserImage(userImage); // Set the image being edited
  };

  // Function to handle the change of the selected image file
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    setNewImageFile(file); // Set the new image file to the state

    const reader = new FileReader(); // Create a FileReader to read the file
    reader.onloadend = () => {
      setEditUserImage(reader.result); // Set the image being edited to the result of reading the file
    };

    if (file) {
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  // Function to handle saving the edited image
  const handleSaveImageEdit = () => {
    // Check if a new image file was selected
    if (!newImageFile) {
      // No new image selected, return or show a message
      return;
    }

    const formData = new FormData(); // Create a FormData object to send the image file
    formData.append("userId", editUserId); // Append user ID to the form data
    formData.append("image", newImageFile); // Append the new image file to the form data

    // Make a POST request to update the image for a particular user ID on the server
    fetch(`https://hrmsadmin.hola9.com/account/loginprofile/${editUserId}/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(
          `Image updated successfully for user ID ${editUserId}:`,
          data
        );
        setEditUserId(null);
        setEditUserImage(null);
        setNewImageFile(null);
      })
      .catch((error) => {
        console.error("Error updating image:", error);
        // Handle error, show a message, etc.
      });
  };

  // Function to handle loading more items
  // Function to handle loading more items
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 20); // Increase the number of items to load
  };
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!loading) {
        const newDataLength = userData.length;

        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [loading, userData, prevDataLength]); // Add userData and loading as dependencies to useEffect

  // -------------------------------- Delete Profile ----------------------------------- //

  const handleDelete = (id) => {
    if (window.confirm(`Are you sure you want to delete the ${id} profile?`)) {
      axios
        .delete(`https://hrmsadmin.hola9.com/account/loginprofile/${id}/`)
        .then((response) => {
          console.log("Profile deleted successfully:", response.data);
          alert("Profile deleted successfully");
          setUserData((prevData) => prevData.filter((user) => user.id !== id));
          // window.location.reload();
        })
        .catch((error) => {
          console.error("Error deleting profile:", error);
          alert("Profile deletion failed");
        });
    }
  };

  // Function to add a new employee to the userData state
  const addNewEmployee = (newEmployee) => {
    setUserData((prevData) => [newEmployee, ...prevData]);
  };

  // JSX for rendering the component
  return (
    <>
      <Navbar />
      <div className="app123" style={{ marginTop: "50px" }}>
        <h3 style={{ color: "navy", marginBottom: "20px" }}>
          ✨Employee details ✨
        </h3>
        {loading ? (
          <center className="loading-indicator" style={{ padding: "50px" }}>
            <img
              src="https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif"
              alt="loading..."
            />
          </center>
        ) : (
          <div className="user-list">
            {userData.map((user) => (
              <div className="user-card" key={user.id}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <i
                    className="fa fa-user-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/EditProf/${user.name}`)}
                  />
                  <i
                    className="fa fa-trash"
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => handleDelete(user.id)}
                  />
                </div>
                <img
                  src={user.image}
                  alt="User"
                  className="user-image"
                  loading="lazy"
                />

                <div className="user-details">
                  <h2>{user.name}</h2>
                  <p>User ID: {user.userid}</p>
                  <p>Blood Group: {user.blood_group}</p>
                  <p>Date of Birth: {user.dob}</p>
                  {/* <button onClick={() => handleEditProfile(user.id, user.image_base64)}>
              Edit Image
            </button> */}
                  <Link
                    to={`/empdash/${user.name}`}
                    style={{ color: "black", float: "right" }}
                  >
                    view Profile
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* <button onClick={handleLoadMore}>Load More</button> */}
        {loading1 && (
          <p className="loading-message">
            <img
              src="https://i.pinimg.com/originals/2e/ce/ce/2ececec5431d0a1b7eae4e1acac7c59f.gif"
              alt="...loading"
            />
          </p>
        )}
        {/* Edit Image Modal */}
        {editUserId && (
          <div className="edit-modal">
            <h2>Edit Image</h2>
            <img src={editUserImage} alt="User" className="edit-image" />
            <input type="file" accept="image/*" onChange={handleImageChange} />
            <button onClick={handleSaveImageEdit}>Save Image</button>
          </div>
        )}
      </div>

      <ProfileForm addNewEmployee={addNewEmployee} />
    </>
  );
};

export default Loginpro;
