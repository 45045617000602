// import React from 'react'
import "./card.css"
const Card = ({children,className,onClick}) => {
    // const {}
const storedColor = localStorage.getItem('selectedColor');

  return (
    <article className={`card ${className}`} onClick={onClick} style={{background:storedColor}}>
      {children}
    </article>
  )
}

export default Card
