import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Chatboard.css";

const LeftSideDashboard = ({ onUserClick, users }) => {
  const storedColor = localStorage.getItem("selectedColor");
  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserClick = (userName) => {
    onUserClick(userName);
    setSelectedUser(userName);
  };

  return (
    <div className="left-side-dashboard">
      <h4
        className="left-side-dashboard-h4"
        style={{ background: `${storedColor}` }}
      >
        &nbsp; Chat
      </h4>
      <ul className="left-side-dashboard-ul">
        {users.map((user) => (
          <li
            className="left-side-dashboard-li"
            key={user.name}
            style={{
              background: selectedUser === user.name ? "whitesmoke" : null,
            }}
            onClick={() => handleUserClick(user.name)}
          >
            <img
              className="left-side-dashboard-img"
              src={user.image}
              alt={user.name}
            />
            {user.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Chatboard = () => {
  const storedColor = localStorage.getItem("selectedColor");

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name = loggedInUser
    ? loggedInUser.name
    : logintesting
      ? logintesting.name
      : loginbackend
        ? loginbackend.name
        : logintl
          ? logintl.name
          : logintltest
            ? logintltest.name
            : loginbackendtl
              ? loginbackendtl.name
              : null;


  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);

  const handleUserClick = (userName) => {
    setSelectedUser(userName);
    setSelectedUserName(userName);
  };

  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState({});

  // Update your handleSendMessage function
  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      const updatedMessages = {
        ...messages,
        [selectedUser]: [
          ...(messages[selectedUser] || []),
          { text: newMessage, sender: "user" },
        ],
      };
      setMessages(updatedMessages);
      setNewMessage("");
    }
  };


  // ------------------------------------------- this is for sender ------------------------------------------ //
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    fetch(`https://hrmsadmin.hola9.com/account/loginprofile/?name=${name}`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
      })
      .catch(() => { });
  }, []);


  // ----------------------- this is for receiver {all user details} ------------------------------------------ //
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://hrmsadmin.hola9.com/account/loginprofile/`
        );
        const apiData = await response.json();
        setData(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const names = data.map((item) => item.name);

  useEffect(() => {
    // Select the first user from the 'names' array if no user is initially selected
    if (!selectedUser && names.length > 0) {
      const initialUser = names[0];
      handleUserClick(initialUser);
    }
  }, [names, selectedUser]);


  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="chatboard">
      <LeftSideDashboard onUserClick={handleUserClick} users={data} />
      <div className="chatboard-container">
        <h4 className="chat-header" style={{ background: `${storedColor}` }}>
          {selectedUserName ? (
            <>
              {data.map(
                (user) =>
                  user.name === selectedUserName && (
                    <Link
                      to={`/empdash/${user.name}`}
                      key={user.name}
                      className="chat-Link"
                    >
                      <img
                        key={user.name}
                        src={user.image}
                        alt={user.name}
                        className="receiver-avatar"
                      />
                      {selectedUserName}
                    </Link>
                  )
              )}
            </>
          ) : (
            "Loading..."
          )}
        </h4>
        <div className="chat-messages">
          {messages[selectedUser]?.map((message, index) => (
            <div
              className="message-container"
              key={index}
              style={{
                justifyContent:
                  message.sender === "user" ? "flex-end" : "flex-start",
              }}
            >
              {message.sender === "receiver" && (
                <>
                  {data.map(
                    (user) =>
                      user.name === selectedUserName && (
                        <img
                          key={user.name}
                          src={user.image}
                          alt="Receiver"
                          className="message-container-img"
                        />
                      )
                  )}
                </>
              )}
              <div
                className="message-text"
                style={{
                  background: message.sender === "user" ? storedColor : "#eee",
                  color: message.sender === "user" ? "white" : "black",
                }}
              >
                {message.text}
              </div>
              <>
                {message.sender === "user" &&
                  userData.find((user) => user.name === name) &&
                  userData.map(
                    (user) =>
                      user.name === name && (
                        <img
                          key={user.name}
                          src={user.image}
                          alt="User"
                          className="message-container-img"
                        />
                      )
                  )}
              </>
            </div>
          ))}
        </div>
        <div className="input-container">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type your message....."
          />
          <div
            style={{ display: "flex", width: "100%", justifyContent: "right" }}
          >
            <button
              className="send-button"
              onClick={handleSendMessage}
              style={{ background: `${storedColor}` }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatboard;
