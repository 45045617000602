import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './PaySlip.css';
import { BiEdit, BiPrinter } from 'react-icons/bi';
import Navbar from '../../section/navbar/Navbar';

const EmployeesList = () => {
  const [employees, setEmployees] = useState([]);
  const [editEmployeeId, setEditEmployeeId] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [monthFilter, setMonthFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const [tasksToShow, setTasksToShow] = useState(20);
  const [cards, setcards] = useState(20);
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);

  useEffect(() => {
    fetchData(); // Function to fetch data from the API
  }, [tasksToShow]);
  //navigation code start for adimin login
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInadmin = localStorage.getItem("loggedInadmin");

    if (!loggedInadmin) {
      navigate("/AdminLogin");
    }
  }, []);
  //navigation code end for adimin login


  // Function to fetch data from the API
  const fetchData = () => {
    setLoading1(true); // Set loading state to false when data is fetched

    // Fetch data from the API using the specified limit
    fetch(`https://hrmsadmin.hola9.com/account/employees_joining/?limit=${tasksToShow}`)
      .then((response) => response.json())
      .then((data) => {
        setEmployees(data);
        setLoading(false); // Set loading state to false when data is fetched
        setLoading1(false); // Set loading state to false when data is fetched
      })
      // Set the fetched data to the state
      .catch((error) => console.error('Error fetching data:', error));
  };
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!loading && limitIncreaseCount != 3) {
        const newDataLength = employees.length;

        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [loading, employees, prevDataLength, limitIncreaseCount]);

  const handleLoadMore = () => {
    setTasksToShow(tasksToShow + 20);
    setLimitIncreaseCount((prevCount) => prevCount + 1);// Increase the number of items to load
  };
  const loadmorecards = () => {
    setcards(cards + 20);
    setLimitIncreaseCount(0);

  };

  const handleEditClick = (employeeId) => {
    setEditEmployeeId(employeeId);
  };

  const handleNameChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonthFilter(event.target.value);
  };

  const filteredEmployees = employees.filter((employee) => {
    return (
      employee.employeeName.toLowerCase().includes(nameFilter.toLowerCase()) &&
      employee.month.toLowerCase().includes(monthFilter.toLowerCase())
    );
  });

  return (
    <>
      <Navbar />
      <div className="employee-list-container">
        <div className="filter-section">
          <input
            type="text"
            placeholder="Filter by Name"
            value={nameFilter}
            onChange={handleNameChange}
          />
          <input
            type="text"
            placeholder="Filter by Month"
            value={monthFilter}
            onChange={handleMonthChange}
          />
        </div>
        {noDataFound && (
          <>
            <img
              src="https://i.pinimg.com/originals/49/e5/8d/49e58d5922019b8ec4642a2e2b9291c2.png"
              alt="no data found"
            />
            <Link to="/salaryslip">
              <button
                className="taskbtn2001"
                style={{
                  marginRight: '10px',
                  marginBottom: '20px',
                  padding: '10px 20px',
                  fontSize: '16px',
                  borderRadius: '5px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Generate Salary Slip
              </button>
            </Link>
          </>
        )}
        {loading ? (
          <img src="https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif" alt="loading..." />
        ) : (
          <>
            <div className="employee-list">
              {filteredEmployees.slice(0, cards).map((employee) => (
                <div key={employee.id} className="employee-card">
                  <div className="employee-info">
                    <h4>{employee.employeeName} ✨</h4>
                    <p>{employee.month}</p>
                  </div>
                  <div className="employee-actions">
                    <Link to={`/edit/${employee.id}`} onClick={() => handleEditClick(employee.id)}>
                      <button className="taskbtn2001">
                        <BiEdit /> Edit
                      </button>
                    </Link>
                    <Link to={`/Printslip/${employee.id}`} onClick={() => handleEditClick(employee.id)}>
                      <button className="print-button">
                        <BiPrinter /> Print Slip
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {loading1 && <p className="loading-message"> <img src='https://i.pinimg.com/originals/2e/ce/ce/2ececec5431d0a1b7eae4e1acac7c59f.gif' alt='...loading' /></p>}

        {!noDataFound && employees.length > 25 && (
          <button className="taskbtn2001" onClick={loadmorecards}>
            Load More
          </button>
        )}
      </div>
    </>
  );
};

export default EmployeesList;
