import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ServerIssue = () => {
  const navigate = useNavigate();

  const [responseText, setResponseText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/admin/login/?next=/admin/');

        // Extract and set the response status
        setResponseText(response.status); // Set status as responseText
        console.log('Full Response:', response);
        if (response.status !== 200) {
          navigate('/serviceunavailable');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle other errors if needed
      }
    };

    fetchData();
  }, [navigate]);

  // return (
  //   <div style={{marginTop:"60px"}}>
  //     <p>API Response:</p>
  //     <p>{responseText}</p> {/* Render responseText here */}
  //   </div>
  // );
};

export default ServerIssue;
