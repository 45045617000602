import React, { useEffect, useState } from "react";
import axios from "axios";
import { BiSave } from "react-icons/bi";
import Navbar from "../../section/navbar/Navbar";

const ProfileForm = (props) => {
  const { addNewEmployee } = props;
  const nam = props.name;


  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    blood_group: "",
    userid: "",
    dob: "",
    image: null,
    organization: "",
    phone_number: "",
    role: "",
    email: "",
    reporting_to: "",
    marital_status: "",
    gender: "",
    project_name: "",
    working_since: "",
    earned_leave: "",
    sick_leave: "",
    casual_leave: "",
    facebook_link: null,
    insta_link: null,
    linkedin_link: null,
    twitter_link: null,
    // rating: null
  });

  const [errors, setErrors] = useState({
    name: "",
    userid: "",
    // image: '',
    organization: "",
    // phone_number: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUserData({ ...userData, image: file });
  };
  useEffect(() => {
    // Fetch data from your API
    fetch(`https://hrmsadmin.hola9.com/account/loginprofile/?name=${nam}`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setLoading(false);
      });
  }, [nam]);

  const validateForm = () => {
    const newErrors = { ...errors };
    let isValid = true;

    if (userData.name === "") {
      newErrors.name = "*Name is required";
      isValid = false;
    } else {
      newErrors.name = "";
    }

    if (userData.userid === "") {
      newErrors.userid = "*Hola9 Id is required";
      isValid = false;
    } else {
      newErrors.userid = "";
    }

    if (userData.organization === "") {
      newErrors.organization = "*Organization is required";
      isValid = false;
    } else {
      newErrors.organization = "";
    }

    if (userData.email === "") {
      newErrors.email = "*Email is required";
      isValid = false;
    } else {
      newErrors.email = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);

      const apiUrl = "https://hrmsadmin.hola9.com/account/loginprofile/";
      // const apiUrl = 'https://hrmsadmin.hola9.com/account/loginprofi';

      const formData = new FormData();
      formData.append("name", userData.name);
      formData.append("userid", userData.userid);
      formData.append("blood_group", userData.blood_group);
      formData.append("dob", userData.dob);
      formData.append("image", userData.image);
      formData.append("phone_number", userData.phone_number);
      formData.append("organization", userData.organization);
      formData.append("role", userData.role);
      formData.append("email", userData.email);
      formData.append("reporting_to", userData.reporting_to);
      formData.append("marital_status", userData.marital_status);
      formData.append("gender", userData.gender);
      formData.append("working_since", userData.working_since);
      formData.append("earned_leave", userData.earned_leave);
      formData.append("sick_leave", userData.sick_leave);
      formData.append("casual_leave", userData.casual_leave);
      formData.append("gender", userData.gender);
      formData.append("project_name", userData.project_name);
      localStorage.setItem("image", userData.image);

      axios
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Data posted successfully:", response.data);
          // navigate(`/`);
          alert("Profile created successfully");
          // window.location.reload();
          addNewEmployee(response.data);
          setLoading(false);
          setUserData({
            name: "",
            blood_group: "",
            userid: "",
            dob: "",
            image: null,
            phone_number: "",
            organization: "",
            role: "",
            email: "",
            reporting_to: "",
            marital_status: "",
            gender: "",
            project_name: "",
            working_since: "",
            earned_leave: "",
            sick_leave: "",
            casual_leave: "",
            facebook_link: null,
            insta_link: null,
            linkedin_link: null,
            twitter_link: null,
          });
        })
        .catch((error) => {
          console.error("Error posting data:", error);
          alert("Profile created Faild");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const bloodGroupOptions = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  return (
    <>
      <Navbar />

      <div className="wholeform" style={{ marginTop: "80px" }}>
        <div className="profile-form">
          {/* Display the image if it exists */}
          {loading && (
            <div className="loading-overlay">
              <img
                src="https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif"
                alt="processing"
              />
            </div>
          )}
          <center>
            <h3>Add Employee</h3>
            {userData.image && (
              <img
                src={URL.createObjectURL(userData.image)}
                alt="User Image"
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            )}
          </center>
          <br />

          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
            <span style={{ width: "50%" }}>
              <input
                style={{ marginBottom: "10px" }}
                required
                type="text"
                name="name"
                value={userData.name}
                onChange={handleChange}
                placeholder="Name"
              />
              <p className="error-message">{errors.name}</p>
              <input
                style={{ marginBottom: "10px" }}
                required
                type="text"
                name="userid"
                value={userData.userid}
                onChange={handleChange}
                placeholder="Hola9 Id:H-10__"
              />
              <p className="error-message">{errors.userid}</p>
              <select
                required
                style={{ marginBottom: "10px" }}
                name="blood_group"
                value={userData.blood_group}
                onChange={handleChange}
                placeholder="Blood Group"
              >
                <option value="">Select Blood Group</option>
                {bloodGroupOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <p className="error-message">{errors.blood_group}</p>

              <label>
                Date of Birth
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="date"
                  name="dob"
                  value={userData.dob}
                  onChange={handleChange}
                  placeholder="Date of Birth"
                />
                <p className="error-message">{errors.dob}</p>
              </label>

              <label>
                Phone Number
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="number"
                  name="phone_number"
                  value={userData.phone_number}
                  onChange={handleChange}
                  placeholder="Phone Number"
                />
                {/* <p className="error-message">{errors.phone_number}</p> */}
              </label>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="text"
                name="organization"
                value={userData.organization}
                onChange={handleChange}
                placeholder="Organization"
              />
              <p className="error-message">{errors.organization}</p>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="text"
                name="role"
                value={userData.role}
                onChange={handleChange}
                placeholder="role"
              />
              <p className="error-message">{errors.role}</p>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="text"
                name="reporting_to"
                value={userData.reporting_to}
                onChange={handleChange}
                placeholder="Reporting Manager"
              />
              <p className="error-message">{errors.reporting_to}</p>
              <input
                style={{ marginBottom: "10px" }}
                required
                type="text"
                name="marital_status"
                value={userData.marital_status}
                onChange={handleChange}
                placeholder="Marital Status"
              />
              <p className="error-message">{errors.marital_status}</p>
            </span>

            <span style={{ width: "50%" }}>
              <input
                style={{ marginBottom: "10px" }}
                required
                type="email"
                name="email"
                value={userData.email}
                onChange={handleChange}
                placeholder="email"
              />
              <p className="error-message">{errors.email}</p>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="text"
                name="gender"
                value={userData.gender}
                onChange={handleChange}
                placeholder="Gender"
              />
              <p className="error-message">{errors.gender}</p>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="text"
                name="project_name"
                value={userData.project_name}
                onChange={handleChange}
                placeholder="Project Name"
              />
              <p className="error-message">{errors.project_name}</p>

              <label>
                Working Since
                <input
                  style={{ marginBottom: "10px" }}
                  required
                  type="date"
                  name="working_since"
                  value={userData.working_since}
                  onChange={handleChange}
                  placeholder="Working Since"
                />
                <p className="error-message">{errors.working_since}</p>
              </label>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                placeholder="Image"
                name="image"
              />
              <p className="error-message">{errors.image}</p>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="number"
                name="earned_leave"
                value={userData.earned_leave}
                onChange={handleChange}
                placeholder="Earned Leave"
              />
              <p className="error-message">{errors.earned_leave}</p>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="number"
                name="sick_leave"
                value={userData.sick_leave}
                onChange={handleChange}
                placeholder="Sick Leave"
              />
              <p className="error-message">{errors.sick_leave}</p>

              <input
                style={{ marginBottom: "10px" }}
                required
                type="number"
                name="casual_leave"
                value={userData.casual_leave}
                onChange={handleChange}
                placeholder="casual_leave"
              />
              <p className="error-message">{errors.casual_leave}</p>
            </span>
          </div>

          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
            <span style={{ width: "50%" }}>
              <label>
                Facebook Link
                <input
                  type="text"
                  name="facebook_link"
                  value={userData.facebook_link || ""}
                  onChange={handleChange}
                />
              </label>
              <label>
                Instagram Link
                <input
                  type="text"
                  name="insta_link"
                  value={userData.insta_link || ""}
                  onChange={handleChange}
                />
              </label>
            </span>
            <span style={{ width: "50%" }}>
              <label>
                LinkedIn Link
                <input
                  type="text"
                  name="linkedin_link"
                  value={userData.linkedin_link || ""}
                  onChange={handleChange}
                />
              </label>
              <label>
                Twitter Link
                <input
                  type="text"
                  name="twitter_link"
                  value={userData.twitter_link || ""}
                  onChange={handleChange}
                />
              </label>
            </span>
          </div>

          <br />

          <button
            onClick={handleSubmit}
            style={{
              padding: "10px",
              color: "white",
              backgroundColor: "navy",
              borderRadius: "10px",
              float: "right",
            }}
          >
            <BiSave /> Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileForm;
