import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MapLocationAds from "./MapLocationAds";
import { Link, useNavigate } from "react-router-dom";
import music from "../Dashboard/mixkit-rocket-ignition-flames-1725.wav";
import { FaDotCircle, FaStop } from "react-icons/fa";
import {
  BiDotsHorizontalRounded,
  BiLocationPlus,
  BiTime,
} from "react-icons/bi";
import { RiWifiOffLine } from "react-icons/ri";
import "./Logindet.css";
import Swal from "sweetalert2";
import { GiStopSign } from "react-icons/gi";
import { useSpeechSynthesis } from "react-speech-kit";

const LoginDetails = (props) => {
  const storedColor = localStorage.getItem('selectedColor');

  const name = props.name;
  const [isHovering, setIsHovering] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState();
  const [loginTime, setLoginTime] = useState("");
  const [location1, setLocation1] = useState("");
  const [lati, setLati] = useState("");
  const [longi, setLongi] = useState("");
  const [lati1, setLati1] = useState("");
  const [longi1, setLongi1] = useState("");
  const [logoutTime, setLogoutTime] = useState("");
  const [location2, setLocation2] = useState("");
  const [totalTime, setTotalTime] = useState(0);
  const [userId, setUserId] = useState(null);
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginPressed, setLoginPressed] = useState(false);
  const [internetConnectivity, setInternetConnectivity] = useState(true);
  const successMessage = `Success! ${name} Your Login successful.`;
  const successMessage2 = `Success! ${name} Your Logout successful.`;
  const successMessage3 = `wohoo ${name} Lunch done `;
  const failureMessage3 = `ohh ho ${name} Till Now you are ahving Lunch`;
  const failureMessage = `Failed! ${name} Your Login failed Please try again.`;
  const failureMessage2 = `Failed! ${name} Your Logout failed Please try again.`;

  const { speak, cancel, speaking } = useSpeechSynthesis();
  const [isReading, setIsReading] = useState(false);

  const getIndianAccentFemaleVoice = () => {
    const voices = window.speechSynthesis.getVoices();

    // Specify the voice URI for an Indian English female voice
    const indianAccentFemaleVoiceURI = "Google हिन्दी India Female";

    // Find the voice with the specified URI
    const indianAccentFemaleVoice = voices.find(
      (voice) => voice.voiceURI === indianAccentFemaleVoiceURI
    );

    // If the specified voice is not available, fall back to any female voice
    const femaleVoice = voices.find((voice) => voice.name.includes("female"));

    return indianAccentFemaleVoice || femaleVoice || voices[0]; // Use default voice if no suitable voice is found
  };

  const handleReadClick = (text) => {
    if (speaking) {
      cancel();
    } else {
      speak({
        text,
        voice: getIndianAccentFemaleVoice(),
        onEnd: () => setIsReading(false),
      });
    }
    setIsReading(!isReading);
  };
  const navigate = useNavigate();
  let timerInterval;

  const startTimer = () => {
    const startTime = localStorage.getItem("timerStartTime") || Date.now();
    timerInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      setTotalTime(elapsedTime / 1000);
    }, 1000);

    return timerInterval;
  };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return `${hours}h ${minutes}m ${seconds}s`;
  };
  const savedLoginStatus = localStorage.getItem("isLoggedIn");

  useEffect(() => {
    if (savedLoginStatus === "true") {
      const savedLoginTime = localStorage.getItem("loginTime");
      const savedLocation1 = localStorage.getItem("location1");
      const savedUserId = localStorage.getItem("userId");
      setIsLoggedIn(true);
      setLoginTime(savedLoginTime);
      setLocation1(savedLocation1);
      setUserId(savedUserId);
      setIsLoginDisabled(true);
      timerInterval = startTimer();
    }
  }, []);

  const handleLogin = () => {
    // setIsLoggedIn(false);
    if (navigator.onLine) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const timeInHours = currentHour + currentMinutes / 60;

      // Check if the current time is between 10:15 am and 2:15 pm
      if (timeInHours >= 10.50 && timeInHours <= 14.25) {
        alert(
          `Time over, ${name} can't log in now. You can login after 2:15 PM, but it will be counted as a HalfDay for ${name}`
        );
        return;
      } else {
        const formattedTime = currentTime.toLocaleTimeString();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const location = `${latitude},${longitude}`;

            setLoginTime(formattedTime);
            setLocation1(location);
            setIsLoginDisabled(true);
            setLati(latitude);
            setLongi(longitude);

            // Save login status and data to local storage

            if (userId) {
              localStorage.setItem("userId", userId);
            }

            // Start or resume the timer
            timerInterval = startTimer();
            localStorage.setItem("timerStartTime", Date.now());
            setIsLoading(true);
            axios
              .post("https://hrmsadmin.hola9.com/account/employeelogin2", {
                date: currentTime,
                name: name,
                login_time: formattedTime,
                longitude1: longitude,
                lattiude1: latitude,
              })
              .then((response) => {
                const retrievedUserId = response.data.id;
                localStorage.setItem("retrievedUserId", retrievedUserId);
                localStorage.setItem("isLoggedIn", "true");
                localStorage.setItem("loginTime", formattedTime);
                localStorage.setItem("location1", location);
                // localStorage.removeItem('lastLogoutTime');
                handleReadClick(successMessage);
                setUserId(retrievedUserId);
                console.log("Login data sent successfully:", response.data);
                // alert('Your login started successfully!');
                Swal.fire({
                  icon: "success",
                  title: "Hello Coder Your login started successfully!",
                  text: "done.",
                  confirmButtonColor: "green",
                });
                setIsLoggedIn(true);

                navigate("/");
              })
              .catch((error) => {
                setIsLoggedIn(false);
                handleReadClick(failureMessage);

                console.error("Error sending login data:", error);
                Swal.fire({
                  icon: "error",
                  title: "Failed to start login,Dear coder Our Server Down",
                  text: "Hello Coder check your internet once",
                  confirmButtonColor: "red",
                });
              })
              .finally(() => {
                setIsLoading(false); // Set loading to false when the request is completed
              });
          },
          (error) => {
            console.error("Error getting geolocation:", error);
            alert(
              "Geolocation data not available. Please allow location access."
            );
          }
        );
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Opps, Check your internet connect!",
        text: `${name}`,
        confirmButtonColor: "red",
      });
    }
  };

  const report = localStorage.getItem("namerepo");
  const Lunch = localStorage.getItem("Lunch");
  const handleLogout = () => {
    if (report) {
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleTimeString();

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const location = `${latitude},${longitude}`;

          setLogoutTime(formattedTime);
          setLocation2(location);
          setIsLoginDisabled(false);
          setLati1(latitude);
          setLongi1(longitude);

          // Clear login status and data from local storage

          const retrievedUserId = localStorage.getItem("retrievedUserId");

          // Remove the timer start time from local storage

          clearInterval(timerInterval);

          // Check if retrievedUserId is available
          if (retrievedUserId) {
            // Now, send the logout data using retrievedUserId directly
            setIsLoading(true);

            axios
              .put(
                `https://hrmsadmin.hola9.com/account/employeelogin2/${retrievedUserId}`,
                {
                  logout_time: formattedTime,
                  longitude2: longitude,
                  lattiude2: latitude,
                  total_time: `${Math.floor(totalTime / 60)}m ${Math.floor(
                    totalTime % 60
                  )}s`, // Send formatted time
                }
              )
              .then((response) => {
                console.log("Logout data sent successfully:", response.data);

                handleReadClick(successMessage2);
                Swal.fire({
                  icon: "info",
                  title: "!!Wooh Hoo Coder You have logged out successfully!",
                  text: "done.",
                  confirmButtonColor: "green",
                }).then((result) => {
                  if (result.isConfirmed) {
                    // If the user clicks "OK," refresh the window
                    setIsLoggedIn(false);
                    localStorage.removeItem("retrievedUserId");
                    localStorage.removeItem("timerStartTime");
                    localStorage.removeItem("isLoggedIn");
                    localStorage.removeItem("loginTime");
                    localStorage.removeItem("location1");
                    localStorage.removeItem("namerepo");
                    localStorage.removeItem("Lunch");

                    window.location.reload();
                  }
                });
              })
              .catch((error) => {
                console.error("Error sending logout data:", error);
                handleReadClick(failureMessage2);

                setIsLoggedIn(true);
                if (
                  error.response &&
                  error.response.status === 500 &&
                  error.response.status === 503
                ) {
                  // Handle 503 status code here
                  Swal.fire({
                    icon: "error",
                    title: "Server unavailable",
                    text: "Please try again later.",
                    confirmButtonColor: "red",
                  });
                } else {
                  Swal.fire({
                    icon: "warning",
                    title: "Wait!, Server Down tryagain in Sometime ",
                    text: "In sometime it will added automatically and you will get success Alert otherwise tryagain.",
                    confirmButtonColor: "yellow",
                  });
                }

                // Clear login status and data from local storage

                const retrievedUserId = localStorage.getItem("retrievedUserId");

                // Remove the timer start time from local storage

                // Continue the timer even if the logout request fails
                // clearInterval(timerInterval);
                // timerInterval = startTimer();
                // localStorage.setItem('timerStartTime', Date.now());

                // Check if retrievedUserId is available
                if (retrievedUserId) {
                  // Now, send the logout data using retrievedUserId directly
                  axios
                    .put(
                      `https://hrmsadmin.hola9.com/account/employeelogin2/${retrievedUserId}`,
                      {
                        logout_time: formattedTime,
                        longitude2: longitude,
                        lattiude2: latitude,
                        total_time: `${Math.floor(
                          totalTime / 60
                        )}m ${Math.floor(totalTime % 60)}s`, // Send formatted time
                      }
                    )
                    .then((response) => {
                      console.log(
                        "Logout data sent successfully:",
                        response.data
                      );

                      Swal.fire({
                        icon: "info",
                        title:
                          "!!Wooh Hoo Coder You have logged out successfully!",
                        text: `${name}`,
                        confirmButtonColor: "green",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // If the user clicks "OK," refresh the window
                          setIsLoggedIn(false);

                          localStorage.removeItem("retrievedUserId");
                          localStorage.removeItem("timerStartTime");
                          localStorage.removeItem("isLoggedIn");
                          localStorage.removeItem("loginTime");
                          localStorage.removeItem("location1");
                          localStorage.removeItem("namerepo");
                          localStorage.removeItem("Lunch");
                          window.location.reload();
                        }
                      });
                    })
                    .catch((error) => {
                      console.error("Error sending logout data again:", error);
                      setIsLoggedIn(true);
                      alert(
                        "Failed to log out. Your logout data may not be saved."
                      );
                    });
                }
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          alert("Location data not available. Please allow location access.");
          // You can handle this case here or leave it as is, depending on your requirement
        }
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: `${name} Please Submit Daily Reports `,
        text: `😠😠😠`, // Add angry emoji here
        confirmButtonColor: "red",
      });
      // .then((result) => {
      //     if (result.isConfirmed) {
      //         // Navigate to another page
      //         navigate(`/ProjectDetails/${name}`);
      //     }
      // });
    }
  };
  const [time1, setTime1] = useState('');
  const [time2, setTime2] = useState('');

  const handleTimeChange = (e, setTime) => {
    const value = e.target.value;
    setTime(value);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState('');

  const handleLunch = () => {

    if (!time1 || !time2) {
      setError('Please fill in both time fields.');
      return;
    }
    const retrievedUserId = localStorage.getItem("retrievedUserId");

    axios
      .put(
        `https://hrmsadmin.hola9.com/account/employeelogin2/${retrievedUserId}`,
        {
          lunch_break_checkin: time1 + "pm",
          lunch_break_checkout: time2 + "pm",

        }
      )
      .then((response) => {
        console.log("Logout data sent successfully:", response.data);

        // handleReadClick(successMessage3);
        setShowPopup(false);
        localStorage.setItem('Lunch', name);

        Swal.fire({
          icon: "info",
          title: "!!Ohh Ho,Lunch Done",
          text: "done.",
          confirmButtonColor: "green",
        })
      }).then((result) => {
        if (result.isConfirmed) {
          // If the user clicks "OK," refresh the window

          window.location.reload();
        }
      })
      .catch((error) => {

        // handleReadClick(failureMessage3);

        // Clear login status and data from local storage


      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const audioRef = useRef(null);

  const handleEnded = () => {
    // Pause the audio when it has ended
    audioRef.current.pause();
  };

  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();
  const timeInHours = currentHour + currentMinutes / 60;

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setInternetConnectivity(true);
    };

    const handleOfflineStatusChange = () => {
      setInternetConnectivity(false);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOfflineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOfflineStatusChange);
    };
  }, []);



  const emojiAnimationClass = {
    animation: "bounce 1s infinite", // You can adjust the animation properties
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const open = () => {
    setShowPopup(true);
  };
  const close = () => {
    setShowPopup(false);
  };

  // // Automatically logout functionally start
  //   if (timeInHours >= 20.30) {
  //     const retrievedUserId = localStorage.getItem("retrievedUserId");

  //     // Remove the timer start time from local storage
  //     clearInterval(timerInterval);

  //     // Check if retrievedUserId is available
  //     if (retrievedUserId) {
  //       // Now, send the logout data using retrievedUserId directly
  //       setIsLoading(true);

  //       axios
  //         .put(
  //           `https://hrmsadmin.hola9.com/account/employeelogin2/${retrievedUserId}`,
  //           {
  //             logout_time: "--",
  //             longitude2: "--",
  //             lattiude2: "--",
  //           }
  //         )
  //         .then((response) => {
  //           if (response) {
  //             // If the user clicks "OK," refresh the window
  //             setIsLoggedIn(false);
  //             localStorage.removeItem("retrievedUserId");
  //             localStorage.removeItem("timerStartTime");
  //             localStorage.removeItem("isLoggedIn");
  //             localStorage.removeItem("loginTime");
  //             localStorage.removeItem("location1");
  //             localStorage.removeItem("namerepo");

  //             window.location.reload();
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error logging out:", error);
  //           Swal.fire({
  //             icon: "error",
  //             title: "Failed to log out",
  //             text: "Please try again later.",
  //             confirmButtonColor: "red",
  //           });
  //         })
  //         .finally(() => {
  //           setIsLoading(false);
  //         });
  //     }
  //   }
  // Automatically logout functionally end
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "30px",
        }}
      >
        <button
          className="taskbtn2001 taskbtn2001-CO"
          id="logbtn234"
          style={{
            margin: "0",
            minWidth: "150px",
            background: savedLoginStatus ? "gold" : timeInHours < 10.50 && timeInHours > 14.25 ? "#cccccc" : storedColor,
            cursor: !savedLoginStatus && timeInHours >= 10.50 && timeInHours <= 14.25 ? "not-allowed" : 'pointer',
            // cursor:"not-allowed"

          }}
          // {savedLoginStatus || (timeInHours < 10.5 || timeInHours > 14.25) ? disabled :null }

          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={savedLoginStatus ? timeInHours >= 8.00 && timeInHours < 15.50 ? (!Lunch ? open : handleLogout) : handleLogout : handleLogin}
          title={savedLoginStatus ? '' : (isHovering && timeInHours >= 10.0 && timeInHours <= 10.50) ? "Check-In window closing soon!" : (isHovering && timeInHours >= 10.50 && timeInHours <= 14.25) ? "You Can't Check-in Now" : 'Check-In Now'}
        >
          {
            savedLoginStatus ?
              (isHovering ?
                ((timeInHours >= 8.00 && timeInHours < 15.50) ? (!Lunch ? "Lunch-Break" : "Check-Out") : "Check-Out") :
                formatTime(totalTime)
              ) :
              "Check-In"}
        </button>
      </div>
      {showPopup && (
        <div className='popup'>
          <div>
            <span style={{ float: 'right', height: '0', width: '100%', display: 'flex', justifyContent: 'right' }}><span onClick={close} style={{ cursor: 'pointer', position: 'absolute' }}>❌</span></span>
            <h3 style={{ textAlign: 'center', background: storedColor, backgroundClip: 'text', color: 'transparent', marginTop: '-20px' }}>Approval Request</h3>

            <div style={{ display: 'flex', width: '100%', gap: '2%' }}>

              <div style={{ width: '50%' }}>
                <input
                  type="time" required
                  value={time1}
                  onChange={(e) => handleTimeChange(e, setTime1)}
                  step="1800"
                /></div>
              <div style={{ width: '50%' }}>
                <input
                  type="time"
                  value={time2} required
                  onChange={(e) => handleTimeChange(e, setTime2)}
                  step="1800"
                />
              </div>
            </div>


            {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

            <button type="button" onClick={handleLunch} style={{ background: storedColor, width: '100%', color: 'Navy' }}>Submit</button>
          </div></div>)}
      {isLoading && (
        <div className="loading-overlay">
          <img
            src="https://global.discourse-cdn.com/business7/uploads/streamlit/original/2X/2/247a8220ebe0d7e99dbbd31a2c227dde7767fbe1.gif"
            alt="processing"
          />{" "}
          <audio
            ref={audioRef}
            autoPlay
            onEnded={handleEnded}
            style={{ display: "none" }}
          >
            <source src={music} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>{" "}
        </div>
      )}
    </>
  );
};

export default LoginDetails;

// {Previous code }

{
  /* <div className="logby">
          {savedLoginStatus ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                border: "1px solid blue",
                padding: "2px",
                borderRadius: "10px",
                background: "skyblue",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  border: "1px groove  rgb(9, 33, 68)",
                  padding: "15px",
                  fontFamily: "Arial, sans-serif",
                  borderRadius: "10px",
                  fontSize: "14px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#f5f5f5",
                  // marginTop:"-30px",
                  zIndex: 6,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontSize: "120%",
                    marginRight: "10px",
                    ...emojiAnimationClass,
                    fontWeight: "bold",
                  }}
                >
                  Check-In 🎉✨🚀
                </p>
                <p
                  style={{ color: "red", fontWeight: "bold", fontSize: "150%" }}
                >
                  {name}{" "}
                  {internetConnectivity ? (
                    <FaDotCircle
                      style={{
                        color: "limegreen",
                        fontSize: "70%",
                        marginBottom: "-2px",
                      }}
                      title="You are online"
                    />
                  ) : (
                    <RiWifiOffLine
                      style={{ color: "red", fontSize: "80%" }}
                      title="You are offline"
                    />
                  )}
                </p>
                {/* <p style={{ marginBottom: '5px' }}>Location: {location1}</p> 

                <p>
                  <a
                    href={
                      "https://www.google.com/maps/search/?api=1&query=" +
                      location1
                    }
                    target="blank"
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      style={{
                        border: "1px solid navy",
                        padding: "8px 15px",
                        backgroundColor: "navy",
                        color: "white",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "10px",
                      }}
                    >
                      <BiLocationPlus style={{ marginRight: "5px" }} />
                      See On Map
                    </button>
                  </a>
                </p>
                <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
                  Check-in:<span> {loginTime}</span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Total Time:{" "}
                  <span className="blinking modern-color">
                    {formatTime(totalTime)}
                  </span>
                </p>
                {/* <GiStopSign/> 
                <button
                  // class="big-button1"
                  className="taskbtn2001"
                  style={{argin:'0', minWidth:'150px' }}
                  onClick={handleLogout}
                >
                  Stop
                </button>
              </div>

              <div></div>
            </div>
          ) : ( 
 <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // marginTop: "60px",
            }}
          >
            {timeInHours >= 10.0 && timeInHours <= 10.5 ? (
              // {timeInHours >= 12.0 && timeInHours <= 24.00 ? (

              <>
                <p
                  style={{
                    color: "rgb(9, 33, 68)",
                    fontSize: "70%",
                    fontFamily: "monospace",
                    color: "red",
                  }}
                >
                  Login window closing soon!
                </p>
                {/* <img src='https://w7.pngwing.com/pngs/765/464/png-transparent-angry-boss-thumbnail.png' alt='angry'/> 
              </>
            ) : null}
            {timeInHours >= 10.5 && timeInHours <= 14.25 ? (
              <p
                style={{
                  color: "rgb(9, 33, 68)",
                  fontSize: "75%",
                  fontFamily: "monospace",
                  color: "red",
                }}
              >
                You Can't login Now
                {/* <img
        src="https://i.pinimg.com/originals/64/5b/80/645b805021f132cd428ccc11bf6d43c9.gif"
        alt="Dangerous GIF"
        style={{ width: "60px", height: "60px", marginLeft: "5px" }}
      /> 
              </p>
            ) : null}

            {/* <p>{location2}</p> 

            <div></div>
          </div>
        </> */}