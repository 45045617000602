import React, { useRef, useState } from 'react';
import './ProjectDetails.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BiSolidDashboard } from "react-icons/bi"
import axios from 'axios';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Navbar from '../../section/navbar/Navbar';
import music from './mixkit-coin-win-notification-1992.wav';
import { useSpeechSynthesis } from 'react-speech-kit';
import Swal from 'sweetalert2';

const ProjectDetails = (props) => {
  const id = props.name;

  const storedColor = localStorage.getItem('selectedColor');

  const [startDate, setStartDate] = useState(null);
  const [id1, setid] = useState(id);
  const [task, settask] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = useState('');
  const [selectedRadio, setSelectedRadio] = useState('');
  const [comment, setcomment] = useState('');
  const [formError, setFormError] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
  const [loading, setLoading] = useState(false); // State to control loading overlay

  const [isLoading1, setIsLoading1] = useState(true);
  const successMessage = `Success! ${id} Your Report Submited successfully.`;
  const failureMessage = `Failed! ${id} Your Report Submit failed Please try again.`;

  const { speak, cancel, speaking } = useSpeechSynthesis();
  const [isReading, setIsReading] = useState(false);

  const getIndianAccentFemaleVoice = () => {
    const voices = window.speechSynthesis.getVoices();

    // Specify the voice URI for an Indian English female voice
    const indianAccentFemaleVoiceURI = 'Google हिन्दी India Female';

    // Find the voice with the specified URI
    const indianAccentFemaleVoice = voices.find(voice => voice.voiceURI === indianAccentFemaleVoiceURI);

    // If the specified voice is not available, fall back to any female voice
    const femaleVoice = voices.find(voice => voice.name.includes('female'));

    return indianAccentFemaleVoice || femaleVoice || voices[0]; // Use default voice if no suitable voice is found
  };


  const handleReadClick = (text) => {
    if (speaking) {
      cancel();
    } else {
      speak({
        text,
        voice: getIndianAccentFemaleVoice(),
        onEnd: () => setIsReading(false),
      });
    }
    setIsReading(!isReading);
  };
  const handleDropdownChange = (e) => {
    setSelectedDropdown(e.target.value);
  };

  // Function to handle radio button value change
  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const resetForm = () => {
    setid(id);
    settask('');
    setStartDate(null);
    setSelectedDropdown('');
    setSelectedRadio('');
    setcomment('');
    setSelectedFile(null);
    setFormError('');
    setUploadProgress(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !id1 ||
      !task ||
      !startDate ||
      !selectedDropdown ||
      !selectedRadio ||
      !comment ||
      !selectedFile
    ) {
      setFormError('Please fill out all required fields.');
      return;
    }

    setLoading(true); // Show loading overlay

    const formData = new FormData();
    formData.append('name', id1);
    formData.append('task', task);
    formData.append('task_date', startDate);
    formData.append('report_of_work', selectedDropdown);
    formData.append('completion_status', selectedRadio);
    formData.append('comment', comment);
    formData.append('documnet', selectedFile);

    try {
      await axios.post('https://hrmsadmin.hola9.com/account/detailEmployee/', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      console.log('Data sent successfully');
      setShowSuccessPopup(true);
      resetForm();
      handleReadClick(successMessage);
      localStorage.setItem('namerepo', id);
      Swal.fire({
        icon: 'success',
        title: 'Hello Coder Your Reaport submitted successfully!',
        text: 'done.',
        confirmButtonColor: 'green',
      }).then((result) => {
        if (result.isConfirmed) {
          // Navigate to another page
          navigate(`/`);
        }
      });

    } catch (error) {
      console.error('Error sending data:', error);
      handleReadClick(failureMessage);

    } finally {
      setLoading(false); // Hide loading overlay regardless of success or failure
    }
  };
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    // Fetch data from your API
    fetch(`https://hrmsadmin.hola9.com/account/loginprofile/?name=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setIsLoading1(false);
      })
      .catch(() => {
      });
  }, []);

  const tlNames = userData.filter((item) => item.name).map((item) => item.name === id ? item.userid : null);

  const navigate = useNavigate();
  useEffect(() => {
    const loggedInUser = localStorage.getItem("loggedInUser");
    const logintl = JSON.parse(localStorage.getItem("logintl"));
    const logintesting = JSON.parse(localStorage.getItem("logintesting"));
    const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
    const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
    const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
    if (!loggedInUser && !logintl && !logintesting && !logintltest && !loginbackend && !loginbackendtl) {
      navigate("/UserLogin");
    }
  }, []);
  const getImageMIMEType = (imageData) => {
    // Detect the MIME type based on the image data
    if (imageData.startsWith('data:image/png')) {
      return 'image/png';
    } else if (imageData.startsWith('data:image/jpeg') || imageData.startsWith('data:image/jpg')) {
      return 'image/jpeg';
    } else if (imageData.startsWith('data:image/gif')) {
      return 'image/gif';
    }
    // Add more formats as needed

    return 'image/png'; // Default to PNG
  };

  const audioRef = useRef(null);

  const handleEnded = () => {
    // Pause the audio when it has ended
    audioRef.current.pause();
  };
  return (
    <>
      <Navbar />
      <br /><br />
      {/* <div className="project-details-container"> */}
      {/* <main className="main-content"> */}
      {/* <div className="glassmorphism-background"> */}
      {/* <div className="heading">
            <div className="green-bar" />
            <h1 id="title" className="main-heading" style={{ display: 'flex', justifyContent: 'center' }}>
            <span style={{color: "red"}}>E</span>mployee
            </h1>
            <div className='maindi'>
            <div >
            <div className='dashboradIcon2001'>
              <p id="description" className="main-description">
                Hola9 Classifieds India Private Ltd.
              </p>
              
            </div> 
            <label htmlFor="name" id="name-label">
              Name : <span className="required" style={{color:"rgb(0, 183, 255)"}}>{id}</span>
            </label>
            <br/>
            <label htmlFor="name" id="name-label">
              ID : <span className="required" style={{color:"rgb(0, 183, 255)"}}>{tlNames}</span>
            </label>
            </div>
            <div  className='imgcont'>
           
              {isLoading1 ? ( // Render loading indicator if data is still loading
        <center className="loading-indicator">Loading...</center>
      ) : (
        <>
            {userData.find((user) => user.name === id) ? (
      userData.map((user) => user.name === id && (
        <>
          <div className="card-body" style={{ textAlign: "center" }}>
           
            <img
              src={`data:${getImageMIMEType(user.image_base64)};base64,${user.image_base64}`}
              alt="User"
              style={{ borderRadius: "50%", height: "100px", width: "100px" }}
            />
          </div>

        </>
      ))
    ) : (
      <div className="card-body" style={{ textAlign: "center" }}>
        <Link to={`/profile/${id}`}>
          <Button>Add Profile</Button>
        </Link>
      </div>
    )}</>
    )}
    
         
              </div>
              </div>
            <hr />
          </div> */}
      {/* <form onSubmit={handleSubmit} className="survey-form"> */}
      {/* <label htmlFor="name" id="name-label">
              Name<span className="required">*</span>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={id1}
              className="name"
              placeholder={id}
              required // Add the required attribute
              onChange={(e) => setid(e.target.value)}
              readOnly
            /> */}
      {/* <label htmlFor="task" id="task-label">
              Task
            </label>
            <input
              type="text"
              name="task"
              id="task"
              className="task"
              placeholder="Enter your Task"
              required // Add the required attribute
              onChange={(e) => settask(e.target.value)}
            />
            <label>Select a date:</label>
            <div>
              <span>Date</span>
              <input
                type="date"
                placeholder="From Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <label htmlFor="dropdown" id="dropdown-label">
              Report of Work?
            </label>
            <select
              name="dropdown"
              id="dropdown"
              className="dropdown"
              required // Add the required attribute
              value={selectedDropdown}
              onChange={handleDropdownChange}
            >
              <option value="">Select an option</option>
              <option value="Daily">Daily</option>
              <option value="2_days">2 days</option>
              <option value="3_days">3 days</option>
              <option value="Weekly">Weekly</option>
            </select>
            <p className="radio-btn-description">
              How much complete work?
            </p>
            <div style={{ display: 'flex', justifyContent: 'start', paddingRight: '20px' }}>
              <label className="radio-btn-label" htmlFor="25-percent">
                <input
                  type="radio"
                  id="25-percent"
                  name="completion-status"
                  className="completion-status"
                  value="25%"
                  checked={selectedRadio === '25%'}
                  onChange={handleRadioChange}
                  required // Add the required attribute
                />{' '}
                25%
              </label>
              <label className="radio-btn-label" htmlFor="50-percent">
                <input
                  type="radio"
                  id="50-percent"
                  name="completion-status"
                  className="completion-status"
                  value="50%"
                  checked={selectedRadio === '50%'}
                  onChange={handleRadioChange}
                  required // Add the required attribute
                />{' '}
                50%
              </label>
              <label className="radio-btn-label" htmlFor="75-percent">
                <input
                  type="radio"
                  id="75-percent"
                  name="completion-status"
                  className="completion-status"
                  value="75%"
                  checked={selectedRadio === '75%'}
                  onChange={handleRadioChange}
                  required // Add the required attribute
                />{' '}
                75%
              </label>
              <label className="radio-btn-label" htmlFor="100-percent">
                <input
                  type="radio"
                  id="100-percent"
                  name="completion-status"
                  className="completion-status"
                  value="100%"
                  checked={selectedRadio === '100%'}
                  onChange={handleRadioChange}
                  required // Add the required attribute
                />{' '}
                100%
              </label>
            </div>
            <label>File Upload</label>
            <input
              type="file"
              className="col-lg-12"
              accept=".zip, .jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .mp4, .avi, .mkv, .mov, .wmv"
              onChange={handleFileChange}
              required // Add the required attribute
            />
           {loading && (
              <div className="loading-overlay">
                <img src="https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif" alt='processing'/>
              </div>
            )}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
                  {uploadProgress}%
                </div>
              </div>
            )}
            <label htmlFor="comments">Any comments or suggestions?</label>
            <textarea
              name="comments"
              id="comments"
              cols={30}
              required
              rows={5}
              placeholder="Enter your suggestions here"
              defaultValue={''}
              onChange={(e) => setcomment(e.target.value)}
            />
            {formError && <div className="error" style={{ color: 'red' }}>{formError}</div>}
            {showSuccessPopup && (
              <>
              <p className="success-message" style={{ color: 'green' }}>
                Your data was submitted successfully!
              </p>
              <audio ref={audioRef} autoPlay onEnded={handleEnded} style={{ visibility: 'hidden' }}>
      <source src={music} type="audio/mp3" />
      Your browser does not support the audio element.
    </audio>
            </>
            )}
            <center>
            <button className='submitbtn2001' type="submit" disabled={showSuccessPopup || loading || uploadProgress > 0}>
              Submit
            </button>
            </center>
            {/* <button class="button button5">Black</button> *
          </form> */}
      {/* </div> */}
      {/* </main> */}
      {/* </div> */}





      <form onSubmit={handleSubmit} className="survey-form" style={{ width: '900px', boxShadow: '0px 0.5px 2px', padding: '30px', borderRadius: '10px' }}>

        <h3 style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'sans-serif', background: storedColor, color: 'transparent', backgroundClip: 'text' }}>Submit your Work Report</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ width: '47%' }}>
            <label htmlFor="task" id="task-label">
              Task Description
            </label>
            <input
              type="text"
              name="task"
              id="task"
              className="task"
              placeholder="Enter your Task"
              required // Add the required attribute
              onChange={(e) => settask(e.target.value)}
            />
          </span>
          <span style={{ width: '47%' }}>
            <label htmlFor="dropdown" id="dropdown-label">
              Report of Work?
            </label>
            <select
              name="dropdown"
              id="dropdown"
              className="dropdown"
              required // Add the required attribute
              value={selectedDropdown}
              onChange={handleDropdownChange}
              style={{ borderRadius: '7px' }}
            >
              <option value="">Select an option</option>
              <option value="Daily">Daily</option>
              <option value="2_days">2 days</option>
              <option value="3_days">3 days</option>
              <option value="Weekly">Weekly</option>
            </select>
          </span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ width: '47%' }}>
            <label>Select a date </label>
            <div>
              <input
                type="date"
                placeholder="From Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </span>
          <span style={{ width: '47%' }}>
            <p className="radio-btn-description">
              <label>
                Work Status
              </label>
            </p>
            <div style={{ display: 'flex', paddingRight: '20px', whiteSpace: 'noWrap', marginTop: '2.5%' }}>
              <label className="radio-btn-label" htmlFor="25-percent">
                <input
                  type="radio"
                  id="25-percent"
                  name="completion-status"
                  className="completion-status"
                  value="25%"
                  checked={selectedRadio === '25%'}
                  onChange={handleRadioChange}
                  required // Add the required attribute
                />{' '}
                0 - 25%
              </label>
              <label className="radio-btn-label" htmlFor="50-percent">
                <input
                  type="radio"
                  id="50-percent"
                  name="completion-status"
                  className="completion-status"
                  value="50%"
                  checked={selectedRadio === '50%'}
                  onChange={handleRadioChange}
                  required // Add the required attribute
                />{' '}
                25% - 50%
              </label>
              <label className="radio-btn-label" htmlFor="75-percent">
                <input
                  type="radio"
                  id="75-percent"
                  name="completion-status"
                  className="completion-status"
                  value="75%"
                  checked={selectedRadio === '75%'}
                  onChange={handleRadioChange}
                  required // Add the required attribute
                />{' '}
                50% - 75%
              </label>
              <label className="radio-btn-label" htmlFor="100-percent">
                <input
                  type="radio"
                  id="100-percent"
                  name="completion-status"
                  className="completion-status"
                  value="100%"
                  checked={selectedRadio === '100%'}
                  onChange={handleRadioChange}
                  required // Add the required attribute
                />{' '}
                75% - 100%
              </label>
            </div>
          </span>
        </div>

        {/* <div style={{display:'flex', justifyContent:'space-between'}}>
            <span style={{width:'47%'}}> */}
        <label>File Upload</label>
        <input
          type="file"
          className="col-lg-12"
          accept=".zip, .jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .mp4, .avi, .mkv, .mov, .wmv"
          onChange={handleFileChange}
          required // Add the required attribute
        />
        {loading && (
          <div className="loading-overlay">
            <img src="https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif" alt='processing' />
          </div>
        )}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
              {uploadProgress}%
            </div>
          </div>
        )}
        {/* </span>
            <span style={{width:'47%'}}> */}
        <label htmlFor="comments">Your comments / suggestions</label>
        <textarea
          name="comments"
          id="comments"
          cols={30}
          required
          rows={3}
          style={{ width: '100%', padding: '10px' }}
          placeholder="Enter your suggestions here"
          defaultValue={''}
          onChange={(e) => setcomment(e.target.value)}
        />

        {/* </span>
            </div> */}
        {formError && <div className="error" style={{ color: 'red' }}>{formError}</div>}
        {showSuccessPopup && (
          <>
            <p className="success-message" style={{ color: 'green' }}>
              Your data was submitted successfully!
            </p>
            <audio ref={audioRef} autoPlay onEnded={handleEnded} style={{ visibility: 'hidden' }}>
              <source src={music} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </>
        )}
        <center>
          <button style={{ width: '100%', background: storedColor, padding: '10px' }} className='submitbtn2001' type="submit" disabled={showSuccessPopup || loading || uploadProgress > 0}>
            Submit
          </button>
        </center>
        {/* <button class="button button5">Black</button> */}
        <p id="description" className="main-description" style={{ height: '0', }}>
          Hola9 Classifieds India Private Ltd.
        </p>
      </form>
    </>
  );
};

export default ProjectDetails;
