import React from 'react';
import Holidays from './featuresImages/Holidays.webp'
import TaskTracking from './featuresImages/TaskTracking.webp'
import Salary from './featuresImages/salary.webp'
import Attendance from './featuresImages/Attendance.webp'
import AtomaticLogout from './featuresImages/AutomaticLogout.webp'
import WorkReport from './featuresImages/report.webp'
import Chat from './featuresImages/Chat.webp'
import empProfile from './featuresImages/empProfile.webp'
import Gallery from './featuresImages/Gallery.webp'
import LoginDetails from './featuresImages/LoginDetails.webp'
import Mails from './featuresImages/Mails.webp'
import NewsFeed from './featuresImages/NewsFeed.webp'
import Notifications from './featuresImages/Notifications.webp'
import Instructions from './featuresImages/Instruction.jpg'


const FeaturesHome = () => {
    const storedColor = localStorage.getItem('selectedColor');

    const cont = [
        {
            imgUrl: `${AtomaticLogout}`,
            title: 'Auto Logout',
            cont: 'Auto logout at 8:30 PM: Employees are automatically signed out after 8:30 PM for a hassle-free end to the workday.'
        },
        {
            imgUrl: `${WorkReport}`,
            title: 'Daily Reports',
            cont: ' A summary providing key insights and updates on daily activities, tasks, or performance metrics within a specified timeframe.'
        },
        {
            imgUrl: `${Salary}`,
            // imgUrl : "https://cdn.vectorstock.com/i/preview-1x/48/21/paying-taxes-concept-vector-9214821.webp",
            title: 'Salaryslips',
            cont: " Concise summaries displaying earnings, deductions, and net pay, offering a snapshot of an employee's financial compensation for a given period."
        },
        {
            imgUrl: `${Attendance}`,
            title: 'Attendence Management',
            cont: "Detailed documents that outline an employee's earnings and deductions for a specific pay period, serving as a transparent record of their salary details."
        },
        {
            // imgUrl : 'https://cdn.vectorstock.com/i/preview-1x/92/46/time-tracking-software-abstract-concept-vector-49149246.webp',
            imgUrl: `${TaskTracking}`,
            title: 'Task Tracking',
            cont: 'Monitoring and recording progress on assigned activities or projects to ensure efficient workflow management and completion of objectives.'
        },
        {
            imgUrl: `${Holidays}`,
            title: 'Holidays',
            cont: "Approved time off from work, documented to track an employee's absence for various reasons such as vacation, sick days, or personal leave."
        },
        {
            imgUrl: `${Notifications}`,
            title: 'Notifications',
            cont: "Stay informed with instant Notifications. Receive timely alerts for important updates and announcements."
        },
        {
            imgUrl: `${NewsFeed}`,
            title: 'News Feed',
            cont: "Stay updated with our News Feed. Get the latest company news, insights, and updates at your fingertips"
        },
        {
            imgUrl: `${LoginDetails}`,
            title: 'Login Details',
            cont: "Access your account swiftly with our Login feature. Enter your credentials securely and enjoy a seamless, personalized experience"
        },
        {
            imgUrl: `${Chat}`,
            title: 'Chat',
            cont: "Engage in real-time communication with our Chat feature. Connect instantly and stay connected with your team effortlessly."
        },
        {
            imgUrl: `${Gallery}`,
            title: 'Gallery',
            cont: "Browse company events in our Gallery. Dive into images of memorable moments and highlights, all in one place."
        },
        {
            imgUrl: `${empProfile}`,
            title: 'Employee Profiles',
            cont: "Explore and manage your Employee Profile effortlessly. Access and update key information, roles, and personal details in one centralized hub."
        },
    ]

    return (
        <>
            <h2 style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>WEBSITE FEATURES</h2>
            <div className='FeatureHome-div' style={{ background: storedColor }}> </div>
            <div className='Featuresdiv-ov'>

                <div className='Features-card' style={{ cursor: 'pointer' }}>
                    <span style={{ width: '100%', height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={Instructions}
                            style={{ borderRadius: '50%', width: '110px', height: '110px', filter: 'drop-shadow(0px 0px 0.5px black)' }} />
                    </span>
                    <b style={{ width: '100%', height: '5%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Instruction video
                    </b>
                    <div style={{ width: '100%', height: '40%', textAlign: 'center', padding: '5%' }}>
                        If you're new to the team, please familiarize yourself with our work processes by watching the instruction video.
                    </div>
                </div>

                {cont.map((data, index) => (
                    <div key={`${data.id}-${index}`} className='Features-card'>
                        <span style={{ width: '100%', height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={data.imgUrl}
                                style={{ borderRadius: '50%', width: '110px', height: '110px', filter: 'drop-shadow(0px 0px 0.5px black)' }} />
                        </span>
                        <b style={{ width: '100%', height: '5%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {data.title}
                        </b>
                        <div style={{ width: '100%', height: '40%', textAlign: 'center', padding: '5%' }}>
                            {data.cont}
                        </div>
                    </div>
                ))}
            </div>
            {/* </div> */}
        </>
    );
};

export default FeaturesHome;