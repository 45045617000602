import React, { useEffect, useState } from 'react'
import Navbar from '../../section/navbar/Navbar';

const Assigntasktl = () => {
  const bloodGroupOptions = [
    "Hola9", "Matrimony", "DatingApp", "Realestate", "Services", "Employee login", "Jobportal"
  ];
  const bloodGroupOptions1 = [
    "Hola9", "Matrimony", "DatingApp", "Realestate", "Services", "Employee login", "Jobportal"
  ];

  const [taskData, setTaskData] = useState({
    assignee_name: "",
    project_name: "",
    tl_name: "",
    task_name: "",
    due_date: "",
    task_description: "",
    add_photo: null

  });

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === 'file') {
      // If the input type is file (for image), update the task_image property
      setTaskData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      // For other input types, update the state as before
      setTaskData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };


  const handleSubmit = (e) => {
    if (!taskData.assignee_name || !taskData.tl_name || !taskData.project_name) {
      alert('Please select values for Assignee Name, TL Name, and Project Name');
      return;
    }
    e.preventDefault();

    const formData = new FormData();
    // Append other form data to FormData
    Object.entries(taskData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Send data to the API
    fetch('https://hrmsadmin.hola9.com/account/assigntaskview', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          // Handle success
          alert('Task submitted successfully');
        } else {
          // Handle errors
          alert('Failed to submit task');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const names = data.map((item) => item.name);
  const Tesing = data.filter((item) => item.testing).map((item) => item.name);
  const Tesingtl = data.filter((item) => item.testing_tl).map((item) => item.name);
  const storedColor = localStorage.getItem('selectedColor');

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "70px" }} className="salary-slip1">
        <form onSubmit={handleSubmit} >
          <label>Task Image:
            <input type="file" name="add_photo" onChange={handleInputChange} accept="image/*" />
          </label>
          <div className="form-row">

            <label >
              Task-Name :
              <input
                type="text"
                name="task_name"
                onChange={handleInputChange}
                required
              /></label>
            <label>
              Task-Desc:
              <input
                type="text"
                name="task_description" required
                onChange={handleInputChange}
              /></label>
          </div>
          <div className="form-row">
            <label>
              AssigneeName:
              <select name="assignee_name" onChange={handleInputChange} required>
                <option>select</option>

                {Tesing.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
            <label>
              ProjectTl_Name:
              <select name="tl_name" onChange={handleInputChange} required>
                <option>select</option>

                {Tesingtl.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="form-row">
            <label>
              Due-Date :
              <input
                type="date"
                name="due_date" required
                onChange={handleInputChange}
              /></label>
            <label>
              Project Name:
              <select name="project_name" onChange={handleInputChange} required>
                <option>select</option>

                {bloodGroupOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <button className='taskbtn2001' type="submit" style={{ background: storedColor }} >Submit</button>
        </form>
      </div></>
  )
}

export default Assigntasktl