import React, { useEffect, useState } from "react";
import "./ProfileDashboard.css";
import logo from "../../../images/Hrms.png";
import Navbar from "../../../section/navbar/Navbar";
import ProfileDashCard from "./ProfileCards/ProfileDashCard";
import { useNavigate, useParams } from "react-router-dom";

import Profileid from "../Profileid";
import EmployeeTabledetail from "../Employeetabledetails";
import EmployeeTable2 from "../EmployeeTable";
import Employeetask1 from "../Employeetask1";
import Personalslip from "../Personalslip";

import Notification from "../../navDropdown/Notification/Notification";

import LeaveHome from "../../navDropdown/Leave/LeaveHome";
import Holidays from "../../navDropdown/Holidays/Holidays";
import Home from "../../Home";
import ProjectDetails from "../../Dashboard/ProjectDetails";
import Admintask from "../Employeetaskad";
import Testingtl from "../Employeetestinglead";
import Assigntask from "../../Dashboard/Assigntask";
import Assigntasktl from "../../Dashboard/Assigntesting";
import Assigntaskbackend from "../../Dashboard/Assigntaskbackend";
import ProfileForm from "../../Dashboard/Profile";
import MailForm from "../../Dashboard/Sendmail";
import Gallery from "../Gallery/Gallery";
import EmployeesList from "../../Dashboard/Employeeslips";
import TaskCardPage from "../Employeetask";
import YourComponent from "../../Dashboard/Attendenceerror";
import EmployeeTable from "../../Dashboard/AdminDashboard";
import Loginpro from "../Loginpro";
import SalarySlip from "../../Dashboard/SalarySlip";
import PerformanceEvents from "../../Dashboard/PerformanceEvents";
import AddUser from "../../Dashboard/AddUser";
import CompanyDetails from "../../Dashboard/CompanyDetails";
import Projects from "../../Dashboard/Projects";
// import Productdetailadmin from '../../Dashboard/Productdetailadmin'

const ProfileAdminDashBoard = () => {
  const [active, setActive] = useState(() => {
    return localStorage.getItem("activeTab") || "profile";
  });
  const [activeCss, setActiveCss] = useState("");

  useEffect(() => {
    localStorage.setItem("activeTab", active);
  }, [active]);

  const navigate = useNavigate();


  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  useEffect(() => {
    const loggedInadmin = localStorage.getItem("loggedInadmin");

    if (!loggedInadmin) {
      navigate("/login");
    }
  }, []);
  const background = {
    color: "gray",
  };
  const faarrow = {
    borderRadius: "50%",
    backgroundColor: "white",
    padding: "3px",
    cursor: "pointer",
  };


  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");

    if (confirmLogout) {
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("loggedInadmin");
      localStorage.removeItem("logintl");
      localStorage.removeItem("logintesting");
      localStorage.removeItem("logintestingtl");
      localStorage.removeItem("loginbackend");
      localStorage.removeItem("loginbackendtl");
      localStorage.removeItem("lastlogintime");
      window.location.reload();
      navigate("/login");
    }
  };

  const AddProfile = () => {
    setActive("aProfile")
  }
  const storedColor = localStorage.getItem('selectedColor');

  return (
    <>
      <Navbar />
      <div className="top-margin" style={{ background: storedColor }}></div>
      <div style={{ display: "flex" }}>
        <div
          className={
            activeCss === "close-side-bar" ? "open-side-bar" : "close-side-bar"
          }
          style={{ background: storedColor }}
        >
          <span className="arrow-span">
            {activeCss === "close-side-bar" ? (
              <i
                className="fas fa-arrow-left"
                style={faarrow}
                onClick={() => setActiveCss("open-side-bar")}
              ></i>
            ) : (
              <i
                className="fas fa-arrow-right"
                style={faarrow}
                onClick={() => setActiveCss("close-side-bar")}
              ></i>
            )}
          </span>
          <span className="img-span">
            <img src={logo} width={110} />
          </span>
          <span className="prof-heading  slide-prof-heading">
            <span onClick={() => navigate("/")}>
              <i className="fas fa-home"></i>
              <p>Home</p>
            </span>
            <span
              onClick={AddProfile}
            >
              <i className="fas fa-user-edit" style={active == "aProfile" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Emp Profile</> : <>Profiles</>}
              </p>
            </span>
            <span
              onClick={() => setActive("Company")}
            >
              <i className="fas fa-user" style={active == "Company" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Edit Company</> : <>Company</>}
              </p>
            </span>
            <span
              onClick={() => setActive("Project")}
            >
              <i className="fas fa-user" style={active == "Project" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Edit Project</> : <>Project</>}
              </p>
            </span>
            <span
              onClick={() => setActive("Reports")}
            >
              <i className="fas fa-user" style={active == "Reports" ? { ...background } : null}></i>
              <p>Reports</p>
            </span>

            <span
              onClick={() => setActive("slips")}
            >
              <i className="fas fa-address-card" style={active == "slips" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Generate PaySlip</> : <>Creat</>}
              </p>
            </span>
            <span
              onClick={() => setActive("Notifications")}
            >
              <i className="fas fa-bell" style={active == "Notifications" ? { ...background } : null}></i>
              <p>Notifications</p>
            </span>
            {loggedInadmin ? (<span
              onClick={() => setActive("Mail")}
            >
              <i className="fas fa-envelope" style={active == "Mail" ? { ...background } : null}></i>
              <p>Mail</p>
            </span>) : null}
            <span
              onClick={() => setActive("CheckIn")}
            >
              <i className="fas fa-sign-in-alt" style={active == "CheckIn" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? (
                  <>Check-In Details</>
                ) : (
                  <>Check-in</>
                )}
              </p>
            </span>
            <span
              onClick={() => setActive("Assigned")}
            >
              <i className="fas fa-list" style={active == "Assigned" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Tasks List</> : <>Tasks</>}
              </p>
            </span>
            <span
              onClick={() => setActive("task")}
            >
              <i className="fas fa-list" style={active == "task" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Assign Task</> : <>Assign</>}
              </p>
            </span>
            <span
              onClick={() => setActive("Leave")}
            >
              <i className="fas fa-calendar-day" style={active == "Leave" ? { ...background } : null}></i>
              <p>Leave</p>
            </span>
            <span
              onClick={() => setActive("Adduser")}
            >
              <i className="fas fa-user" style={active == "Adduser" ? { ...background } : null}></i>
              <p>Adduser</p>
            </span>
            <span
              onClick={() => setActive("empofm")}
            >
              <i className="fas fa-calendar-day" style={active == "empofm" ? { ...background } : null}></i>
              <p>
                {activeCss === "close-side-bar" ? <>Best Employee</> : <>EOM</>}
              </p>
            </span>
            <span
              onClick={() => setActive("Holidays")}
            >
              <i className="fas fa-candy-cane" style={active == "Holidays" ? { ...background } : null}></i>
              <p>Holidays</p>
            </span>
            <span
              onClick={() => setActive("payslip")}
            >
              <i className="fas fa-money-check-alt" style={active == "payslip" ? { ...background } : null}></i>
              <p>Payslip</p>
            </span>
            <span
              onClick={() => setActive("gallery")}
            >
              <i className="fas fa-image" style={active == "gallery" ? { ...background } : null}></i>
              <p>Gallery</p>
            </span>
            <span
              onClick={handleLogout}
            >
              <i className="fas fa-sign-out-alt"></i>
              <p>Logout</p>
            </span>
          </span>
        </div>

        <div style={{ padding: "30px", width: "100%" }}>

          <div>
            {/* {active === "Home" ?  <Home/> :null} */}
            {active === "Reports" ? (
              <EmployeeTable name={loggedInadmin ? loggedInadmin.email : null} domain={""} />
            ) : null}
            {active === "Mail" ? (
              <MailForm />
            ) : null}
            {active === "aProfile" ? <>
              <Loginpro /> </> : null}
            {active === "slips" ? (
              <SalarySlip />
            ) : null}
            {active === "Notifications" ? (
              <Notification name={loggedInadmin ? loggedInadmin.email : null} />
            ) : null}
            {active === "CheckIn" ? (
              <YourComponent name={loggedInadmin ? loggedInadmin.email : null} />
            ) : null}
            {active === "Assigned" ? (

              <TaskCardPage />) : null}
            {active === "task" ? (
              <>
                <Assigntask /> </>
            ) : null}
            {active === "Adduser" ? (
              <>
                <AddUser /> </>
            ) : null}
            {active === "Company" ? (
              <>
                <CompanyDetails /> </>
            ) : null}
            {active === "Project" && <Projects />}
            {active === "Leave" && <LeaveHome />}
            {active === "empofm" && <PerformanceEvents />}
            {active === "Holidays" && <Holidays />}
            {active === "payslip" ? (
              <EmployeesList />
            ) : null}
            {active === "gallery" && <Gallery />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileAdminDashBoard;
