import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './UesrDashboard.css';
import TaskDetails from './TaskDetailsPopup';
import { Link } from 'react-router-dom';
import TaskDetailsPopup from './TaskDetailsPopup';
import music from '../Dashboard/mixkit-arcade-retro-game-over-213.wav';

const Testingtl = (props) => {
  const name = props.name
  const [tasks, setTasks] = useState([]);
  const [sound, setsound] = useState(false);
  const [search, setSearch] = useState('');
  const [currentTab, setCurrentTab] = useState('todo');
  const [selectedTask, setSelectedTask] = useState(null); // Track the selected task for the popup
  const [tasksToShow, setTasksToShow] = useState(8);
  const [loading, setLoading] = useState(true); // Add loading state


  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://hrmsadmin.hola9.com/account/assigntaskview?tester=${name}`);
        setTasks(response.data);
        setLoading(false); // Set loading to false when data is loaded

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleButtonClick = async (taskId, fieldName) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return { ...task, [fieldName]: true };
      }
      return task;
    });

    try {
      // Assuming you are using axios for API calls
      await axios.put(`https://hrmsadmin.hola9.com/account/assigntaskview/${taskId}`, {
        [fieldName]: true,
      });

      // Update local state to reflect the changes
      setTasks(updatedTasks);
      setsound(true);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const filterTasks = () => {
    const filteredTasks = tasks.filter((task) => {
      // Filter tasks based on the search input
      const taskDetails = `${task.id} ${task.task_name} ${task.assignee_name} ${task.project_name} ${task.due_date} ${task.push_code}`;
      return taskDetails.toLowerCase().includes(search.toLowerCase());
    });
    switch (currentTab) {
      // case 'Notdone':
      //   return tasks.filter((task) => !task.push_code && !task.dev_review && !task.task_done && task.push_code && task.dev_review && task.task_done);
      case 'todo':
        return filteredTasks.slice(0, tasksToShow).filter((task) => !task.task_done);
      case 'all':
        return filteredTasks.slice(0, tasksToShow);
      case 'devReview':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.push_code && !task.dev_review);
      case 'Deployment':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.dev_review && !task.deployment);
      case 'Re_Testing':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.re_deployment && !task.re_testing);
      case 'Testing':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.deployment && !task.testing);
      case 'Re_Deployment':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.testing && !task.re_deployment);
      case 'testing_bug':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.re_testing && !task.testing_bug);
      case 'pushCode':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && !task.push_code);
      case 'done':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.push_code && task.dev_review && task.task_done && task.deployment && task.testing && task.re_deployment && task.re_testing);
      case 'pending':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.overdue_duedate);
      default:
        return filteredTasks.slice(0, tasksToShow);
    }
  };
  const handleLoadMore = () => {
    // Increment the number of tasks to display by 20
    setTasksToShow((prevTasksToShow) => prevTasksToShow + 8);
  };
  const openPopup = (task) => {
    setSelectedTask(task);
  };

  const closePopup = () => {
    setSelectedTask(null);
  };
  const audioRef = useRef(null);

  const handleEnded = () => {
    // Pause the audio when it has ended
    audioRef.current.pause();
  };
  return (
    <div className="task-card-page">
      {loading ? (
        <center className="loading-indicator" style={{ padding: "50px" }}><img src='https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif' alt='loading...' /></center>
      ) : (
        <>
          <div style={{ textAlign: "center", margin: "20px" }}>
            <input
              type="text" className='filin'
              placeholder="Search tasks by Name, Description, Title..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="tabs" style={{ display: "flex", justifyContent: "center" }}>
            {/* <button
          className={currentTab === 'Notdone' ? 'active' : ''}
          onClick={() => setCurrentTab('Notdone')}
        >
          All task
        </button> */}
            <button
              className={currentTab === 'all' ? 'active' : ''}
              onClick={() => setCurrentTab('all')}
            >
              All Task
            </button>
            {/* <button
          className={currentTab === 'todo' ? 'active' : ''}
          onClick={() => setCurrentTab('todo')}
        >
          ToDo
        </button>
      
        <button
          className={currentTab === 'pushCode' ? 'active' : ''}
          onClick={() => setCurrentTab('pushCode')}
        >
          Push Code
        </button>  
        <button
          className={currentTab === 'devReview' ? 'active' : ''}
          onClick={() => setCurrentTab('devReview')}
        >
          Dev Review
        </button> */}
            <button
              className={currentTab === 'Deployment' ? 'active' : ''}
              onClick={() => setCurrentTab('Deployment')}
            >
              Deployment
            </button>
            <button
              className={currentTab === 'Testing' ? 'active' : ''}
              onClick={() => setCurrentTab('Testing')}
            >
              Testing
            </button>
            <button
              className={currentTab === 'Re_Deployment' ? 'active' : ''}
              onClick={() => setCurrentTab('Re_Deployment')}
            >
              Re_Deployment
            </button>
            <button
              className={currentTab === 'Re_Testing' ? 'active' : ''}
              onClick={() => setCurrentTab('Re_Testing')}
            >
              Re_Testing
            </button>
            <button
              className={currentTab === 'testing_bug' ? 'active' : ''}
              onClick={() => setCurrentTab('testing_bug')}
            >
              Testing_bug
            </button>

            <button
              className={currentTab === 'done' ? 'active' : ''}
              onClick={() => setCurrentTab('done')}
            >
              Done
            </button>
            <button
              className={currentTab === 'pending' ? 'active' : ''}
              onClick={() => setCurrentTab('pending')}
            >
              Pending
            </button>
            {/* <div className="line" style={{ width: `${100 / 4}%` }} /> */}
          </div>
          <div className="task-cards-container">
            {filterTasks().length === 0 ? (
              <div style={{ textAlign: "center", padding: "70px" }}>Nothing in {currentTab}.</div>
            ) : (

              filterTasks().map((task) => (
                <div key={task.id} className="task-card" >
                  <div style={{ display: "flex" }}>
                    <p style={{ display: "flex", float: "left" }}>({task.id})</p>
                    <div>
                      <h3>{task.task_name}</h3>
                      <p>Assignee: {task.assignee_name}</p>
                      <p>Project: {task.project_name}</p>
                      <p>Tl_name: {task.tl_name}</p>
                      <p>Due Date: {task.due_date}</p>
                    </div>
                    {task.addphoto_base64 && <img src={`data:image/png;base64,${task.addphoto_base64}`} alt="Task" style={{ height: "150px", width: "150px" }} />}

                  </div>
                  <div className='buttoncont'>
                    <button
                      className={task.task_done ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'task_done')}
                    >
                      Task Done
                    </button>
                    <button
                      className={task.push_code ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'push_code')}
                    >
                      Push Code
                    </button>
                    <button
                      className={task.dev_review ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'dev_review')}
                    >
                      Dev Review
                    </button>
                    <button
                      className={task.deployment ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'deployment')}
                    >
                      Deployment
                    </button>
                    <button
                      className={task.testing ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'testing')}
                    >
                      Testing
                    </button>
                    <button
                      className={task.re_deployment ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 're_deployment')}
                    >
                      Re_Deployment
                    </button>
                    <button
                      className={task.re_testing ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 're_testing')}
                    >
                      Re_testing
                    </button>
                    <button
                      className={task.testing_bug ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'testing_bug')}
                    >
                      Testing_bug
                    </button>

                  </div>
                  <button onClick={() => openPopup(task)} className='taskbtn2001'> Details</button>

                </div>
              ))

            )}</div>
          {/* {filterTasks().length > tasksToShow && ( */}
          {filterTasks().length > 7 && (
            <div style={{ textAlign: 'center', margin: '20px' }}>
              <button onClick={handleLoadMore} className="taskbtn2001">
                Load More
              </button>
            </div>
          )}</>
      )}
      {sound && (
        <audio ref={audioRef} autoPlay onEnded={handleEnded} style={{ display: 'none' }}>
          <source src={music} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      )}
      {/* )} */}
      {selectedTask && (
        <TaskDetailsPopup task={selectedTask} onClose={closePopup} />
      )}
    </div>
  );
};

export default Testingtl;
