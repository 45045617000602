// Import necessary React components and styles
import React, { useEffect, useState } from 'react';
import './SalarySlip.css'; // Import the CSS file
import Navbar from '../../section/navbar/Navbar';
import { BiSave } from 'react-icons/bi';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// SalarySlip component
const SalarySlip = () => {
  // State to store form data
  const [formData1, setFormData1] = useState()
  const [formData, setFormData] = useState({
    employeeName: '',
    employeeId: '',
    totalwork: '',
    leaves: '',
    designation: '',
    dateOfJoining: '',
    month: '',
    year: '',
    basicDA: '',
    providentFund: '',
    hra: '',
    esi: '',
    conveyance: '',
    loan: '',
    professionTax: '',
    lop: '',
    totalAddition: '',
    totalDeduction: '',
    netSalary: '',
    bank_name: '',  // Ensure bank_name is included in the initial state
    ifsc: '',
    acc_num: '',
    branch_name: '',
    extrafil_1: formData1,
  });
  const numberToWords = (num) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convert = (num) => {
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
      if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + convert(num % 100) : '');
      if (num < 1000000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + convert(num % 1000) : '');
      if (num < 1000000000) return convert(Math.floor(num / 1000000)) + ' Million' + (num % 1000000 !== 0 ? ' ' + convert(num % 1000000) : '');
      if (num < 1000000000000) return convert(Math.floor(num / 1000000000)) + ' Billion' + (num % 1000000000 !== 0 ? ' ' + convert(num % 1000000000) : '');


    };

    return convert(num);
  };

  // Function to handle form data changes
  // Function to handle form data changes
  // Function to handle form data changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'dateOfJoining') {
      // Handle date input separately
      setFormData({ ...formData, [name]: value });
    } else {
      // Allow both alphabetic and numeric characters for basicDA, hra, and conveyance
      const isBasicDAHraConveyance = /^[A-Za-z0-9 ]+$/.test(value);

      if (isBasicDAHraConveyance || value === '') {
        // Update state only if the input is alphabetic, numeric, or empty
        setFormData({ ...formData, [name]: value });
      }
    }
  };



  // Function to handle form submission (in a real application, you might send this data to a server)
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Function to handle form data changes
  const monthsList = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December',
  ];
  const bankNames = [
    'State Bank of India', 'HDFC Bank', 'ICICI Bank', 'Axis Bank',
    'Kotak Mahindra Bank', 'Bank of Baroda', 'Punjab National Bank', 'Canara Bank',
    'Union Bank of India', 'Bank of India', 'IDBI Bank', 'IndusInd Bank',
    'Yes Bank', 'Federal Bank', 'Karur Vysya Bank', 'South Indian Bank',
    'RBL Bank', 'Standard Chartered Bank', 'Citibank', 'HSBC'
  ];

  // Function to handle form submission
  const handlePrint = async () => {
    try {
      // Set loading state to true
      setLoading(true);

      // Make an HTTP POST request to your API endpoint
      const response = await axios.post('https://hrmsadmin.hola9.com/account/employees_joining/', formData);

      // Set success state to true
      setSuccess(true);
      window.alert(`${formData.employeeName}'s ${formData.month} Salary slip generated successfully. `);

      // Log the response from the server (for debugging purposes)
      console.log('Server Response:', response.data);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error sending data:', error.message);
    } finally {
      // Set loading state back to false, whether the request was successful or not
      setLoading(false);
    }
  };
  useEffect(() => {
    const basicDA = parseFloat(formData.basicDA) || 0;
    const hra = parseFloat(formData.hra) || 0;
    const conveyance = parseFloat(formData.conveyance) || 0;

    const totalAddition = basicDA + hra + conveyance;
    setFormData({ ...formData, totalAddition: totalAddition.toFixed(2) });
  }, [formData.basicDA, formData.hra, formData.conveyance]);
  useEffect(() => {
    const professionTax = parseFloat(formData.professionTax) || 0;
    const lop = parseFloat(formData.lop) || 0;
    const providentFund = parseFloat(formData.providentFund) || 0;
    const esi = parseFloat(formData.esi) || 0;
    const loan = parseFloat(formData.loan) || 0;

    const totalDeduction = professionTax + lop + providentFund + esi + loan;
    setFormData({ ...formData, totalDeduction: totalDeduction.toFixed(2) });
  }, [formData.professionTax, formData.lop, formData.providentFund, formData.esi, formData.loan]);
  useEffect(() => {
    const totalAddition = parseFloat(formData.totalAddition) || 0;
    const totalDeduction = parseFloat(formData.totalDeduction) || 0;

    const netSalary = totalAddition - totalDeduction;
    setFormData({ ...formData, netSalary: netSalary.toFixed(2) });
  }, [formData.totalAddition, formData.totalDeduction]);


  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const names = data.map((item) => item.name);

  //navigation code start for adimin login
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInadmin = localStorage.getItem("loggedInadmin");

    if (!loggedInadmin) {
      navigate("/AdminLogin");
    }
  }, []);
  //navigation code end for adimin login
  return (
    <>
      <Navbar />
      <div className="salary-slip" style={{ marginTop: "50px" }}>
        <h2>Salary Slip</h2>
        <div>
          <div className="form-row">

            <label>
              Employee Name:
              <select
                name="employeeName"
                value={formData.employeeName}
                onChange={handleInputChange} style={{ borderRadius: '10px' }}
              >
                <option value="">Select Name</option>
                {names.map((employeeName, index) => (
                  <option key={index} value={employeeName}>
                    {employeeName}
                  </option>
                ))}
              </select>
            </label>


            <label>
              Employee Id:
              <input
                type="text"
                name="employeeId"
                value={formData.employeeId}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Designation:
              <input
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="form-row">
            <label>
              Total Working Days:
              <input
                type="text"
                name="totalwork"
                value={formData.totalwork}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Leaves:
              <input
                type="text"
                name="leaves"
                value={formData.leaves}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="form-row">
            <label>
              Date of Joining:
              <input
                type="date"
                name="dateOfJoining"
                value={formData.dateOfJoining}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Month:
              <select
                name="month"
                value={formData.month}
                onChange={handleInputChange} style={{ borderRadius: '10px' }}
              >
                <option value="">Select Month</option>
                {monthsList.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Year:
              <input
                type="text"
                name="year"
                value={formData.year}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="form-row">
            <label>
              BankName:
              <select
                name="bank_name"
                value={formData.bank_name}
                onChange={handleInputChange} style={{ borderRadius: '10px' }}
              >
                <option value="">Select Bank</option>
                {bankNames.map((bank, index) => (
                  <option key={index} value={bank}>
                    {bank}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Branch Name.
              <input
                type="text"
                name="branch_name"
                value={formData.branch_name}
                onChange={handleInputChange}
              />
            </label>

          </div>
          <div className='form-row'>
            <label>
              Bank ACC.
              <input
                type="text"
                name="acc_num"
                value={formData.acc_num}
                onChange={handleInputChange}
              />
            </label>


            <label>
              IFSC
              <input
                type="text"
                name="ifsc"
                value={formData.ifsc}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <h3>Earnings</h3>
          <div className="form-row">
            <label>
              Basic & DA:
              <input
                type="number"
                name="basicDA"
                value={formData.basicDA}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Conveyance:
              <input
                type="number"
                name="conveyance"
                value={formData.conveyance}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="form-row">
            <label>
              HRA:
              <input
                type="number"
                name="hra"
                value={formData.hra}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Total Addition:
              <input
                type="number"
                name="totalAddition"
                value={formData.totalAddition}
                onChange={handleInputChange}
              />
            </label>

          </div>

          <h3>Deductions</h3>
          <div className="form-row">
            <label>
              Profession Tax:
              <input
                type="number"
                name="professionTax"
                value={formData.professionTax}
                onChange={handleInputChange}
              />
            </label>
            <label>
              LOP:
              <input
                type="number"
                name="lop"
                value={formData.lop}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Provident Fund:
              <input
                type="number"
                name="providentFund"
                value={formData.providentFund}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="form-row">
            <label>
              E.S.I.:
              <input
                type="number"
                name="esi"
                value={formData.esi}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Loan:
              <input
                type="number"
                name="loan"
                value={formData.loan}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Total Deduction:
              <input
                type="number"
                name="totalDeduction"
                value={formData.totalDeduction}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="form-row">
            <label>
              NET Salary:
              <input
                type="number"
                name="netSalary"
                value={formData.netSalary}
                onChange={handleInputChange}
              />
            </label>

          </div>
          <b>Net Salary in Words: {numberToWords(parseFloat(formData.netSalary))} Only</b>

          <button type="button" className='print-button12' onClick={handlePrint}>
            <BiSave /> Save
          </button>
          {loading && <div className="loading-overlay">
            <img src="https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif" alt='processing' />
          </div>}
          {success && <p style={{ color: "green" }}>Created successfully!</p>}
        </div>
      </div>
    </>
  );
};

export default SalarySlip;
