import React, { useEffect, useState } from "react";
import logo from "../../images/Hola9logo.jpg";
import pic from "../../images/surya.jpeg";
import Slider from "react-slick";

const EmpofWeek = () => {
  const storedColor = localStorage.getItem("selectedColor");
  const [isLoading, setIsLoading] = useState(true);

  const [active, setActive] = useState("EOW");

  const [avblEventType, setavblEventType] = useState([]);

  useEffect(() => {
    fetch("https://hrmsadmin.hola9.com/account/wanted/")
      .then((response) => response.json())
      .then((data) => {
        setavblEventType(data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const currentDate = new Date().toISOString().split('T')[0];

  const EmployeOfMonth = () => {
    const [eomData, seteomData] = useState([]);

    useEffect(() => {
      fetch("https://hrmsadmin.hola9.com/account/wanted/")
        .then((response) => response.json())
        .then((data) => {
          const filteredData = data.filter((item) => item.event_type === 'employee_of_month');
          seteomData(filteredData);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }, []);

    const settings = {
      infinite: true,
      autoplay: true,
      PauseOnHover: true,
      autoplaySpeed: 1000,
    }
    return (
      <>
        {isLoading ? (
          <center className="loading-indicator">
            <img
              src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
              alt="---loading"
              className="emp-loading"
            />
          </center>
        ) : (
          <>
            <Slider {...settings}>
              {eomData.map((data) => (
                <>
                  <h2 className="emp-card-title">
                    Employee of the Month |
                    <span
                      style={{
                        fontSize: "75%",
                        background: storedColor,
                        color: "transparent",
                        backgroundClip: "text",
                      }}
                    >
                      {data.date}
                    </span>
                  </h2>
                  <i className="emp-card-body">
                    Congratulations on your outstanding performance!
                  </i>
                  <div
                    style={{
                      background: storedColor,
                      width: "100%",
                      height: "40%",
                      marginTop: "10%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      padding: "0 10px",
                    }}
                  >
                    <img
                      src={`https://hrmsadmin.hola9.com` + data.images[0].image}
                      className="emp-card-image"
                    />
                    <h6 className="emp-card-subtitle">
                      <span>{data.title}</span>
                    </h6>
                  </div>
                </>
              ))}
            </Slider>
          </>
        )}
      </>
    );
  };

  const EmployeOfWeek = () => {
    const [eowData, seteowData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      fetch("https://hrmsadmin.hola9.com/account/wanted/")
        .then((response) => response.json())
        .then((data) => {
          const filteredData = data.filter((item) => item.event_type === 'employee_of_week');
          seteowData(filteredData);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }, []);

    // const settings = {
    //   infinite: true,
    //   autoplay: true,
    // PauseOnHover: true,
    // autoplaySpeed: 1000,
    // }
    return (
      <>
        {isLoading ? (
          <center className="loading-indicator">
            <img
              src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
              alt="---loading"
              className="emp-loading"
            />
          </center>
        ) : (
          // <Slider {...settings}>
          <>
            {eowData.map((data) => (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    background: storedColor,
                    marginTop: "25px",
                  }}
                ></div>
                <div className="emp-week-card">
                  <img
                    src={`https://hrmsadmin.hola9.com` + data.images[0].image}
                    width={140}
                    height={170}
                    style={{
                      padding: "5px",
                      boxShadow: "0px 0.2px 3px inset",
                      borderRadius: "10%",
                    }}
                  />
                  <div style={{ marginLeft: "5%" }}>
                    <h3 className="emp-week-title">Employee of the Week 🎉</h3>
                    <br />
                    <h3
                      style={{
                        margin: "10px 0 15px 0",
                        fontSize: "130%",
                        background: storedColor,
                        backgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      {data.title}
                    </h3>
                    <p
                      style={{
                        fontSize: "80%",
                        fontStyle: "italic",
                        lineHeight: "13px",
                      }}
                    >
                      In recognition of your effort, Being an example to all our
                      colleagues
                    </p>
                  </div>
                </div>
              </>
            ))}
          </>
          // </Slider>
        )}
      </>
    );
  };

  const Birthday = () => {
    const [birthdayData, setBirthdayData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const currentDate = new Date().toISOString().split('T')[0];

    useEffect(() => {
      fetch(`https://hrmsadmin.hola9.com/account/wanted/?date=${currentDate}`)
        .then((response) => response.json())
        .then((data) => {
          const filteredData = data.filter((item) => item.event_type === 'employee_birthday');
          setBirthdayData(filteredData);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }, [currentDate]);

    const settings = {
      infinite: true,
      autoplay: true,
      PauseOnHover: true,
      autoplaySpeed: 1000,
    }
    return (
      <>
        {isLoading ? (
          <center className="loading-indicator">
            <img
              src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
              alt="---loading"
              className="emp-loading"
            />
          </center>
        ) : (
          <>
            <Slider {...settings}>
              {birthdayData.map((data) => (
                <>
                  <h3
                    className="emp-bday-heading"
                    style={{ background: storedColor, backgroundClip: "text" }}
                  >
                    Happy Birthday
                  </h3>
                  <div className="emp-birthday-card">
                    <div className="emp-card-bday-image">
                      <img
                        src={`https://hrmsadmin.hola9.com` + data.images[0].image}
                      />
                      <b>{data.title}</b>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "65%",
                      textAlign: "center",
                      boxShadow: "0px 0.2px 3px",
                      background: storedColor,
                      marginTop: "2%",
                      color: "white",
                    }}
                  >
                    "Wishing you long life and prosperity. May god blessing you
                    radiate in your new"
                  </div>
                </>
              ))}
            </Slider>
          </>
        )}
      </>
    );
  };



  const Festival = () => {
    const [festivalData, setfestivalData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const currentDate = new Date().toISOString().split('T')[0];

    useEffect(() => {
      fetch("https://hrmsadmin.hola9.com/account/wanted/")
        .then((response) => response.json())
        .then((data) => {
          const filteredData = data.filter((item) => item.event_type === 'festival' && item.date === currentDate);
          setfestivalData(filteredData);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }, []);

    const settings = {
      infinite: true,
      autoplay: true,
      PauseOnHover: true,
      autoplaySpeed: 1000,
    }
    return (
      <>
        {isLoading ? (
          <center className="loading-indicator">
            <img
              src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
              alt="---loading"
              className="emp-loading"
            />
          </center>
        ) : (
          <>
            {/* {festivalData.map((data) => data.date === currentDate) ? 'rtrt': null} */}
            <Slider {...settings}>
              {festivalData.map((data) => (
                <>
                  <h3
                    className="emp-bday-heading"
                    style={{ background: storedColor, backgroundClip: "text" }}
                  >
                    Happy Birthday
                  </h3>
                  <div className="emp-birthday-card">
                    <div className="emp-card-bday-image">
                      {data.images && data.images.length > 0 && (
                        <img src={`https://hrmsadmin.hola9.com${data.images[0].image}`} alt={data.title} />
                      )}
                      <b>{data.title}</b>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "65%",
                      textAlign: "center",
                      boxShadow: "0px 0.2px 3px",
                      background: storedColor,
                      marginTop: "2%",
                      color: "white",
                    }}
                  >
                    "Wishing you long life and prosperity. May god blessing you
                    radiate in your new"
                  </div>
                </>
              ))}
            </Slider>
          </>
        )}
      </>
    );
  };




  return (
    <div className="emp-card">
      <div className="emp-card-header">
        <div className="emp-card-header-sub">
          {avblEventType.some(event => event.event_type === 'employee_of_month') ? (
            <span
              onClick={() => setActive("EOM")}
              style={{
                background: active === "EOM" && storedColor,
                color: active === "EOM" && "white",
              }}
            >
              Emp Of Month
            </span>
          ) : null}
          {avblEventType.some(event => event.event_type === 'employee_of_week') ? (
            <span
              onClick={() => setActive("EOW")}
              style={{
                background: active === "EOW" && storedColor,
                color: active === "EOW" && "white",
              }}
            >
              Emp Of Week
            </span>
          ) : null}
          {avblEventType.filter(event => event.event_type === 'employee_birthday' && event.date === currentDate) ? (
            <span
              onClick={() => setActive("Birthday")}
              style={{
                background: active === "Birthday" && storedColor,
                color: active === "Birthday" && "white",
              }}
            >
              Birthday
            </span>
          ) : null}
          {avblEventType.filter(event => event.event_type === 'festival' && event.date === currentDate) ? (
            <span
              onClick={() => setActive("festival")}
              style={{
                background: active === "festival" && storedColor,
                color: active === "festival" && "white",
              }}
            >
              Festival
            </span>
          ) : null}
        </div>
        <img src={logo} width={55} height={25} />
      </div>
      {active === "EOM" ? (
        <EmployeOfMonth />
      ) : active === "EOW" ? (
        <EmployeOfWeek />
      ) : active === "Birthday" ? (
        <Birthday />
      ) : active === "festival" ? (
        <Festival />
      ) : null}
    </div>
  );
};

export default EmpofWeek;
