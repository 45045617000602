import React, { useState, useEffect, useRef } from "react";
import "./Gallery.css"; // Import CSS file for styling
import Form from "./Addgallery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Gallery() {
  const [isLoading, setIsLoading] = useState(true);


  const [galleryData, setGalleryData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    // Fetch data from your API endpoint
    fetch("https://hrmsadmin.hola9.com/account/wanted2/")
      .then((response) => response.json())
      .then((data) => {
        setGalleryData(data)
        setIsLoading(false);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Function to handle click on a card
  const handleCardClick = (item) => {
    setSelectedItem(item);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedItem(null);
  };
  const [openLeaveForm, setopenLeaveForm] = useState(false);

  //  -----------------------  Add Mor Gallery -------------------------------------- //

  const fileInputRef = useRef(null);

  const handleImageUpload = (image) => {
    setIsLoading(true);
    fetch(`https://hrmsadmin.hola9.com/account/wanted2/${image}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(galleryData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to Uploaded Images");
          setIsLoading(false);
        }
        return response.json();
      })
      .then((data) => {
        alert("Images uploaded successfully:", data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
        alert("Error during image upload:");
        setIsLoading(false);
      });
  };

  const handleAddMoreClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // ---------------------------- Click on esc Button to close popup ------------------------------------- //
  const storedColor = localStorage.getItem("selectedColor");

  const handleEscKey = (event) => {
    if (event.key === "Escape") {
      setopenLeaveForm(false);
      setSelectedItem(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <center className="loading-indicator">
          <img
            src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
            alt="---loading"
            className="emp-loading"
            style={{ width: '400px', height: '400px' }}
          />
        </center>
      ) : (
        <>
          <button
            onClick={() => setopenLeaveForm(true)}
            style={{ background: storedColor, color: "white", marginTop: '10px' }}
          >
            Add Gallery
          </button>


          <div style={{ display: 'flex', gap: '1.5%' }}>
            <div style={{ width: '32%' }}>
              {galleryData.slice(0, 10).map((item) => (
                <div
                  key={item.id}
                  className="image-card"
                  onClick={() => handleCardClick(item.id)}
                >
                  <div
                    className="description23"
                  >
                    <p className="img-desc">{item.description}</p>
                  </div>
                  {item.images && item.images.length > 0 && (
                    <img
                      src={`https://hrmsadmin.hola9.com${item.images[0].image}`}
                      alt={`Image`}
                      className="image-card-img"

                    />
                  )}
                  {/* Render description */}
                  <div
                    style={{
                      width: "95%",
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "35px",
                      padding: '5px'
                    }}
                  >
                    <h3
                      style={{
                        // background: storedColor,
                        // backgroundClip: "text",
                        // color: "transparent",
                        fontSize: "110%",
                        overflow: "hidden",
                      }}
                    >
                      {item.organization}
                    </h3>
                    <i
                      style={{
                        fontSize: "75%",
                        whiteSpace: "nowrap",
                        marginLeft: "5px",
                      }}
                    >
                      {item.date}
                    </i>
                  </div>

                </div>
              ))}
            </div>


            <div style={{ width: '32%' }}>
              {galleryData.slice(10, 20).map((item) => (
                <div
                  key={item.id}
                  className="image-card"
                  onClick={() => handleCardClick(item)}
                >
                  <div
                    className="description23"
                  >
                    <p className="img-desc">{item.description}</p>
                  </div>
                  {item.images && item.images.length > 0 && (
                    <img
                      src={`https://hrmsadmin.hola9.com${item.images[0].image}`}
                      alt={`Image`}
                      className="image-card-img"

                    />
                  )}
                  {/* Render description */}
                  <div
                    style={{
                      width: "95%",
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "35px",
                      padding: '5px'
                    }}
                  >
                    <h3
                      style={{
                        // background: storedColor,
                        // backgroundClip: "text",
                        // color: "transparent",
                        fontSize: "110%",
                        overflow: "hidden",
                      }}
                    >
                      {item.organization}
                    </h3>
                    <i
                      style={{
                        fontSize: "75%",
                        whiteSpace: "nowrap",
                        marginLeft: "5px",
                      }}
                    >
                      {item.date}
                    </i>
                  </div>
                </div>
              ))}
            </div>


            <div style={{ width: '32%' }}>
              {galleryData.slice(20, 30).map((item) => (
                <div
                  key={item.id}
                  className="image-card"
                  onClick={() => handleCardClick(item)}
                >
                  <div
                    className="description23"
                  >
                    <p className="img-desc">{item.description}</p>
                  </div>
                  {item.images && item.images.length > 0 && (
                    <img
                      src={`https://hrmsadmin.hola9.com${item.images[0].image}`}
                      alt={`Image`}
                      className="image-card-img"

                    />
                  )}
                  {/* Render description */}
                  <div
                    style={{
                      width: "95%",
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "35px",
                      padding: '5px'
                    }}
                  >
                    <h3
                      style={{
                        // background: storedColor,
                        // backgroundClip: "text",
                        // color: "transparent",
                        fontSize: "110%",
                        overflow: "hidden",
                      }}
                    >
                      {item.organization}
                    </h3>
                    <i
                      style={{
                        fontSize: "75%",
                        whiteSpace: "nowrap",
                        marginLeft: "5px",
                      }}
                    >
                      {item.date}
                    </i>
                  </div>
                </div>
              ))}
            </div>


            {/* </div> */}



            {/* Popup for displaying details */}
            {selectedItem && (
              <div className="popup">
                <div
                  style={{ width: "70%", height: "80%", background: "white" }}
                  className="pop-img-cont"
                >
                  <i onClick={handleClosePopup} style={{ float: "right" }}>
                    <span style={{ position: "absolute", cursor: "pointer" }}>
                      ❌
                    </span>
                  </i>
                  {/* Render all images */}
                  <Slider
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "93%",
                    }}
                  >
                    {selectedItem.images && selectedItem.images.map((image, index) => (
                      <img
                        controls
                        key={index}
                        src={`https://hrmsadmin.hola9.com${image.image}`}
                        alt={`Image ${index}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    ))}
                    {/* Render video */}
                    <video controls>
                      <source
                        src={`https://hrmsadmin.hola9.com${selectedItem.video}`}
                        type="video/mp4"
                        style={{ width: "100%", height: "100%" }}
                      />
                      Your browser does not support the video tag.
                    </video>
                    {/* {addMoreForm && ( */}

                    {/* )} */}
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <br />

                      <>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept="image/*"
                          multiple
                          onChange={() => handleImageUpload(selectedItem.id)}
                        />

                        <button
                          style={{
                            padding: "5px 10px",
                            background: "white",
                            width: "100%",
                            height: "300px",
                          }}
                          onClick={handleAddMoreClick}
                        >
                          Add More
                        </button>
                      </>
                    </div>
                  </Slider>
                  {/* Render description */}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      height: "40px",
                      background: storedColor,
                      color: "white",
                    }}
                  >
                    <h4 style={{ color: "white" }}>{selectedItem.organization}</h4>
                    <p>{selectedItem.date}</p>
                  </div>
                  <div style={{ marginTop: "-135px", position: "absolute" }}>
                    <p
                      className="img-desc"
                      style={{ height: "100%", width: "80%", marginLeft: "0.6%" }}
                    >
                      {selectedItem.description}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {openLeaveForm ? (
            <div className="popup">
              <Form close={() => setopenLeaveForm(false)} />
            </div>
          ) : null}
        </>
      )}

      {/* {addMoreGallery && <div className="popup">dsnifhd</div>} */}
    </>
  );
}

export default Gallery;

// import React, { useState } from 'react';
// import './Gallery.css';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
// import { useRef,  useEffect} from 'react';

// const Images1 = ({handleImageClick, storedColor}) => {
//     const slideRef = useRef(null);

//   const handleClickNext = () => {
//     let items = slideRef.current.querySelectorAll(".item23");
//     slideRef.current.appendChild(items[0]);
//   };

//   const handleClickPrev = () => {
//     let items = slideRef.current.querySelectorAll(".item23");
//     slideRef.current.prepend(items[items.length - 1]);
//   };

//   const [currentIndex, setCurrentIndex] = useState(0);

//   const autoScrollLeft = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     handleClickNext();
//   };
//   useEffect(() => {

//     const intervalId = setInterval(autoScrollLeft, 3000);

//     return () => clearInterval(intervalId);
//   }, [currentIndex]);

//   const images = [
//     {imgUrl: "https://img.freepik.com/vecteurs-premium/office-party-celebration-happy-cartoon-team_81522-4603.jpg"},
//     {imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzSDj6yqpHa8aWK8OFqVLIsOTvO9RvKxMLIegNIMzCxo2RvbrvLanPTkqXeE1WRmuHhe4&usqp=CAU"},
//     {imgUrl: "https://img.freepik.com/free-vector/hand-drawn-business-party-illustration_52683-87327.jpg"},
//     {imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZJWBcSxCqQiHWnqmzOVvQilzMH85qUfS4R74WyaplYxqTFSDsbYt9lQCWekbOoqUccFQ&usqp=CAU"},
//     {imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLVws5IZc6yvfL6cucPswMYQvmz2SWmSWPV-34ZgoWKhh9jphhvYpWT0jy-Cs7Np-EbSU&usqp=CAU"},
//     {imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj5aKmUCuAl1M21-qcBI6gmQQjFpXc4EHFITP-D4zAa8CRxaoCaZOLJcZA4emAnpbjbjg&usqp=CAU"},
//   ];

//   return (
//     <>
//           <div id="slide23" ref={slideRef}>
//             {images.map((item) => (
//               <div
//               onClick={() => {
//                 autoScrollLeft()
//                 handleImageClick(item.imgUrl);
//                 }}
//                 key={item.id}
//                 className="item23"
//                 style={{ backgroundImage: `url(${item.imgUrl})` }}
//               >
//               </div>
//             ))}
//           </div>

//           <div className="buttons123">
//             <button id="prev" onClick={handleClickPrev} style={{background:`${storedColor}`}}>
//               <FontAwesomeIcon icon={faAngleLeft} className='buttons123-icon'/>
//             </button>
//             <button id="next" onClick={handleClickNext} style={{background:`${storedColor}`}}>
//               <FontAwesomeIcon icon={faAngleRight} className='buttons123-icon'/>
//             </button>
//           </div>
//     </>
//   );
// }

// const Images2 = ({handleImageClick, storedColor}) => {
//   const slideRef = useRef(null);

// const handleClickNext = () => {
//   let items = slideRef.current.querySelectorAll(".item23");
//   slideRef.current.appendChild(items[0]);
// };

// const handleClickPrev = () => {
//   let items = slideRef.current.querySelectorAll(".item23");
//   slideRef.current.prepend(items[items.length - 1]);
// };

// // const autoScrollLeft = () => {
// //   setInterval(() => {
// //     handleClickNext();
// //   }, 9000);
// // };

// // useEffect(() => {
// //   autoScrollLeft();
// // }, []);

// const [currentIndex, setCurrentIndex] = useState(0);

// const autoScrollLeft = () => {
//   setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//   handleClickNext();
// };
// useEffect(() => {
//   const intervalId = setInterval(autoScrollLeft, 3000);

//   return () => clearInterval(intervalId);
// }, [currentIndex]);

// const images = [
//   {imgUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8HWaO3xEjMoH1cxMDlMg8_bExFIZyd1V-QA&usqp=CAU"},
//   {imgUrl: "https://www.sahivalue.com/product-images/14+pro+max.jpg/293890000021697778/400x400"},
//   {imgUrl: "https://images.saymedia-content.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cq_auto:eco%2Cw_1200/MTk3MjI5OTczMDA0NjI0OTc5/cute-pet-names.png"},
//   {imgUrl: "https://shop.gkwretail.com/cdn/shop/products/Designer-Sofa-Set-in-Fabric-L-Shape_2.png?v=1651899140"},
//   {imgUrl: "https://content3.jdmagicbox.com/comp/goa/f2/0832px832.x832.220527185136.f5f2/catalogue/ak-rental-car-and-bike-aquem-goa-car-rental-lxcqq8w6qy.jpg"},
//   {imgUrl: "https://www.gooezy.com/uiassets/images/serviceslist/officecleaning.jpg"},
// ];

// return (
//   <>
//         <div id="slide23" ref={slideRef}>
//           {images.map((item) => (
//             <div
//               onClick={() => {
//               autoScrollLeft()
//               handleImageClick(item.imgUrl);
//               }}
//               key={item.id}
//               className="item23"
//               style={{ backgroundImage: `url(${item.imgUrl})` }}
//             >
//             </div>
//           ))}
//         </div>

//         <div className="buttons123">
//           <button id="prev" onClick={handleClickPrev} style={{background:`${storedColor}`}}>
//             <FontAwesomeIcon icon={faAngleLeft} className='buttons123-icon'/>
//           </button>
//           <button id="next" onClick={handleClickNext} style={{background:`${storedColor}`}}>
//             <FontAwesomeIcon icon={faAngleRight} className='buttons123-icon'/>
//           </button>
//         </div>
//   </>
// );
// }

// const Gallery = () => {

//   const handleImageClick = (imageUrl) => {
//     window.open(imageUrl, '_blank');
//   };

//   const storedColor = localStorage.getItem('selectedColor');

// return (
//   <>
//   <Images1 handleImageClick={handleImageClick} storedColor={storedColor}/>
//   <br/>
//   <Images2 handleImageClick={handleImageClick} storedColor={storedColor}/>
//   </>
// )
// };

// export default Gallery;
