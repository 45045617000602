import React, { useState, useEffect } from 'react';
import { BiColorFill } from 'react-icons/bi';
import { MdClose, MdColorLens } from 'react-icons/md';
import './Color.css';
import { CloseButton } from 'react-bootstrap';

const Color = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#092144');
  const storedColor = localStorage.getItem('selectedColor');
  
  // Load color from local storage on component mount
  useEffect(() => {
    if (storedColor) {
      setSelectedColor(storedColor);
    //   document.body.style.backgroundColor = storedColor;
    }
    else {
      localStorage.setItem('selectedColor', '#092144');
    }
  }, []);

  const colorOptions = [
    // { name: 'Blue', hex: '#3498db' },
    // { name: 'Green', hex: '#2ecc71' },
    // { name: 'Yellow', hex: '#f1c40f' },
    // { name: 'Navy', hex: '#092144' },
    // { name: 'Purple', hex: '#9b59b6' },
    // { name: 'Orange', hex: '#e67e22' },
    { name: 'Blue', hex: 'linear-gradient(to right, #3498db, #2980b9)' },
    { name: 'Green', hex: 'linear-gradient(to right, #2ecc71, #27ae60)' },
    { name: 'Yellow', hex: 'linear-gradient(to right, #f1c40f, #f39c12)' },
    { name: 'Navy', hex: 'linear-gradient(to right, #092144, #053553)' },
    { name: 'Purple', hex: 'linear-gradient(to right, #9b59b6, #8e44ad)' },
    { name: 'Orange', hex: 'linear-gradient(to right, #e67e22, #d35400)' },
    { name: 'Teal', hex: 'linear-gradient(to right, #4AC29A, #BDFFF3)' },
    { name: 'Pink', hex: 'linear-gradient(to right, #F06EA9, #F6B5D1)' },
    { name: 'Red', hex: 'linear-gradient(to right, #FF416C, #FF4B2B)' },
    { name: 'Gray', hex: 'linear-gradient(to right, #BDC3C7, #2C3E50)' },
    
  ];

  const handleButtonClick = () => {
    setShowPopup(true);
  };
  const handleClose= () => {
    setShowPopup(false);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color.hex);
    setShowPopup(false);
    // document.body.style.backgroundColor = color.hex;

    // Save selected color to local storage
    localStorage.setItem('selectedColor', color.hex);
    window.location.reload();
  };

  return (
    <div className="App123">
     <MdColorLens onClick={handleButtonClick} style={{fontSize:"25px", cursor:'pointer'}}/>
      {showPopup && (
        <div className="popup" >
          <MdClose style={{position:"absolute",marginBottom:"600px",color:"red",fontSize:"35px",fontWeight:"bold",cursor:"pointer"}} onClick={handleClose}/>

          <h4 style={{color:"white",marginBottom:"20px"}}>Select Background Color:</h4>
          <div className="color-options">
            {colorOptions.map((color, index) => (
              <div
                key={index}
                className="color-option"
                style={{ background: color.hex}}
                onClick={() => handleColorSelect(color)}
              >
                {color.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Color;
