import React, { useState } from "react";
import "./Adminsignup.css";
import { useNavigate } from "react-router-dom";

const AdminSignup = () => {
  const [step, setStep] = useState(1);

  const [submissionMessage, setSubmissionMessage] = useState('');
  const navigate = useNavigate(); // Create navigate function

  const [step1Error, setStep1Error] = useState(false)
  const [orgError, setorgError] = useState(false)
  const [urlError, setUrlError] = useState(false)
  const [step3Error, setStep3Error] = useState(false)
  const [tagError, settagError] = useState(false)
  const [aboutError, setaboutError] = useState(false)
  const [step4Error, setStep4Error] = useState(false)
  const [emailError, setEmailError] = useState(""); // State to track email errors

  // State to hold form data
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    logo: null,
    company_type: "",
    company_since: "",
    color: "-",
    company_address: "",
    social_media_links: {
      facebook: "https://",
      instagram: "https://",
      linkedin: "https://",
      twitter: "https://",
    },
    about: "",
    images: [],
    tagline: "",
    numOfEmployees: "",
    domain: "",
    user_id: "",
    email: "",
    password: "",
  });

  // Handle input change for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStep1Error(false)
    setStep3Error(false)
    setStep4Error(false)
    setorgError(false)
    // Check email validation when the email field is updated
    if (name === "email") {
      validateEmail(value, formData.domain);
    }
    if (name === "password") {
      validatePassword(value);
    }

    if (name === "tagline") {
      if (value.length < 15) {
        settagError(true)
      }
      if (value.length >= 15) {
        settagError(false)
      }
    } else if (name === "about") {
      if (value.length < 30) {
        setaboutError(true)
        // return;
      }
      if (value.length >= 30) {
        setaboutError(false)
        // return;
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email, domain) => {
    if (!email.includes("@")) {
      setEmailError("Invalid email format");
      return;
    }

    // Extract the domain part of the email (after @)
    const emailDomain = email.toLowerCase();

    // Remove common prefixes (e.g., "www.") and suffixes (e.g., ".com", ".in", etc.)
    const extractedDomain = domain
      .replace(/^(https?:\/\/)?(www\.)?/i, "") // Remove "http://", "https://", and "www."
      .replace(/(\.[a-z]{2,})+$/i, "") // Remove domain suffixes like ".com", ".in", ".org", etc.
      .trim()
      .toLowerCase();


    if (!extractedDomain) {
      setEmailError("Invalid domain format");
      return;
    }

    // Check if the extracted domain name exists in the email
    if (!emailDomain.includes(extractedDomain)) {
      setEmailError(`Email must contain '${extractedDomain}'`);
    } else {
      setEmailError(""); // Clear error if valid
    }
  };


  const [passwordError, setPasswordError] = useState("");

  const validatePassword = (password) => {
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!strongPasswordRegex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
    } else {
      setPasswordError(""); // Clear error if valid
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  // // Handle input change for social media links
  // const handleSocialMediaChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData(prevFormData => ({
  //     ...prevFormData,
  //     social_media_links: {
  //       ...prevFormData.social_media_links,
  //       [name]: value,
  //     },
  //   }));
  // };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setUrlError(false);

    // Regular expression to validate URL format
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Check if the input value matches the URL format
    if (value === "" || urlRegex.test(value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        social_media_links: {
          ...prevFormData.social_media_links,
          [name]: value,
        },
      }));
    } else {
      setUrlError(true);
    }
  };

  // Function to format social media links as JSON string
  const formatSocialMedia = (socialMediaObj) => {
    return JSON.stringify(socialMediaObj);
  };


  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    // Step 4 data validation
    if (
      !formData.domain ||
      !formData.user_id ||
      !formData.email ||
      !formData.password
    ) {
      isValid = false;
      setStep4Error(true);
    }
    // Ensure email validation passes before submitting
    if (emailError) {
      return;
    }
    if (isValid) {
      // Send formData to API
      try {
        const form = new FormData();
        for (let key in formData) {
          if (key === "social_media_links") {
            form.append(key, formatSocialMedia(formData[key]));
          } else {
            form.append(key, formData[key]);
          }
        }
        const response = await fetch(
          "https://hrmsadmin.hola9.com/account/adminapi/",
          {
            method: 'POST',
            body: form,
          });
        const data = await response.json();
        if (response.ok) {
          // ✅ Success: Move to the next step
          setStep(5);
          setSubmissionMessage('Form signup successful.');
        } else {
          // ❌ Failed: Handle error response from the backend
          if (data.errors) {
            // Backend returns validation errors as an object { field: ["error1", "error2"] }
            const errorMessages = Object.entries(data.errors)
              .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
              .join("\n");

            alert(`Validation Errors:\n${errorMessages}`);
            console.log("Validation Errors:", data.errors);
          } else if (data.response) {
            // Backend returns a response array of errors
            alert(`Error: ${data.response.join(", ")}`);
            console.log(data.response);
          } else {
            // General error message if no structured errors exist
            alert(`Error: ${data.message || "Failed to signup. Please try again."}`);
            console.log("General Error:", data);
          }
        }
      } catch (error) {
        setSubmissionMessage('Failed to signup.');
      }
    }
  };

  // Function to handle moving to the next step with validation
  const handleNext = (e) => {
    e.preventDefault();
    let isValid = true;

    if (step === 1) {
      // Check if any required fields are empty
      if (
        !formData.name ||
        !formData.logo ||
        !formData.company_type ||
        !formData.company_since ||
        !formData.company_address
      ) {
        isValid = false;
        setStep1Error(true)
      }
    } else if (step === 2) {
      // Check if organization name is empty
      if (!formData.organization) {
        isValid = false;
        setorgError(true);
      }

      // Check if any social media links are empty or set to 'https://YourLink.com/'
      const socialMediaLinks = formData.social_media_links;
      for (let key in socialMediaLinks) {
        if (!socialMediaLinks[key] || socialMediaLinks[key] === "https://") {
          isValid = false;
          setUrlError(true);
          break; // Exit loop if any empty or default social media link found
        }
      }
    } else if (step === 3) {
      if (
        !formData.about ||
        !formData.images ||
        !formData.tagline ||
        !formData.numOfEmployees
      ) {
        isValid = false;
        setStep3Error(true)
      }
    } else if (step === 4) {
      if (
        !formData.domain ||
        !formData.user_id ||
        !formData.email ||
        !formData.password
      ) {
        isValid = false;
        setStep4Error(true)
      }
    }

    if (isValid) {
      setStep(step + 1);
    }
  };

  // Function to handle clicking on step numbers
  const handleStepClick = (stepNumber) => {
    if (submissionMessage !== 'Form signup successful.') {
      let isValid = true;

      if ((stepNumber === 2 || stepNumber === 3 || stepNumber === 4) && step === 1) {
        if (
          !formData.name ||
          !formData.logo ||
          !formData.company_type ||
          !formData.company_since ||
          !formData.company_address
        ) {
          isValid = false;
          setStep1Error(true)
        }
      } else if ((stepNumber === 1 || stepNumber === 3 || stepNumber === 4) && step === 2) {
        if (!formData.organization) {
          isValid = false;
          setorgError(true)
        }

        const socialMediaLinks = formData.social_media_links;
        for (let key in socialMediaLinks) {
          if (
            !socialMediaLinks[key] ||
            socialMediaLinks[key] === "https://YourLink.com/"
          ) {
            isValid = false;
            setUrlError(true);
            break;
          }
        }
      }
      else if ((stepNumber === 1 || stepNumber === 2 || stepNumber === 3) && step === 3) {
        if (
          !formData.about ||
          !formData.images ||
          !formData.tagline ||
          !formData.numOfEmployees
          // !formData.domain ||
          // !formData.user_id ||
          // !formData.email ||
          // !formData.password
        ) {
          isValid = false;
          setStep3Error(true)
        }
      }

      if (isValid) {
        setStep(stepNumber);
      }
    }
  };


  // Function to handle moving to the previous step
  const handlePrev = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };


  // JSX for rendering the form
  return (
    <div className="signup-form">
      <h2 style={{ marginBottom: '10px' }}>Sign up for - Free</h2>
      <div className="steps-progress">
        {/* Step indicators */}
        {/* <div className={`connector full ${step >= 1 ? 'filled' : ''}`} /> */}
        <div className="step-container" onClick={() => handleStepClick(1)}>
          <div className={`step ${step >= 1 ? "active" : ""}`}>1</div>
        </div>
        {/* <div className={`connector ${step >= 2 ? 'full' : ''}`} /> */}
        <div className={`connector full ${step >= 2 ? "filled" : ""}`} />
        <div className="step-container" onClick={() => handleStepClick(2)}>
          <div className={`step ${step >= 2 ? "active" : ""}`}>2</div>
        </div>
        {/* <div className={`connector ${step >= 3 ? 'full' : ''}`} /> */}
        <div className={`connector full ${step >= 3 ? "filled" : ""}`} />
        <div className="step-container" onClick={() => handleStepClick(3)}>
          <div className={`step ${step >= 3 ? "active" : ""}`}>3</div>
        </div>
        <div className={`connector full ${step >= 4 ? "filled" : ""}`} />
        <div className="step-container" onClick={() => handleStepClick(4)}>
          <div className={`step ${step >= 4 ? "active" : ""}`}>4</div>
        </div>
        {submissionMessage && (
          <>
            <div className='connector full' style={{ background: step >= 5 ? (submissionMessage === 'Failed to signup.' ? 'red' : 'green') : '#f2f2f2' }} />
            <div className="step-container">
              <div className={`step ${step >= 5 ? "active" : ""}`} style={{ background: submissionMessage === 'Failed to signup.' ? 'red' : 'green', color: 'white' }}> {submissionMessage === 'Failed to signup.' ? 'Error' : 'Sucess'}</div>
            </div>
          </>
        )}
      </div>



      {/* Form */}
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          // Step 1 fields
          <>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />

            <label>Logo:</label>
            <input type="file" name="logo" onChange={handleFileChange} />

            <label>CompanyType:</label>
            <input
              type="text"
              name="company_type"
              value={formData.company_type}
              onChange={handleChange}
            />

            <label>Company Since:</label>
            <input
              type="date"
              name="company_since"
              value={formData.company_since}
              onChange={handleChange}
            />

            <label>Company Address:</label>
            <input
              type="text"
              name="company_address"
              value={formData.company_address}
              onChange={handleChange}
            />
            {step1Error === true ? <div style={{ color: 'red', textAlign: 'end' }}>Please fill in all required fields</div> : null}

            <div className="prev-next-btn" style={{ justifyContent: 'right' }}>
              <button onClick={handleNext}>Next</button>
            </div>
          </>
        )}
        {step === 2 && (
          // Step 2 fields
          <>
            <label>Organization Name:</label>
            <input
              type="text"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
            />
            <label>Facebook:</label>
            <input
              type="text"
              name="facebook"
              value={formData.social_media_links.facebook}
              onChange={handleSocialMediaChange}
            />

            <label>Instagram:</label>
            <input
              type="text"
              name="instagram"
              value={formData.social_media_links.instagram}
              onChange={handleSocialMediaChange}
            />

            <label>LinkedIn:</label>
            <input
              type="text"
              name="linkedin"
              value={formData.social_media_links.linkedin}
              onChange={handleSocialMediaChange}
            />

            <label>Twitter:</label>
            <input
              type="text"
              name="twitter"
              value={formData.social_media_links.twitter}
              onChange={handleSocialMediaChange}
            />
            {orgError === true ? <div style={{ color: 'red', textAlign: 'end' }}>Please fill in organization name</div> : null}
            {urlError === true ? <div style={{ color: 'red', textAlign: 'end' }}>Please enter a valid URL</div> : null}
            <div className="prev-next-btn">

              <button onClick={handlePrev}>Prev</button>
              <button onClick={handleNext}>Next</button>

            </div>


          </>
        )}
        {step === 3 && (
          // Step 3 fields
          <>
            <label>About:</label>
            <textarea
              name="about"
              value={formData.about}
              onChange={handleChange}
            />
            {aboutError === true ? <div style={{ color: 'red' }}>About must have at least 30 characters</div> : null}

            <label>Upload Image:</label>
            <input
              type="file"
              name="images"
              multiple
              onChange={handleFileChange}
            />

            <label>Tagline:</label>
            <input
              type="text"
              name="tagline"
              value={formData.tagline}
              onChange={handleChange}
            />
            {tagError === true ? <div style={{ color: 'red' }}>Tagline must have at least 15 characters</div> : null}

            <label>Number of Employees:</label>
            <input
              type="number"
              name="numOfEmployees"
              value={formData.numOfEmployees}
              onChange={handleChange}
            />
            {step3Error === true ? <div style={{ color: 'red', textAlign: 'end' }}>Please fill in all required fields</div> : null}

            <div className="prev-next-btn">

              <button onClick={handlePrev}>Prev</button>
              <button onClick={handleNext}>Next</button>

            </div>
          </>
        )}
        {step === 4 && (
          <>
            <label>Domain:</label>
            <input
              type="text"
              name="domain"
              placeholder="www.xyz.com"
              value={formData.domain}
              onChange={handleChange}
            />

            <label>User ID:</label>
            <input
              type="text"
              name="user_id"
              value={formData.user_id}
              onChange={handleChange}
            />

            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {emailError && <div style={{ color: "red", fontSize: "14px" }}>{emailError}</div>}

            <label>Password:</label>
            <input
              type="text"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {passwordError && <div style={{ color: "red", fontSize: "14px" }}>{passwordError}</div>}
            {step4Error === true ? <div style={{ color: 'red', textAlign: 'end' }}>Please fill in all required fields</div> : null}
            <div className="prev-next-btn">

              <button onClick={handlePrev}>Prev</button>
              <button onClick={handleSubmit} style={{ background: 'darkblue', color: 'white' }}>Submit</button>

            </div>

          </>
        )}
        <p id="description" className="main-description" style={{ height: '4px', }}>
          Hola9 Classifieds India Private Ltd.
        </p>
      </form>
      {step === 5 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <div className="submission-message" style={{ width: '250px', height: '160px', boxShadow: '0 1px 5px lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%', color: setSubmissionMessage === 'Failed to signup' ? 'red' : 'green' }}>{submissionMessage}</div>
          {/* Display Submitted Data */}
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <h3>Admin Login Credentials:</h3>
            <p><strong>Username:</strong> {formData.user_id}</p>
            <p><strong>Organization:</strong> {formData.organization}</p>
            <p><strong>Password:</strong> {formData.password}</p>
          </div>
          <div>
            <button style={{ color: 'whitesmoke', padding: '10px', backgroundColor: 'darkblue' }} onClick={() => navigate('/login')}>Go Home</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSignup;
