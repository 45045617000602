import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from "../section/navbar/Navbar"
import Header from "../section/header/Header"
import About from "../section/about/About"
import FAQs from "../section/faqs/FAQs"
import Services from "../section/services/Services"
import Contact from "../section/contact/Contact"
import Portfolio from "../section/portfolio/Portfolio"
import Testimonials from "../section/testimonials/Teastmonials"
import Footer from "../section/footer/Footer"
import Logindetails from './login/LoginDetails'
import LoginDetails from './login/LoginDetails'
import { isMobile } from 'react-device-detect'
import Clock from './Userdashboard/MapLocationRow '
import BackgroundAnimation from './Dashboard/Backgroundanimation'
import "./card.css"
import { ParallaxProvider } from 'react-scroll-parallax'
import UserProfileCard from '../section/UserProfileCard/UserProfileCard'
import MainHome from './Home/MainHome'
import AdminSignup from './Adminsignup'
import ProjectsInHome from './Home/ProjectsInHome'
import OurTrustedCompanies from './Home/OurTrustedCompanies/OurTrustedCompanies'
import FeaturesHome from './Home/FeaturesHome'
// import Loginpro from './Userdashboard/Loginpro'
const Home = () => {

 return (
  <>
  <div style={{marginTop:'70px'}}></div>
  {/* <Navbar/> */}
  <MainHome />
  <ProjectsInHome />
  <About/>
  <FeaturesHome />
  <Contact/>
  <OurTrustedCompanies />
  </>
  )
}

export default Home;




{/* <div >
        
        {/* <div > 
         <Link to="/UserLogin">
       
             <button style={{padding:"10px",borderRadius:"10px",backgroundColor:"navy",color:"white",marginRight:"20px"}}>Empolyee Login</button>
             </Link>
         <Link to="/AdminLogin">
       
             <button style={{padding:"10px",borderRadius:"10px",backgroundColor:"green",color:"white",marginLeft:"20px"}}>Admin Login</button>
             </Link>
        </div> 
 {/* 
   <span style={{color:"red"}}>
   Apologies,This Website is not accessible through mobile platforms
 </span> 
 
       <ParallaxProvider>
 
   <div className="app-container">
        <Navbar/>
       <Header/>
        <UserProfileCard />
       <About/>
       {/* <FAQs/> 
       <Services/>
       {/* <Portfolio/> 
       {/* <Testimonials/> 
       <Contact/>
       {/* <Loginpro/> 
       </div>
       </ParallaxProvider>
       {/* <FloatingNav/> 
         
</div> */}