import React, { useState, useEffect } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { Modal } from "antd";
// import { Delete } from "@mui/icons-material";
import axios from "axios";
import { MdDelete } from "react-icons/md";

const NewsfeedHome = (props) => {
  const storedColor = localStorage.getItem("selectedColor");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible22, setIsModalVisible22] = useState(false);
  const [postContent, setPostContent] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [feedData, setFeedData] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch feed data on component mount
    fetchFeedData();
  }, []); // Empty dependency array to run once on mount

  const fetchFeedData = () => {
    fetch("https://hrmsadmin.hola9.com/account/feeds/")
      .then((response) => response.json()) // Parse response as JSON
      .then((data) => {
        setFeedData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching feed data:", error);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModal2001 = () => {
    setIsModalVisible22(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleOk2001 = () => {
    setIsModalVisible22(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCancel2001 = () => {
    setIsModalVisible22(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();

    // Add leading zero if necessary
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    showModal2001();
  };

  const handlePost = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("desc", postContent);
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    formData.append("date", getCurrentDateTime()); // Current date and time
    formData.append("name", props.name2001); // Use props.name2001 for name
    formData.append("organization", props.organization); // Use props.organization

    fetch("https://hrmsadmin.hola9.com/account/feeds/", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          console.log("Post successful", formData);
          setPostContent("");
          setSelectedFile(null); // Reset to null after successful post
          fetchFeedData(); // Fetch updated feed data after posting
          alert("Your Post successful");
          setIsLoading(false);
        } else {
          console.error("Post failed");
          alert("Your Post failed");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDeleteClick = (id) => {
    console.log("Deleting comment with id:", id);
    const formData = new FormData();
    formData.append("name", props.name2001);
    formData.append("id", id);

    axios
      .delete("https://hrmsadmin.hola9.com/account/feeds/", {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const updatedComments = feedData.filter((comment) => comment.id !== id);
        setFeedData(updatedComments);
      })
      .catch((error) => {
        console.error("Error deleting comment:", error);
      });
  };

  return (
    <div className="newsfeed-container">
      <h4 className="newsfeed-title">News Feed</h4>
      <span>
        <textarea
          className="newsfeed-textarea"
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
        ></textarea>
      </span>
      <span className="newsfeed-post-button">
        <span className="uploadicon" onClick={showModal}>
          <AiOutlineUpload className="uploadicon2001" />
        </span>
        <button
          className="newsfeed-post-buttonM"
          style={{ background: storedColor }}
          onClick={handlePost}
        >
          Post
        </button>
      </span>
      <Modal
        title="Upload File"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <input
          type="file"
          onChange={handleFileChange}
          className="upload-input"
        />
      </Modal>
      <Modal
        title="Image Preview"
        open={selectedImage !== ""}
        onCancel={() => setSelectedImage("")}
        footer={null}
      >
        <img
          src={selectedImage}
          alt="Preview"
          style={{ width: 400, maxHeight: 400 }}
        />
      </Modal>

      {isLoading ? (
        <center className="loading-indicator">
          <img
            src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
            alt="---loading"
            className="emp-loading"
            style={{ margin: "0" }}
          />
        </center>
      ) : (
        <>
          <div style={{ overflow: "auto", height: "70%", marginRight: '2% ' }}>
            {feedData.map((data, index) => (
              <div key={index} className="newsfeed-message">
                <img
                  src={"https://hrmsadmin.hola9.com" + data.profile.image}
                  className="newsfeed-image"
                  alt="img"
                />
                <span className="newsfeed-message-content" style={{ background: 'rgba(0, 0, 0, 0.01)', padding: '0 10px 0 5px  ' }}>
                  <span className="newsfeed-message-header">
                    <b className="newsfeed-message-name">{data.name}</b>
                    <i className="newsfeed-message-time">{data.date}</i>
                  </span>
                  {props.name2001 === data.name && (
                    <MdDelete
                      style={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "13px",
                        float: 'right'
                      }}
                      onClick={() => handleDeleteClick(data.id)}
                    />
                  )}
                  <p className="newsfeed-message-body">{data.desc}</p>
                  <span onClick={showModal2001}>
                    {data.image && (
                      <img
                        src={"https://hrmsadmin.hola9.com" + data.image}
                        className="newsfeed-image"
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "8px",
                        }}
                        alt="img"
                        onClick={() =>
                          handleImageClick(
                            "https://hrmsadmin.hola9.com" + data.image
                          )
                        }
                      />
                    )}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default NewsfeedHome;
