import React, { useEffect } from 'react';
import Navbar from '../../section/navbar/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import LoginDetails from '../login/LoginDetails';
import './AdminDashboard.css'; // Import your custom CSS file for styling

const AddAdmin = () => {

  //navigation code start for adimin login
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInadmin = localStorage.getItem("loggedInadmin");

    if (!loggedInadmin) {
      navigate("/AdminLogin");
    }
  }, []);
  //navigation code end for adimin login
  return (
    <>
      <Navbar />
      <div className="features-container">
        <div className="features-heading">
          <h3>✨ Awesome Features ✨</h3>        </div>
        <div className="features-buttons">
          <Link to="/salaryslip">
            <button className="task-btn">Generate Salary Slip 📈</button>
          </Link>
          <Link to="/allslips">
            <button className="task-btn">Print Salary Slip 🖨️</button>
          </Link>
          <Link to="https://hrmsadmin.hola9.com/admin/account/employeelogin/add/" target='_blank'>
            <button className="task-btn">Add User 👤</button>
          </Link>
          {/* <Link to="/atteror">
            <button className="task-btn">Attendances Error ❌</button>
          </Link> */}
          {/* <a href="mailto:gopikrishna@hola9.com,ramya.hola9@outlook.com,akshathaml.nayak@outlook.com"><button className="task-btn">Attendances Error ❌</button></a> */}
          <Link to="/emp">
            <button className="task-btn">Employees 👨‍💼</button>
          </Link>
          <Link to="/mailto">
            <button className="task-btn">Send Mail 📧</button>
          </Link>
          <Link to="/LeaveCard">
            <button className="task-btn">Leaves 📅 </button>
          </Link>
        </div>
      </div>
      {/* <LoginDetails/> */}
    </>
  );
};

export default AddAdmin;
