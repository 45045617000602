import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Loggedout = () => {
    const navigate=useNavigate();
    useEffect(() => {
      const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
      const logintl = JSON.parse(localStorage.getItem("logintl"));
      const logintesting = JSON.parse(localStorage.getItem("logintesting"));
      const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
      const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
      const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
    
      console.log("loggedInUser", loggedInUser);
      console.log("loggedInadmin", loggedInadmin);
      console.log("logintl", logintl);
      console.log("logintesting", logintesting);
      console.log("logintltest", logintltest);
      console.log("loginbackend", loginbackend);
      console.log("loginbackendtl", loginbackendtl);
      // Check if any of the items is not present
      if (!loggedInUser && !loggedInadmin && !logintl && !logintesting && !logintltest && !loginbackend && !loginbackendtl) {
        navigate("/login");
        
      }
    }, []);
    
 
}

export default Loggedout;