import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const OfflineListener = () => {
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      navigate('/nointernet');
    }
  }, [isOnline, navigate]);

  return null; // This component doesn't render anything
};

export default OfflineListener;


