import React, { useState } from "react";
import "../navDropdown.css";
import Navbar from "../../../section/navbar/Navbar";
import { FaRegTrashAlt } from "react-icons/fa";
import NotificationData from "./NotificationData";
import NotificationCard from "./NotificationCard";

const Notification = () => {
  const [All, setAll] = useState(true);
  const [Mentioned, setMentioned] = useState(false);

  const storedColor = localStorage.getItem('selectedColor');


  const handleAll = () => {
    setAll(true);
    setMentioned(false);
  };

  const handleMentioned = () => {
    setMentioned(true);
    setAll(false);
  };

  const ActiveStyles = {
    background: storedColor,
    color: "white",
  };

  const Styles = {
    background: 'unset',
  };

  return (
    <>
      <Navbar />
      <div
        style={{ borderBottom: "2px solid black", marginBottom: "10px" }}
        className="nav-m-top"
      >
        <button
          style={All ? { ...ActiveStyles } : { ...Styles }}
          onClick={handleAll}
          className="Notification-btn"
        >
          All
        </button>

        <button
          style={Mentioned ? { ...ActiveStyles } : { ...Styles }}
          className="Notification-btn"
          onClick={handleMentioned}
        >
          Mentions
        </button>
      </div>

      {All && (
        <div className="width nav-m-top">
          {NotificationData.map((data) => (
            <NotificationCard key={data.id} className="All-Notification">
              <div style={{ width: "fitContent" }}>
                <img
                  src={data.img}
                  width={60}
                  height={60}
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ paddingLeft: "20px", width: "100%" }}>
                <div>
                  <h5>{data.title}</h5>
                  <i
                    style={{
                      float: "right",
                      marginTop: "-20px",
                      paddingRight: "10px",
                    }}
                  >
                    {data.time}
                  </i>
                </div>
                <b style={{ fontSize: "90%" }}>{data.subTitle}</b>
                <p>{data.para}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "red",
                  width: "70px",
                }}
              >
                <FaRegTrashAlt />
              </div>
            </NotificationCard>
          ))}
        </div>
      )}

      {Mentioned && (
        <>
          <div className="width nav-m-top">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src="https://icon-library.com/images/at-icon-png/at-icon-png-20.jpg" />
              <p>no mentions yet</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Notification;
