import React, { useState, useEffect } from 'react';
// import './Parent.css';

const Projects = () => {
  const storedColor = localStorage.getItem("selectedColor");
  const [formData, setFormData] = useState({
    projectname: '',
    logo: null,
    employeename: [], // Modify to store selected employee names
  });


  const [errors, setErrors] = useState({}); // Define errors state

  const [employeeNames, setEmployeeNames] = useState([]); // State to store employee
  useEffect(() => {
    // Fetch employee names from API
    const fetchEmployeeNames = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/account/loginprofile/');
        if (!response.ok) {
          throw new Error('Failed to fetch employee names');
        }
        const data = await response.json();
        setEmployeeNames(data); // Set fetched employee names to state
      } catch (error) {
        console.error('Error fetching employee names:', error.message);
      }
    };

    fetchEmployeeNames();
  }, []);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const val = type === 'file' ? e.target.files[0] : value;
    setFormData(prevState => ({
      ...prevState,
      [name]: val
    }));
  };

  const handleSelectChange = (e) => {
    const selectedNames = Array.from(e.target.selectedOptions, option => {
      return { name: option.value }; // No need for ID
    });
    setFormData(prevState => ({
      ...prevState,
      employeename: [...prevState.employeename, ...selectedNames],
    }));
  };


  const removeName = (nameToRemove) => {
    setFormData(prevState => ({
      ...prevState,
      employeename: prevState.employeename.filter(employee => employee.name !== nameToRemove),
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const errors = validateForm(formData);
      if (Object.keys(errors).length === 0) {
        const formDataToSend = new FormData();
        formDataToSend.append("projectname", formData.projectname);
        formDataToSend.append("logo", formData.logo);
        formDataToSend.append("employeename", JSON.stringify(formData.employeename)); // Convert array to string before sending

        const response = await fetch('https://hrmsadmin.hola9.com/account/projectdetail/', {
          method: 'POST',
          body: formDataToSend
        });

        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
        console.log('Form submitted successfully');
        setFormData({
          projectname: '',
          logo: null,
          employeename: [], // Clear selected employee names after successful submission
        });
        setErrors({});
      } else {
        setErrors(errors);
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.projectname.trim()) {
      errors.projectname = 'Project Name is required';
    }
    return errors;
  };
  return (

    <div
      style={{
        background: "rgba(0, 0, 0, 0.4)",
        width: "100%",
        padding: "10% 0",
      }}
    >
      <form
        className="leave-form"
        onSubmit={handleSubmit}
        style={{
          background: "white",
          padding: "40px",
          borderRadius: "10px",
          width: "70%",
          height: "auto",
          margin: "auto",
        }}
      >
        <div>
          <h3
            style={{
              textAlign: "center",
              background: storedColor,
              backgroundClip: "text",
              color: "transparent",
            }}
          >Add Projects</h3>
        </div>
        <div>
          <label htmlFor="projectname"><span style={{ color: "red" }}>P</span>roject Name:</label>
          <input
            type="text"
            id="projectname"
            name="projectname"
            value={formData.projectname}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="logo"><span style={{ color: "red" }}>L</span>ogo:</label>
          <input
            type="file"
            id="logo"
            name="logo"
            accept="image/*"
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="employeename"><span style={{ color: "red" }}>E</span>mployee Name:</label>
          <select
            id="employeename"
            name="employeename"
            multiple
            onChange={handleSelectChange}
          >
            {employeeNames.map(({ id, name }) => (
              <option key={id} value={name}>{name}</option>
            ))}
          </select>
        </div>
        <div style={{ width: 'fit-content', height: 'fit-content', display: 'flex', flexWrap: 'wrap' }}>
          <label>Selected Names:</label>
          {formData.employeename && formData.employeename.map((employee, index) => (
            <span key={index} style={{ background: 'lightgray', margin: '2px' }}>
              {employee.name}
              <i type="button" onClick={() => removeName(employee.name)} style={{ color: 'red', padding: '5px', cursor: 'pointer' }}>❌</i>
            </span>
          ))}

        </div>
        <button className='btn2001' type="submit" style={{ background: storedColor, color: 'white' }}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default Projects;