import {SiAdobexd} from "react-icons/si";
import {RiReactjsLine} from "react-icons/ri";
import {AiFillAppstore,AiFillAlert} from "react-icons/ai";

const data=[
    {id:1,icon:<SiAdobexd/>,title:"Ecommerce Platform",desc:"An e-commerce platform is a digital marketplace where businesses can showcase and sell their products or services online."},
    {id:2,icon:<RiReactjsLine/>,title:"Frontend Developement",desc:"Frontend development involves creating the visual and interactive elements of a website or application that users interact with directly"},
    {id:2,icon:<AiFillAlert/>,title:"Backend Developement",desc:"Backend development is the behind-the-scenes work that powers websites and applications. Backend developers work on server-side scripting, databases, and application logic"},
    {id:4,icon:<AiFillAppstore/>,title:"Testing",desc:"Testing is a vital phase in software development where professionals evaluate the functionality, performance, and reliability of a system"},
]
export default data;


