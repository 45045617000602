import React, { useState } from "react";
import ApplayLeavesForm from "../../Dashboard/ApplyLeavesForm";
const LeaveFrom = () => {
  const storedColor = localStorage.getItem("selectedColor");

  const [openLeaveForm, setopenLeaveForm] = useState(false);

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  // const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));

  return (
    <>
      <span className="width leave-top-bar" style={{ minHeight: "45px" }}>
        <h5>Welcome to Leave Portal</h5>
        {loggedInUser ||
        logintesting ||
        loginbackend ||
        logintl ||
        loginbackendtl ||
        logintltest ? (
          <button
            onClick={() => setopenLeaveForm(true)}
            style={{ background: storedColor }}
          >
            Apply Leave
          </button>
        ) : null}
      </span>

      {openLeaveForm ? (
        <div className="popup">
          <ApplayLeavesForm close={() => setopenLeaveForm(false)} />
        </div>
      ) : null}
    </>
  );
};

export default LeaveFrom;
