import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./UserLogin.css";
import Navbar from "../../section/navbar/Navbar";

const Login = () => {
  const [email, setEmail] = useState("");
  const [name, setname] = useState("");
  const [org, setorg] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessing1, setIsProcessing1] = useState(false);

  useEffect(() => {
    setIsProcessing1(true);

    const fetchData = async () => {
      try {
        const response = await fetch('https://hrmsadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData(apiData);
        setIsProcessing1(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsProcessing1(true);

      }
    };

    fetchData();
  }, []);

  const tlNames = data.filter((item) => item.tl).map((item) => item.name);
  const Tesing = data.filter((item) => item.testing).map((item) => item.name);
  const Tesingtl = data.filter((item) => item.testing_tl).map((item) => item.name);
  const Backenddash = data.filter((item) => item.backend).map((item) => item.name);
  const Backendtl = data.filter((item) => item.backend_tl).map((item) => item.name);
  // useEffect(() => {
  //   const companyDomain = "outlook.com";
  //   const emailRegex = new RegExp(`^[A-Za-z0-9._%+-]+@${companyDomain}$`, "i");

  //   if (!emailRegex.test(email) && email.trim() !== "") {
  //     setEmailError(true);
  //   } else {
  //     setEmailError(false);
  //   }
  // }, [email]);

  // / ✅ Persistent Login Check
  useEffect(() => {
    const storedUser =
      localStorage.getItem("logintl") ||
      localStorage.getItem("logintesting") ||
      localStorage.getItem("logintestingtl") ||
      localStorage.getItem("loginbackend") ||
      localStorage.getItem("loginbackendtl") ||
      localStorage.getItem("loggedInUser");

    if (storedUser) {
      const userData = JSON.parse(storedUser);
      navigate(`/profileDashboard/${userData.name}`);
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    try {
      const response = await fetch("https://hrmsadmin.hola9.com/account/loginEmployee/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: email, password, name, organization: org }),
      });

      const result = await response.json();
      if (result === true) {
        const userData = JSON.stringify({ email, name, password });
        localStorage.setItem("lastlogintime", new Date().toLocaleString());
        localStorage.setItem("selectedColor", "linear-gradient(to right, #092144, #053553)");

        if (data.some((item) => item.tl === true && item.name === name)) {
          localStorage.setItem("logintl", userData);
        } else if (data.some((item) => item.testing === true && item.name === name)) {
          localStorage.setItem("logintesting", userData);
        } else if (data.some((item) => item.testing_tl === true && item.name === name)) {
          localStorage.setItem("logintestingtl", userData);
        } else if (data.some((item) => item.backend === true && item.name === name)) {
          localStorage.setItem("loginbackend", userData);
        } else if (data.some((item) => item.backend_tl === true && item.name === name)) {
          localStorage.setItem("loginbackendtl", userData);
        } else {
          localStorage.setItem("loggedInUser", userData);
        }
        alert("Employee Login Successful");

        // ✅ Navigate properly without reloading
        navigate(`/profileDashboard/${name}`);
      } else {
        setLoginError("Invalid Credentials");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoginError("Something went wrong. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };


  // Check if the user is already logged in (stored in localStorage)
  useEffect(() => {
    const loggedInUser = localStorage.getItem("loggedInUser");
    if (loggedInUser) {
      const { email, name } = JSON.parse(loggedInUser);
      navigate(`/ProjectDetails/${name}`);
    }
  }, []);

  // Function to handle logout
  const handleLogout = () => {
    // Remove user details from localStorage
    localStorage.removeItem("loggedInUser");
  };

  return (
    <>

      <form onSubmit={handleSubmit} className="form-container">

        <div className="form-group">
          <label htmlFor="name" className="lab" />
          <input
            type="text"
            placeholder="Name"
            id="name"
            value={name}
            onChange={(e) => setname(e.target.value)}
            required
            className='input-field'
          />
        </div>
        <div className="form-group">
          <label htmlFor="org" className="lab" />
          <input
            type="text"
            placeholder="Organization"
            id="org"
            value={org}
            onChange={(e) => setorg(e.target.value)}
            required
            className='input-field'
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="lab" />
          <input
            type="text"
            placeholder="Username"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-field"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password" className="lab" />
          <input
            type="password"
            placeholder="* * * * * *"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="input-field"
          />
        </div>
        {loginError && (
          <div className="error" style={{ color: "red" }}>
            {loginError}
          </div>
        )}
        {emailError && <div className="error">Enter Outlook Email Id's Only</div>}

        <button className="button1" type="submit" disabled={isProcessing1}>
          {isProcessing1 ? "Wait..." : isProcessing ? "Processing..." : "Login"}
        </button>
      </form>

    </>
  );
};

export default Login;
