// import React, { useState } from "react";
// import "../navDropdown.css";
// import logo from "../../../images/Hola9logo.jpg";
// import Navbar from "../../../section/navbar/Navbar";
// import { holidays } from "./HolidaysData";

// const Holidays = () => {

//   const storedColor = localStorage.getItem('selectedColor');


//   const [selectedHoliday, setSelectedHoliday] = useState(null);

//   const getCurrentDate = () => {
//     const today = new Date();
//     return today.toISOString().slice(0, 10);
//   };

//   const currentDate = getCurrentDate();
//   const openPopup = (holiday) => {
//     setSelectedHoliday(holiday);
//   };

//   const closePopup = () => {
//     setSelectedHoliday(null);
//   };
//   return (
//     <>
//       <Navbar />
//       <div
//         className="width nav-m-top"
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           marginBottom: "20px",
//         }}
//       >
//         <table className="holiday-table">
//           <thead>
//           <tr style={{background:storedColor}}>
//               {/* <th>{currentDate}</th> */}
//               <th>Date</th>
//               <th>Week</th>
//               <th>Event</th>
//             </tr>
//           </thead>
//           <tbody>
//             {holidays.map((holiday) => {
//               const holidayDate = new Date(holiday.date2);
//               const isPast = holidayDate < new Date(currentDate);
//               const isCurrent = holiday.date2 === currentDate;

//               return (
//                 <tr
//                   key={holiday.id}
//                   onClick={() => openPopup(holiday)}
//                   className={
//                     isPast
//                       ? "past-holiday"
//                       : isCurrent
//                       ? "current-holiday"
//                       : "upcoming-holiday"
//                   }
//                 >
//                   <td>
//                     {holiday.date2}
//                     {isCurrent ? <>✨</> : null}
//                   </td>
//                   <td>{holiday.week}</td>
//                   <td>
//                     {holiday.event}
//                     {isCurrent ? <>🎉✨</> : null}{" "}
//                     {/* <button
//                       onClick={() => openPopup(holiday)}
//                       style={{ float: "right", background: "transparent" }}
//                     >
//                       View Detail..
//                     </button> */}
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//       {selectedHoliday && (
//         <div className="popup">
//           <div className="popup-content">
//             <h2>
//               {selectedHoliday.event}
//               {/* - Description */}
//               <span
//                 className="close"
//                 onClick={closePopup}
//                 style={{
//                   float: "right",
//                   color: "red",
//                   fontSize: "70%",
//                   cursor: "pointer",
//                 }}
//               >
//                 &times;
//               </span>
//             </h2>
//             <p style={{paddingTop:'25px'}}>{selectedHoliday.description}</p>
//             <p>{selectedHoliday.date}</p>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Holidays;



import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import "../navDropdown.css";
// import "./Holidays.css"
import Navbar from "../../../section/navbar/Navbar";

const Holidays = () => {
  const storedColor = localStorage.getItem("selectedColor");

  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));





  const [isLoading, setIsLoading] = useState(true);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [holidayData, setHolidayData] = useState({
    id: null,
    date: "",
    holidayname: "",
    week: "",
    image: null,
    desc: "",
    organization: ""
  });

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().slice(0, 10);
  };

  const currentDate = getCurrentDate();

  const handleHolidayClick = (holiday) => {
    setSelectedHoliday(holiday);
  };

  const closePopup = () => {
    setSelectedHoliday(null);
  };
  const toggleForm = () => {
    setShowForm(!showForm);
    setEditMode(false);
    setHolidayData({
      id: null,
      date: "",
      holidayname: "",
      week: "",
      image: null,
      desc: "",
      organization: ""
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHolidayData({ ...holidayData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setHolidayData({ ...holidayData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("date", holidayData.date);
      formData.append("holidayname", holidayData.holidayname);
      formData.append("week", holidayData.week);
      formData.append("desc", holidayData.desc);
      formData.append("organization", holidayData.organization);

      if (holidayData.image) {
        formData.append("image", holidayData.image);
      }

      let url = "https://hrmsadmin.hola9.com/account/holiday/";
      let method = "POST";

      if (editMode) {
        url += `${holidayData.id}/`;
        method = "PUT";
      }

      const response = await fetch(url, {
        method: method,
        body: formData
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      fetchHolidayData();
      setShowForm(false);
      setSelectedHoliday(null);
      // setIsLoading(false);
    } catch (error) {
      console.error("Error submitting holiday data:", error);
    }
  };

  useEffect(() => {
    fetchHolidayData();
  }, []);

  const fetchHolidayData = async () => {
    try {
      const response = await fetch(
        "https://hrmsadmin.hola9.com/account/holiday/"
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // Sort the holidays based on the date in ascending order
      const sortedHolidays = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setHolidays(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching holiday data:", error);
    }
  };

  const handleDeleteClick = async (holidayId) => {
    try {
      const response = await fetch(`https://hrmsadmin.hola9.com/account/holiday/${holidayId}/`, {
        method: "DELETE"
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setSelectedHoliday(null);
      fetchHolidayData();
    } catch (error) {
      console.error("Error deleting holiday data:", error);
    }
  };

  const handleEditClick = (holiday) => {
    setEditMode(true);
    setHolidayData(holiday);
    setSelectedHoliday(null);
    setShowForm(true);
  };

  return (
    <>
      {/* <Navbar /> */}
      {isLoading ? (
        <center className="loading-indicator">
          <img
            src="https://media.tenor.com/dHAJxtIpUCoAAAAi/loading-animation.gif"
            alt="---loading"
            className="emp-loading"
          />
        </center>
      ) : (<>
        {loggedInadmin ?
          <button style={{ background: "rgb(8,33,68)", color: "#fff", borderRadius: "8px" }} onClick={toggleForm}>Add Holiday List</button>
          : null}
        <div className="width nav-m-top" style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          <table className="holiday-table">
            <thead>
              <tr style={{ background: storedColor }}>
                <th>Date</th>
                <th>Week</th>
                <th>Event</th>
                {loggedInadmin ?
                  <th style={{ textAlign: 'right' }}>Manage</th>
                  : null}
              </tr>
            </thead>
            <tbody>
              {holidays.map((holiday) => {
                const holidayDate = new Date(holiday.date);
                const isPast = holidayDate < new Date(currentDate);
                const isCurrent = holiday.date === currentDate;

                return (
                  <tr
                    key={holiday.id}
                    className={
                      isPast ? "past-holiday" : isCurrent ? "current-holiday" : "upcoming-holiday"
                    }
                  >
                    <td onClick={() => handleHolidayClick(holiday)}>
                      {holiday.date}
                      {isCurrent ? <span>✨</span> : null}
                    </td>
                    <td onClick={() => handleHolidayClick(holiday)}>{holiday.week}</td>
                    <td onClick={() => handleHolidayClick(holiday)}>
                      {holiday.holidayname}
                      {isCurrent ? <span>🎉✨</span> : null}
                    </td>
                    {loggedInadmin ? <>
                      <td style={{ gap: '20px', display: 'flex', cursor: 'unset', justifyContent: 'right' }}>
                        <button style={{ background: "#007bff", color: "#fff", borderRadius: "8px", marginRight: '5px' }} onClick={() => handleEditClick(holiday)}>Edit</button>
                        <button style={{ background: "#cc0000", color: "#fff", borderRadius: "8px" }} onClick={() => handleDeleteClick(holiday.id)}>Delete</button>
                      </td>
                    </>
                      : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Modal
          title={editMode ? "Edit Holiday" : "Add New Holiday"}
          visible={showForm}
          onCancel={toggleForm}
          footer={null}
        >
          <form onSubmit={handleSubmit}>
            <label>
              Date:
              <input
                type="date"
                name="date"
                value={holidayData.date}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Holiday Name:
              <input
                type="text"
                name="holidayname"
                value={holidayData.holidayname}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Week:
              <input
                type="text"
                name="week"
                value={holidayData.week}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Organization:
              <input
                type="text"
                name="organization"
                value={holidayData.organization}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Image:
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
              />
            </label>
            <label>
              Description:
              <textarea
                name="desc"
                value={holidayData.desc}
                onChange={handleInputChange}
                required
              />
            </label>
            <button type="submit"
              style={{
                background: "rgb(8,33,68)", // Add your desired background color here
                color: "#fff",
                borderRadius: "8px", // Add your desired border radius here
                padding: "8px 16px", // Add padding for better visual appearance
                border: "none", // Remove default button border
                cursor: "pointer" // Change cursor to pointer on hover
              }}
            >
              {editMode ? "Update Holiday" : "Add Holiday"}
            </button>
          </form>
        </Modal>
        {selectedHoliday && (
          <div className="popup">
            <div className="popup-content">
              <h2 style={{ textTransform: 'uppercase' }}>
                {selectedHoliday.holidayname}
                <span
                  className="close"
                  onClick={closePopup}
                  style={{
                    float: "right",
                    color: "red",
                    fontSize: "70%",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </span>
              </h2>
              <div className="popup-details">
                <div className="popup-image">
                  <img src={selectedHoliday.image} alt="Holiday Image" />
                </div>
                <div className="popup-info">
                  <p><strong>Date:</strong> {selectedHoliday.date}</p>
                  <p><strong>Holiday Name:</strong> {selectedHoliday.holidayname}</p>
                  <p><strong>Week:</strong> {selectedHoliday.week}</p>
                  <p><strong>Description:</strong> {selectedHoliday.desc}</p>
                  <p><strong>Organization:</strong> {selectedHoliday.organization}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      )}

    </>
  );
};

export default Holidays;
