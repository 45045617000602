import React, { useState } from "react";
import axios from "axios";

const Form = ({ close }) => {
  const [formData, setFormData] = useState({
    id: "",
    images: [],
    video: "",
    description: "",
    organization: "",
    date: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    const videoUrl = URL.createObjectURL(file);
    setFormData({
      ...formData,
      video: videoUrl,
    });
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const imageFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      imageFiles.push({ image: file.name }); // Push the file name
    }

    const updatedImages = [...formData.images, ...imageFiles];

    setFormData({
      ...formData,
      images: updatedImages,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("id", formData.id);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("organization", formData.organization);
      formDataToSend.append("date", formData.date);
      formDataToSend.append("video", e.target.video.files[0]);
      for (let i = 0; i < formData.images.length; i++) {
        formDataToSend.append("images", e.target.images.files[i]);
      }

      const response = await axios.post(
        "https://hrmsadmin.hola9.com/account/wanted2/",
        formDataToSend
      );

      console.log(response.data);
      close();
      alert("Uploded Successfully");
      // handle success, maybe show a success message
    } catch (error) {
      console.error("Error:", error);
      // handle error, maybe show an error message
    }
  };
  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = formData.images.filter(
      (_, index) => index !== indexToRemove
    );
    setFormData({
      ...formData,
      images: updatedImages,
    });
  };
  return (
    <div
      style={{
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        width: "70%",
        height: "auto",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{ overflowY: "scroll" }}
        className="leave-form"
      >
        {/* <label>
        ID:
        <input
          type="text"
          name="id"
          value={formData.id}
          onChange={handleChange}
        />
      </label> */}

        <label>
          Description:
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </label>

        <label>
          Title :
          <input
            type="text"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
          />
        </label>

        <label>
          Date:
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
        </label>

        <label>
          Video:
          <input
            type="file"
            name="video"
            accept="video/*"
            onChange={handleVideoUpload}
          />
        </label>

        {/* {formData.video && (
        <video width="320" height="240" controls>
          <source src={formData.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )} */}

        <label>
          Images:
          <input
            type="file"
            name="images"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
          />
        </label>

        {/* {formData.images.map((imageUrl, index) => (
        <img key={index} src={imageUrl.image} alt={`Image ${index + 1}`} />
      ))} */}

        <button type="submit">Submit</button>
        <button type="button" onClick={close}>
          close
        </button>
      </form>
    </div>
  );
};




export default Form;


// import React, { useRef, useState } from "react";
// import axios from "axios";
// import { FaFileImage, FaFileVideo } from "react-icons/fa";

// const Form = ({ close }) => {
//   const fileInputRef = useRef(null);
//   const [uploadedVideos, setUploadedVideos] = useState([]);

//   const storedColor = localStorage.getItem("selectedColor");
//   const [formData, setFormData] = useState({
//     id: "",
//     images: [],
//     video: "",
//     description: "",
//     organization: "",
//     date: "",
//   });
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleVideoUpload = (e) => {
//     const file = e.target.files[0];
//     const videoUrl = URL.createObjectURL(file);
//     setFormData({
//       ...formData,
//       video: videoUrl,
//     });
//   };

//   const handleImageUpload = (e) => {
//     const files = e.target.files;
//     const imageFiles = [];

//     for (let i = 0; i < files.length; i++) {
//       const file = files[i];
//       imageFiles.push({ image: file.name }); // Push the file name
//     }

//     const updatedImages = [...formData.images, ...imageFiles];

//     setFormData({
//       ...formData,
//       images: updatedImages,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formDataToSend = new FormData();
//       formDataToSend.append("id", formData.id);
//       formDataToSend.append("description", formData.description);
//       formDataToSend.append("organization", formData.organization);
//       formDataToSend.append("date", formData.date);
//       formDataToSend.append("video", e.target.video.files[0]);
//       for (let i = 0; i < formData.images.length; i++) {
//         formDataToSend.append("images", e.target.images.files[i]);
//       }

//       const response = await axios.post(
//         "https://hrmsadmin.hola9.com/account/wanted2/",
//         formDataToSend
//       );

//       if(response.ok) {
//         console.log(response.data);
//       alert("Uploded Successfully");
//       close();
//       }
//       // handle success, maybe show a success message
//     } catch (error) {
//       console.error("Error:", error);
//       alert("Error posting Gallery");
//       // handle error, maybe show an error message
//     }
//   };
//   const handleRemoveImage = (indexToRemove) => {
//     const updatedImages = formData.images.filter(
//       (_, index) => index !== indexToRemove
//     );
//     setFormData({
//       ...formData,
//       images: updatedImages,
//     });
//   };
//   return (
//     <div
//       style={{
//         background: "white",
//         padding: "20px",
//         borderRadius: "10px",
//         // width: "70%",
//         height: "auto",
//       }}
//     >
//       <i
//         type="button"
//         onClick={close}
//         style={{ float: "right", cursor: "pointer" }}
//       >
//         ❌
//       </i>

//       {/* <form onSubmit={handleSubmit} style={{overflowY:"scroll"}} className="leave-form"> */}
//       {/* <label>
//         ID:
//         <input
//           type="text"
//           name="id"
//           value={formData.id}
//           onChange={handleChange}
//         />
//       </label> */}

//       <label>
//         Description:
//         <input
//           type="text"
//           name="description"
//           value={formData.description}
//           onChange={handleChange}
//         />
//       </label>

//       <label>
//         Organization:
//         <input
//           type="text"
//           name="organization"
//           value={formData.organization}
//           onChange={handleChange}
//         />
//       </label>

//       <label>
//         Date:
//         <input
//           type="date"
//           name="date"
//           value={formData.date}
//           onChange={handleChange}
//         />
//       </label>
//       <br />
//       <br />

//       <div style={{ display: "flex", gap: "10px", flexWrap:'wrap' }}>
//         {formData.video && (
//           <video width="100" height="60" controls>
//             <source src={formData.video} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         )}
//         <>
//           <label
//             htmlFor="VideoFile"
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               cursor: "pointer",
//             }}
//           >
//             <FaFileVideo style={{ fontSize: "60px" }} />
//             <p style={{ fontSize: "75%", marginBottom: "0" }}>ADD VIDEO</p>
//           </label>

//           <input
//             type="file"
//             accept="video/*"
//             name="video"
//             id="VideoFile"
//             style={{ display: "none" }}
//             onChange={handleVideoUpload}
//           />
//         </>

//         {formData.images.map((imageUrl, index) => (
//           <img
//             width="70"
//             height="60"
//             key={index}
//             src={imageUrl.image}
//             alt={`Image ${index + 1}`}
//             controls
//           />
//         ))}
//         <>
//           <label
//             htmlFor="Imagefile"
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               cursor: "pointer",
//             }}
//           >
//             <FaFileImage style={{ fontSize: "60px" }} />
//             <p style={{ fontSize: "75%", marginBottom: "0" }}>ADD Images</p>
//           </label>

//           <input
//             type="file"
//             name="images"
//             id="Imagefile"
//             accept="image/*"
//             multiple
//             style={{ display: "none" }}
//             onChange={handleImageUpload}
//           />
//         </>
//       </div>

//       <button
//         type="submit"
//         onClick={handleSubmit}
//         style={{
//           background: storedColor,
//           color: "white",
//           borderRadius: "25px 0 0 50%",
//           float: "right",
//           marginRight: "-20px",
//         }}
//       >
//         Post Gallery
//       </button>
//       {/* </form> */}
//     </div>
//   );
// };

// export default Form;
