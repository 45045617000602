// Import necessary dependencies
import React, { useState, useEffect } from 'react';
import { BiLocationPlus } from 'react-icons/bi';
import MapLocationAds from './MapLocation';
import MapLocationAds1 from './Maplocation1';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactPaginate from 'react-paginate'; // Import pagination component

const EmployeeTable = (props) => {
  const name = props.name
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    date: '',
  });
  const itemsPerPage = 42; // Number of items to display per page

  const [pageNumber, setPageNumber] = useState(0);
  const [itemsToLoad, setItemsToLoad] = useState(5); // Holds the number of items to load initially and increases when "Load More" is clicked
  const [loading, setLoading] = useState(); // Holds the new image file being selected


  const fetchData = () => {

    fetch(`https://hrmsadmin.hola9.com/account/employeelogin2?limit=${itemsToLoad}`)
      .then((response) => response.json())
      .then((newData) => {
        setData(newData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [itemsToLoad]);
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 5);
  };
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!loading) {
        const newDataLength = data.length;

        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [loading, data, prevDataLength]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);

    let filtered = data.filter(employee => {
      const nameMatch = employee.name.toLowerCase().includes(updatedFilters.name.toLowerCase());

      const formattedDate = new Date(employee.date).toLocaleDateString();
      const dateMatch = updatedFilters.date === '' || formattedDate === new Date(updatedFilters.date).toLocaleDateString();

      return nameMatch && dateMatch;
    });

    console.log('Original Data:', data);
    console.log('Updated Filters:', updatedFilters);
    console.log('Filtered Data:', filtered);

    setFilteredData(filtered);
  };


  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedData = filteredData.sort((a, b) => b.id - a.id).slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );
  const convertMinutesToHours = (minutes) => {
    const totalMinutes = parseInt(minutes, 10); // Ensure total_minutes is a valid number
    if (isNaN(totalMinutes)) {
      return 'Invalid Time';
    }

    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const thStyle = {
    backgroundColor: '#f2f2f2',
    padding: '12px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  };

  const tdStyle = {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  };
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={filters.name} onChange={handleFilterChange} />
        </div>
        <div>
          <label>Date:</label>
          <input type="date" name="date" value={filters.date} onChange={handleFilterChange} />
        </div>
        <div style={{ marginTop: "-20px" }}>
          <ReactHTMLTableToExcel
            id="downloadButton"
            className="download-button"
            table="employeeTable"
            filename="employee_data"
            sheet="employee_data"
            buttonText="Download Details"
          /></div>
      </div>
      {loading ?
        (<center className="loading-indicator" style={{ padding: "50px" }}><img src='https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif' alt='loading...' /></center>) : (


          <table className="employee-table" id="employeeTable" style={tableStyle}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Date</th>
                <th>Check-in Time</th>
                <th>Check-in Location</th>
                <th>Check-Out Time</th>
                <th>Check-Out Location</th>
                <th>Total Time</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map(employee => (
                <tr key={employee.id}>
                  <td>{employee.id}</td>
                  <td>{employee.name}</td>
                  <td>{employee.date}</td>
                  <td>{employee.login_time}</td>
                  <td>
                    {employee.lattiude1 && employee.longitude1 ? (
                      <>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${employee.lattiude1},${employee.longitude1}`}
                          target='_blank'
                        >
                          <button style={{ border: '1px solid navy', padding: '5px' }}>
                            <BiLocationPlus /> See On Map
                          </button>
                        </a>
                        <MapLocationAds id={employee.id} props={{ lati: employee.lattiude1, long: employee.longitude1 }} />
                      </>
                    ) : (
                      <>
                        No location found
                      </>
                    )}
                  </td>
                  <td>{employee.logout_time}</td>
                  <td>
                    {employee.lattiude2 && employee.longitude2 ? (
                      <>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${employee.lattiude2},${employee.longitude2}`}
                          target='_blank'
                        >
                          <button style={{ border: '1px solid navy', padding: '5px' }}>
                            <BiLocationPlus /> See On Map
                          </button>
                        </a>
                        <MapLocationAds1 id1={employee.id} props1={{ lati1: employee.lattiude2, long1: employee.longitude2 }} />
                      </>
                    ) : (
                      <>
                        No location found
                      </>
                    )}
                  </td>
                  <td>{convertMinutesToHours(employee.total_time)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      {filteredData.length > itemsPerPage && (
        <ReactPaginate
          previousLabel={'Previous'}
          className='paginat'
          nextLabel={'Next'}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={'pagination-container'}
          pageClassName={'pagination-page'}
          pageLinkClassName={'pagination-link'}
          previousClassName={'pagination-previous'}
          nextClassName={'pagination-next'}
          disabledClassName={'pagination-disabled'}
          activeClassName={'pagination-active'}
        />
      )}
    </div>
  );
};

export default EmployeeTable;
