import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DocumentViewer from '../Dashboard/Dashboard';
import "./UesrDashboard.css";
import ReactPaginate from 'react-paginate'; // Import pagination component
import Card from "react-bootstrap/Card";
import Navbar from '../../section/navbar/Navbar';
import EmployeeTable2 from '../Userdashboard/EmployeeTable';
import Employeetask1 from '../Userdashboard/Employeetask1';
import Clock from '../Userdashboard/MapLocationRow ';
import Button from "react-bootstrap/Button";
import LoginDetails from '../login/LoginDetails';
import logo from "../../images/Hola9logo.jpg"
function EmployeeTabledetail(props) {
  const name = props.name;

  const storedColor = localStorage.getItem('selectedColor');


  const [data, setData] = useState([]);
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [nameEmailFilter, setNameEmailFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const itemsPerPage = 40; // Number of items per page
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [selectedTab, setSelectedTab] = useState("allEmployees");
  const [itemsToLoad, setItemsToLoad] = useState(20)
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
  useEffect(() => {
    // Fetch the data from your API
    fetch(`https://hrmsadmin.hola9.com/account/employeeprofile/?limit=${itemsToLoad}&name=${name}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setIsLoading(false); // Set loading state to false when data is fetched
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to false in case of an error
        console.error('Error fetching data:', error);
      });
  }, [itemsToLoad]);


  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 20);
    setLimitIncreaseCount((prevCount) => prevCount + 1); // Increase the number of items to load
  };
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading && limitIncreaseCount != 3) {
        const newDataLength = data.length;

        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, data, prevDataLength, limitIncreaseCount]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    setLimitIncreaseCount(0);
  };
  // Filter function to apply filters
  const filteredData = data.filter((employee) => {
    // Filter by Name/Email
    if (nameEmailFilter) {
      const name = employee.name.toLowerCase();
      const email = employee.email ? employee.email.toLowerCase() : '';
      const task = employee.task ? employee.task.toLowerCase() : '';
      const nameEmail = name + email + task;
      if (!nameEmail.includes(nameEmailFilter.toLowerCase())) {
        return false;
      }
    }

    // Filter by Date Range
    if (dateFilter) {
      const employeeDate = new Date(employee.task_date);
      const selectedDate = new Date(dateFilter);

      // Check if the selected date matches the employee's task_date
      if (employeeDate.toDateString() !== selectedDate.toDateString()) {
        return false;
      }
    }

    return true;
  });
  filteredData.sort((a, b) => new Date(b.id) - new Date(a.id));


  const sortedData = filteredData.sort((a, b) => b.id - a.id);
  // Calculate the start and end indexes based on the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the data to display for the current page
  const dataToDisplay = filteredData.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const pageCount = Math.ceil(sortedData.length / itemsPerPage);
  return (
    <>

      <div className="employee-table-container">
        {isLoading ? (
          <h4>Hii <span style={{ color: "red" }}>{name}</span> please wait just a moment</h4>) : filteredData.length == 0 ? (
            <img
              src="https://i.pinimg.com/originals/49/e5/8d/49e58d5922019b8ec4642a2e2b9291c2.png"
              alt="no data found"
            />
          ) : (
          // <h3>Here is your Details </h3>
          null
        )}

        {isLoading ? ( // Render loading indicator if data is still loading
          <center className="loading-indicator" style={{ padding: "50px" }}><img src='https://media.tenor.com/hlKEXPvlX48AAAAi/loading-loader.gif' alt='loading...' /></center>
        ) : (

          <>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

              <div className="profileInput" style={{ marginLeft: '0', width: '50%' }}>
                <input
                  type="date"
                  // className="input1"
                  placeholder="Select Date"
                  value={dateFilter}
                  onChange={(e) => setDateFilter(e.target.value)}
                  style={{
                    // height: "10px",
                    width: '100%',
                  }}
                />
                {/* <input
          // className="input2"
          type="search"
          placeholder="Search.."
          value={nameEmailFilter}
          onChange={(e) => setNameEmailFilter(e.target.value)}
          style={{
            // height: "10px",
            width:'100%',
            marginLeft:'10px'
          }}
        /> */}

              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactPaginate
                  className='paginat'
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
            <div style={{ overflowX: 'auto' }}>
              <table className="employee-table" id="employeeTable">
                <thead>
                  <tr
                  // style={{background:storedColor}}
                  >
                    <th>ID</th>
                    {/* <th>Name</th> */}
                    <th>Task Date</th>
                    <th>Task</th>
                    {/* <th>Report of Work</th>
                    <th>Completion Status</th> */}
                    <th>Work Report</th>
                    <th>Document</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {dataToDisplay.sort((a, b) => b.id - a.id).map((employee) =>
                  (
                    <tr key={employee.id}>
                      <td>{employee.id}</td>
                      {/* <td> */}
                      {/* <Link to={`/userdash/${employee.name}`} style={{ color: 'navy' }}> */}
                      {/* {employee.name} */}
                      {/* </Link> */}
                      {/* </td> */}
                      <td style={{ whiteSpace: 'nowrap' }}>{employee.task_date}</td>
                      <td>{employee.task}</td>
                      <td>
                        <pre>
                          {`Report  -  ${employee.report_of_work}`}
                        </pre>
                        <pre>
                          {`Status  -  ${employee.completion_status}`}
                        </pre>
                      </td>
                      {/* <td>{employee.report_of_work}</td> */}
                      {/* <td>{employee.completion_status}</td> */}
                      {/* <td style={{ fontSize: '80%' }}><td><a href={employee.documnet} target='_blank' style={{color:"navy"}}>View Document</a></td>
                          <td>
                            {employee.document_base64 && (
                              <DocumentViewer documentData={employee} />
                            )}
                          </td>
                        </td> */}
                      <td>
                        <span>
                          <a href={employee.documnet} target='_blank' style={{ color: "navy" }}>View Document</a>
                        </span>
                        <span>
                          {employee.document_base64 && (
                            <DocumentViewer documentData={employee} />
                          )}
                        </span>
                      </td>
                      <td>{employee.comment}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {/* Pagination component */}
        {filteredData.length > itemsPerPage && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactPaginate
              className='paginat'
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        )}
      </div>
    </>
  )

}

export default EmployeeTabledetail;